/**
 * @Description
 * This file intended to intercept incoming request and response.
 * Main utility will to authorize the user else redirect to login page.
 * or we want the error to display api failure and sentry logs can be put here.
 */
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { logOutUser } from '../modules/App/AppActions';
import storage from '../util/storageService';
import { showNotification } from '../util/utility';
import { CustomRequest } from './common';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';


export const GET = 'GET';
export const POST = 'POST';
export const DELETE = 'DELETE';
export const PUT = 'PUT';

export const API_BASE_PATH = `${process.env.REACT_APP_API_URL}/api/`; // introduce a fallback here
// export const API_BASE_PATH = `http://localhost:8070/api/`;
export const CERBERUS_BASE_PATH = `${process.env.REACT_APP_CERBERUS_API_URL}/cerberus/`;
export const API_BASE_AUTH_PATH = `${API_BASE_PATH}auth/`;
export const UW_BASE_PATH = `${process.env.REACT_APP_UW_API_URL}/underwriting/`;
// export const UW_BASE_PATH = `http://localhost:8000/`;
// export const API_BASE_CERBERUS_PATH = `https://apidev4.recur.club/cerberus/rest/`;
export const API_BASE_CERBERUS_PATH = `${CERBERUS_BASE_PATH}rest/`;
export const WEBUTILS_URL = process.env.REACT_APP_WEBUTILS_API_URL;
export const MNEMOSYNE_URL = process.env.REACT_APP_MNEMOSYNE_API_URL;
export const OLYMPUS_URL = `${process.env.REACT_APP_OLYMPUS_API_URL}/rest/`;
// export const OLYMPUS_URL = 'http://localhost:8083/olympus/rest/'
export const NOTIFICATION_BASE_URL = `${process.env.REACT_APP_NOTIFICATION_API_URL}`;
// export const NOTIFICATION_BASE_URL ='http://localhost:8081/notification'

declare global {
    interface Window {
        gapi: any;
    }
}
const logOutFromApp = (dispatch:any): void => {
    const authInstance: any = window?.gapi?.auth2?.getAuthInstance();
    if (authInstance) authInstance.signOut();
    dispatch(logOutUser());
};

const onRequest = (config: CustomRequest,dispatch:any): any => {
    const _token = storage.get('cerberus_token');
    if (config.isAuthRequired) {
        if (!_token) logOutFromApp(dispatch);
        if (_token)
            config.headers = {
                ...config.headers,
                'x-cerberus-token': _token ?? '',
                'x-organization-id': storage.get('org_id') ?? '',
                'x-user-id': storage.get('user_id') ?? '',
            };
        delete config.isAuthRequired;
    }
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    if (response.status === 401) {
        showNotification('Error', 'Session Timed Out');
    }
    return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    if (error?.response?.status === 401) {
        showNotification('Error', 'Session Timed Out');
    }
    return Promise.reject(error);
};

export function applyInterceptor(
    axiosInstance: AxiosInstance,
    dispatch: any,
): AxiosInstance {
    axiosInstance.interceptors.request.use((req) => onRequest(req,dispatch), onRequestError);
    axiosInstance.interceptors.response.use(
        (res) => onResponse(res),
        (err) => onResponseError(err),
    );
    return axiosInstance;
}
