import React, {useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import '../../../../../../../../../../../css/ModerationComment.css'
import callApi from '../../../../../../../../../../../util/apiCaller';
import {
    getTranchTenure,
    showNotification,
    formatDate,
    checkValidBankAccountOnboarding,
    getRoiFromIrr
} from '../../../../../../../../../../../util/utility';
import _, {isEmpty, isNil} from 'lodash';
import styled from "styled-components";
import SaveSVG from '../../../../../../../../../../../components/SVGs/SaveSVG';
import {
    getInvesteeOnboardingData,
    getInvesteesOrganization,
    getGstAnnualRev,
    getRrfToggleDetails,
    getDebtSyndicationToggleDetails,
} from '../../../../../../../../../AdminReducers';
import {useDispatch, useSelector} from 'react-redux';
import {
    ADD_ON_LIMIT_CONST,
    API_STATUS,
    CASHFREE_SPLIT_KEY,
    DIRECT_COLLECTION_KEY,
    GENERIC_ERROR,
    IDENTIFIER_TYPE,
    INVESTEE_ORG_UNCLAIMED,
    PREPARE_TERMS_TAB,
    RAZORPAY_SPLIT_KEY,
    SHOW_NOTIFICATION,
    MODERATION,
    APPLICATION_TYPE,
    GST_INACTIVE
} from '../../../../../../../../../../../constants/enums';
import MultipleTotFooter from './components/MultipleTotComponents/MultipleTotFooter';
import ModeratorDetails from './components/MultipleTotComponents/ModeratorDetails';
import ReviewFinancialData from './components/MultipleTotComponents/ReviewFinancialData';
import PrepareTerms from './components/MultipleTotComponents/PrepareTerms';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import AdditionalInfo from './components/AdditionalInfo';
import DetailsInput from './DetailsInput';
import {fetchInvesteeDetails, fetchToggleInfo, fetchDealReport} from '../../../../../../../../../AdminActions';
import StepperTabs from '../../../../../../../../../../../components/StepperTabs/StepperTabs';
import DealEvaluation from './components/DealEvaluation';
import {
    WAIT_LISTING_TOAST_TEXT
} from '../../../../../../../../../../../constants/commonConstants';
import LimitRoadmap from './components/LimitRoadmap/LimitRoadmap';
import ToggleButton from '../../../../../../../../../../../components/ToggleButton/ToggleButton';
import styles from "./ModerateOnboarding.module.scss"
import {NEED_INFO_ONBOARDING_STATUS, ONBOARDING_STATUS, TOT_STATUS} from '../../../../../../../../../../../common/ENUM';
import {
    emailSwiftTermsToUser,
    getMoratMonths,
    sendWaitlistedEmailHelper
} from '../../../../../../../../../../../common/ApiHelpers';
import {uw_signal_types} from "./components/DealEvaluation"
import {sendScaleTot} from '../../../../../../../../../../../common/ApiContainer';
import ConfirmTotUpdate from '../../../../../../../../../../../components/ConfirmTotUpdateModal/ConfirmTotUpdateModal';
import { EXPIRED } from '../../../../DocumentVault/consts/consts';
import RenderTextBox from './AddOnModerate/components/RenderTextBox/RenderTextBox';


dayjs.extend(customParseFormat)

const {toggleContainer} = styles

const DynamicValue = styled.input`
    border: 0.5px solid #D2D2D2;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 40px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 10px;
    color: #ADADAD;

    &:disabled {
        background-color: #F1F1F1;
        border: none;
    }

    &:focus, &:valid {
        border: 0.5px solid #4C8DFF;
        color: #000000;
    }
`;
const SelectBox = styled.select`
    border: 0.5px solid #D2D2D2;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 40px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 10px;
    color: #ADADAD;

    &:disabled {
        background-color: #F1F1F1;
        border: none;
    }

    &:focus, &:valid {
        border: 0.5px solid #4C8DFF;
        color: #000000;
    }
`;
const EmailUserBtn = styled.button`
    max-width: 140px;
    border-radius: 4px;
    background-color: #00bd00;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    border: none;
    margin-right: 30px;
    padding: 6.5px 0;

    img {
        width: 14px;
        margin-right: 5px;
    }

    &:disabled {
        background-color: #c7c7c7;
    }
`;
const ModerateBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 4px;
    background-color: #4C8DFF;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    border: none;
    padding: 7px 24px;
`;

export const DEFAULT_LENDER_PROCESSING_FEES = 1;

export default function ModerateOnboarding({
                                               investeeOrgId,
                                               investeeOnboardedId,
                                               fetchMonthlyData,
                                               fetchDocumentationData,
                                               dataLength,
                                               moderateRow,
                                               price,
                                               tradingLimit,
                                               monthlyData,
                                               irr,
                                               contractsCount
                                           }) {
    const ONBOARDING = "Onboarding";
    const STEPS = [
        {
            label: "Review Financial Data",
            value: 1
        },
        {
            label: MODERATION.RRF_TERM,
            value: 2
        },
        {
            label: MODERATION.DEBT_SYNDICATION_TERM,
            value: 3

        }
    ];
    const NEEDINFO = "Need Information";
    const WAITLISTED = "Waitlisted";
    const APPROVED = "Approved";
    const SPARK_APPROVED = "Spark Approved";
    const IS_NACH = "is_nach_enabled";

    const RECUR_LIMIT = "Recurlimit";
    const STANDARD = "Standard";
    const DEBT_SYNDICATION = "DebtSyndication";
    const MAX = "Max";
    const MAB = "MAB";
    const DIRECT_COLLECTION = "Direct Collection";

    const MIS = "MIS";
    const AUD_FINANCIAL = "Audited Financials";
    const PROV_FINANCIAL = "Provisional Financials";
    const INVOICING = "Invoicing Data";
    const ADD_BANK = "is_add_bank_account";
    const DEBT_SCHEDULE = "Debt Schedule";
    const DIRECTOR_DETAILS = "Director Details";

    const APPROVED_RUNAWAY = "Approved-Runway<3M";

    const ACCEPTED = "Accepted";
    const SENT = "Sent";
    const UPDATED_NOT_SENT = "Updated Not Sent";

    const mandatory_fields_enum = {
        display_name: "Display Name",
        company_sector: "Company Sector",
        company_sub_sector: "Company Sub Sector",
        founded_in: "Founded In",
        funding: "Funding",
        company_overview: "Company Overview",
        website_url: "Website Url",
        pan_card: "Pan Card",
        company_cin: "Company Cin",
        address: "Address",
        cin_number: "Cin Number",
        pan_number: "Pan Number",
        gstin_number: "Gstin Number",
        company_website: "Company Website",
        current_company_address: "Current Company address"
    }

    const emailCheck = price && tradingLimit && irr && contractsCount;
    const {dataType, due_date, archived} = moderateRow;
    const applicationStatus = moderateRow?.status;
    const getStatusOptions = () => {
        if (dataType === "monthly") {
            if (dataLength > 1 || due_date !== ONBOARDING) {
                if (due_date.includes(ADD_ON_LIMIT_CONST)) {
                    return ["Need Information", "Approved", "Approved-Runway<3M", "Waitlisted", "Approved For Debt Syndication"];
                }
                return ["Need Information", "Approved", "Approved-Runway<3M", "Waitlisted", "Approved For Debt Syndication"];
            } else {
                return ["Need Information", "Approved", "Not Approved", "Waitlisted", "Spark Approved", "Approved For Debt Syndication"];
            }
        } else if (dataType === "documentation") {
            return ["Need Information", "Approved"];
        }
    }
    const onboardingData = useSelector(state => getInvesteeOnboardingData(state));
    const investee_details = useSelector(state => getInvesteesOrganization(state));
    const [statusOptions, setStatusOptions] = useState(getStatusOptions());
    const [isModerating, setIsModerating] = useState(false);
    const investeeOrganizationId = useSelector(getInvesteesOrganization)?._id;
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [userNameForCoborrower, setUserNameForCoborrower] = useState(null);
    const [currentModerateTab, setCurrentModerateTab] = useState(PREPARE_TERMS_TAB.RRF_TERMS);
    const rrfToggleInfo = useSelector(getRrfToggleDetails);
    const debtSyndicationToggleInfo = useSelector(getDebtSyndicationToggleDetails);
    const gstAnnualRevData = useSelector(getGstAnnualRev);
    const [rrfTermToggle, setRrfTermToggle] = useState(false);
    const [debtSyndicationToggle, setDebtSyndicationToggle] = useState(false);
    const [showTotUpdatePopUp, setShowTotUpdatePopUp] = useState(false);
    const [modalMessaging, setModalMessaging] = useState({})
    const dispatch = useDispatch();
    const [sendingEmail, setIsSendingEmail] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [dealReportState, setDealReportState] = useState({});

    const initialStatusInfo = () => {
        if (due_date === "Documentation") {
            return {
                1: {
                    category: "KYC",
                    status: ""
                },
                2: {
                    category: "Aut. Doc.",
                    status: ""
                },
                3: {
                    category: "Company Aut.",
                    status: ""
                },
                4: {
                    category: "Add'I",
                    status: ""
                }
            }
        }
        if (due_date === ONBOARDING) {
            return {
                1: {
                    category: PROV_FINANCIAL,
                    status: "",
                    comment: ""
                },
                2: {
                    category: AUD_FINANCIAL,
                    status: "",
                    comment: ""
                },
                3: {
                    category: INVOICING,
                    status: "",
                    comment: ""
                },
                4: {
                    category: DEBT_SCHEDULE,
                    status: "",
                    comment: ""

                },
                5: {
                    category: DIRECTOR_DETAILS,
                    status: "Need Information",
                    comment: "Need consent from pending directors"
                }
            };
        }
        return {
            1: {
                category: "Bank Statement",
                status: "",
                comment: ""
            },
            2: {
                category: PROV_FINANCIAL,
                status: "",
                comment: ""
            },
            3: {
                category: "Invoicing",
                status: "",
                comment: ""
            }
        };
    }
    // Current Step indicates the tabs 1 - Review Financial Data, 2 - Swift RRF Terms, 3 - Scale DS Terms
    const [currentStep, setCurrentStep] = useState(1);
    const [statusInfo, setStatusInfo] = useState(initialStatusInfo());
    const statusInfoRef = useRef(initialStatusInfo());
    const [showReqStatusOptions, setShowReqStatusOptions] = useState(false);
    const [addnInfo, setAddnInfo] = useState([])
    const [isAddnInfo, setIsAddnInfo] = useState(false)
    const [status, setStatus] = useState("");
    const statusRef = useRef("");
    const [onboardingComment, setOnboardingComment] = useState(onboardingData?.comment);
    const [note1, setNote1] = useState("");
    const [standardCP, setStandardCP] = useState([""]);
    const [standardCS, setStandardCS] = useState([""]);
    const [note2, setNote2] = useState("");
    const [maxCP, setMaxCP] = useState([""]);
    const [maxCS, setMaxCS] = useState([""]);
    const [recurLimitCS, setRecurLimitCS] = useState([""]);
    const [recurLimitCP, setRecurLimitCP] = useState([""]);
    const [note3, setNote3] = useState("");
    const [note4, setNote4] = useState("");
    const [CP4, setCP4] = useState([""]);
    const [CS4, setCS4] = useState([""]);
    const currentStepRef = useRef(1);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [lastEmail, setLastEmail] = useState(null);
    const [initialUserTotData, setInitialUserTotData] = useState();
    const [totData, setTotData] = useState({});
    const [coborrowerList, setCoborrowerList] = useState([]);
    const [userTotData, setUserTotData] = useState();
    const [detectChange, setDetectChange] = useState({
        [RECUR_LIMIT]: false,
        [STANDARD]: false,
        [MAX]: false,
        [DEBT_SYNDICATION]: false
    });
    const [showMaxLimit, setShowMaxLimit] = useState(false);
    const [showStandardLimit, setShowStandardLimit] = useState(false);
    const [totalAvailableLimit, setTotalAvailableLimit] = useState();
    const [totId, setTotId] = useState();
    const [isTotAccepted, setIsTotAccepted] = useState(false);
    const [limitRoadmapData, setLimitRoadmapData] = useState({
        limitRoadmap: '',
        tranches: [
            {
                tenure: '',
                amount: '',
                comments: ''
            }
        ]
    });
    const [moratDropdownList, setMoratDropdownList] = useState({});
    const getMoraDropdownList = async () => {
        const res = await getMoratMonths([0, 1, 2, 3])
        if (res) {
            const modifiedRes = {};
            Object.keys(res).forEach((key, index) => {
                modifiedRes[key] = `${res[key]} (${index}M)`;
            });
            setMoratDropdownList(modifiedRes)
        }
    }
    useEffect(() => {
        getMoraDropdownList()

    }, [])
    const [toggleState, setToggleState] = useState({
        rrfToggle: {
            updatedBy: "System",
            updatedAt: formatDate(onboardingData.created_at, '/')
        },
        debtSynToggle: {
            updatedBy: "System",
            updatedAt: formatDate(onboardingData.created_at, '/')
        }
    });
    const path = (due_date === ONBOARDING || due_date === "Documentation") ?
        due_date : due_date.includes(ADD_ON_LIMIT_CONST) ? due_date : moment(due_date).format("YYYY-MM");

    const getCurrentTotCount = (data) => {
        let count = 0;
        if (data.hasOwnProperty(RECUR_LIMIT)) {
            count++;
        }
        if (data.hasOwnProperty(STANDARD)) {
            count++;
        }
        if (data.hasOwnProperty(MAX)) {
            count++;
        }
        if (data.hasOwnProperty(DEBT_SYNDICATION)) {
            count++;
        }
        return count;
    }

    const handleToggle = () => {
        const rrf_toggle_state = "rrfToggle";
        const debt_syn_toggle_state = "debtSynToggle";
        let capitalReqObj = {};
        if ((typeof onboardingData?.capital_required) === 'string') {
            capitalReqObj = JSON.parse(onboardingData?.capital_required);
        } else {
            capitalReqObj = onboardingData?.capital_required
        }

        if (
            rrfToggleInfo &&
            rrfToggleInfo !== "" &&
            rrfToggleInfo?.updatedBy
        ) {
            setToggleState(prevState => ({
                ...prevState,
                [rrf_toggle_state]: {
                    ...prevState[rrf_toggle_state],
                    updatedBy: rrfToggleInfo?.updatedBy,
                    updatedAt: formatDate(rrfToggleInfo?.updatedAt, '/')
                }
            }));

            setRrfTermToggle(!!rrfToggleInfo?.isOn);
        } else {
            setToggleState(prevState => ({
                ...prevState,
                [rrf_toggle_state]: {
                    ...prevState[rrf_toggle_state],
                    updatedBy: 'System',
                    updatedAt: formatDate(onboardingData.created_at, '/')
                }
            }));
        }

        if (
            debtSyndicationToggleInfo &&
            debtSyndicationToggleInfo !== "" &&
            debtSyndicationToggleInfo?.updatedBy
        ) {
            setToggleState(prevState => ({
                ...prevState,
                [debt_syn_toggle_state]: {
                    ...prevState[debt_syn_toggle_state],
                    updatedBy: debtSyndicationToggleInfo?.updatedBy,
                    updatedAt: formatDate(debtSyndicationToggleInfo?.updatedAt, '/')
                }
            }));

            setDebtSyndicationToggle(!!debtSyndicationToggleInfo?.isOn);
        } else {
            setToggleState(prevState => ({
                ...prevState,
                [debt_syn_toggle_state]: {
                    ...prevState[debt_syn_toggle_state],
                    updatedBy: 'System',
                    updatedAt: formatDate(onboardingData.created_at, '/')
                }
            }));
            if (
                Number(capitalReqObj?.amount) >= 50000000 &&
                (Number(gstAnnualRevData) >= 20 ||
                    Number(onboardingData?.arr_funding_cal) >= 20)
            ) {
                setDebtSyndicationToggle(true);
            } else {
                setDebtSyndicationToggle(false);
            }
        }
    };

    useEffect(() => {
            handleToggle();
        },
        [gstAnnualRevData, rrfToggleInfo, debtSyndicationToggleInfo, onboardingData])

    useEffect(() => {
        dispatch(fetchToggleInfo(investeeOnboardedId, investeeOrgId, false));
    }, []);

    useEffect(() => {
        setIsModerating(false);
    }, [currentModerateTab]);
    useEffect(() => {
        if (currentStep === 2) {
            setCurrentModerateTab(PREPARE_TERMS_TAB.RRF_TERMS);
        } else if (currentStep === 3) {
            setCurrentModerateTab(PREPARE_TERMS_TAB.DS_TERMS);
        }

    }, [currentStep])

    useEffect(() => {
        setStatusOptions(getStatusOptions());
        if (dataType === "monthly") {
            fetchMonthlyStatusInfo();
            if (due_date === ONBOARDING) {
                fetchTotData();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [due_date]);

    useEffect(() => {
        if (totId) fetchAvailableLimit(totId);
    }, [totId]);

    useEffect(() => {
        getUserNameForCoborrower();
    }, [])

    useEffect(() => {
        if (moderateRow?.additionalRequiredDocuments) {
            setAddnInfo(moderateRow.additionalRequiredDocuments)
        }
        if(moderateRow.status === "Expired"){
            setIsExpired(true);
        } else {
            setIsExpired(false);
        }
    }, [moderateRow])

    const fetchAvailableLimit = async () => {
        const res = await callApi("tot-history/get-available-limit", "post", {
            investeeOrganizationId: investeeOrgId,
            totType: "onboarding",
            totId
        });
        if (res.status === API_STATUS.SUCCESS && res.data) setTotalAvailableLimit(res.data.availableTradingLimit);
    }

    const updateToDefaultUserTotData = (category) => {
        const obj = {
            tradingLimit: investee_details?.trading_limit,
            irr: investee_details?.irr,
            roi: getRoiFromIrr(investee_details?.irr),
            price: investee_details?.max_tenure_price,
            minTenure: investee_details?.custom_tenure_limit_min,
            maxTenure: investee_details?.custom_tenure_limit_max,
            fee: investee_details?.fees,
            isLenderFeesWaived: totData?.[RECUR_LIMIT]?.isLenderFeesWaived ?? true,
            lenderProcessingFees: totData?.[RECUR_LIMIT]?.lenderProcessingFees ?? DEFAULT_LENDER_PROCESSING_FEES,
            isWaived: investee_details?.recur_fees_waived,
            feeRes: userTotData?.[RECUR_LIMIT]?.feeRes ?? {},
            moratorium_months: investee_details?.moratorium_months,
            collectionMethods: {},
        }
        if (investee_details?.mab > 0) {
            obj.collectionMethods[MAB] = {amount: investee_details.mab}
        }
        if (!category) setUserTotData({
            [RECUR_LIMIT]: JSON.parse(JSON.stringify(obj)),
            [STANDARD]: JSON.parse(JSON.stringify(obj)),
            [MAX]: JSON.parse(JSON.stringify(obj)),
        })
        else {
            setUserTotData(temp => {
                temp[category.category] = obj;
                return temp;
            })

        }
    }

    const fetchTotData = (isUpdateToBeCalled = false, clonedTotData) => {
        callApi("admin/tot-history/get-prepare-terms-tot-details", "post", {
            investee_organization_id: investeeOrgId,
            onboarding_id: investeeOnboardedId
        }).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                const data = {...res.data};
                if ("old_rcm" in data) delete data.old_rcm;
                setIsTotAccepted(res.data?.base_tot_status === ACCEPTED);
                setTotId(data?.baseTotId);
                setTotData(data);
                const coborrowerTemp = {};
                if (data?.[STANDARD]?.secondary_rcm?.Coborrower) {
                    coborrowerTemp[STANDARD] = [...data?.[STANDARD]?.secondary_rcm?.Coborrower];
                }
                if (data?.[MAX]?.secondary_rcm?.Coborrower) {
                    coborrowerTemp[MAX] = [...data?.[MAX]?.secondary_rcm?.Coborrower];
                }
                if (!isEmpty(coborrowerTemp)) {
                    setCoborrowerList(coborrowerTemp);
                }
                if (!isEmpty(data)) updateTotData(data);
                else updateToDefaultUserTotData();
                intitalizeLimitRoadmapData(res.data?.AdminCapitalRoadmap);
            } else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
        }).then(() => {
            if (isUpdateToBeCalled) updateTot(clonedTotData);
        })
    }

    const intitalizeLimitRoadmapData = (data) => {
        if (isEmpty(data)) return;
        const firstTranchTenure = data?.tranches?.[0]?.tenure;
        setLimitRoadmapData({
            limitRoadmap: `${data.limitRoadmap || ''}`,
            tranches: data?.tranches?.map(tranch => ({
                amount: `${tranch?.amount || ''}`,
                arr: `${tranch?.arr || ''}`,
                comments: `${tranch?.comments || ''}`,
                tenure: `${getTranchTenure(tranch.tenure) || ''}`
            }))
        })
    }

    const getUserNameForCoborrower = () => {
        callApi(
            `admin/fetch-username-for-coborrower`,
            'post', {
                investee_organization_id: investeeOrgId
            }
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                setUserNameForCoborrower(
                    res.data
                );
            } else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
        });
    };

    const updateTotData = (data, initialDataUpdateRequired=true) => {
        let obj = {...userTotData};
        const defaultObj = {
            tradingLimit: investee_details?.trading_limit,
            irr: investee_details?.irr,
            roi: getRoiFromIrr(investee_details?.irr),
            price: investee_details?.max_tenure_price,
            minTenure: investee_details?.custom_tenure_limit_min,
            maxTenure: investee_details?.custom_tenure_limit_max,
            lenderProcessingFees: DEFAULT_LENDER_PROCESSING_FEES,
            isLenderFeesWaived: true,
            fee: investee_details?.fees,
            isWaived: investee_details?.recur_fees_waived,
            moratorium_months: investee_details?.moratorium_months,
            collectionMethods: {},
            feeRes: {}
        }
        if (investee_details?.mab > 0) {
            defaultObj.collectionMethods[MAB] = {amount: investee_details.mab}
        }
        if (DEBT_SYNDICATION in data)
            obj = updateCategoryInUserState(DEBT_SYNDICATION, data, obj);
        if (!(RECUR_LIMIT in data) && !(STANDARD in data) && !(MAX in data)) {
            obj[RECUR_LIMIT] = JSON.parse(JSON.stringify(defaultObj));
            obj[STANDARD] = JSON.parse(JSON.stringify(defaultObj));
            obj[MAX] = JSON.parse(JSON.stringify(defaultObj));
        } else {
            obj = updateCategoryInUserState(RECUR_LIMIT, data, obj);
            obj = updateCategoryInUserState(STANDARD, data, obj);
            obj = updateCategoryInUserState(MAX, data, obj);
        }
        setUserTotData(obj);
        if (initialDataUpdateRequired) setInitialUserTotData(obj);
        updateTexts(data);
        if (MAX in data) setShowMaxLimit(true);
        if (STANDARD in data) setShowStandardLimit(true);
    }

    const updateTexts = (data) => {
        if (DEBT_SYNDICATION in data) {
            if (!isEmpty(data[DEBT_SYNDICATION]?.cp)) setCP4(data[DEBT_SYNDICATION].cp);
            if (!isEmpty(data[DEBT_SYNDICATION]?.cs)) setCS4(data[DEBT_SYNDICATION].cs);
            if ("note" in data[DEBT_SYNDICATION]) setNote4(data[DEBT_SYNDICATION].note);
        }
        if (RECUR_LIMIT in data) {
            if (!isEmpty(data[RECUR_LIMIT]?.cp)) setRecurLimitCP(data[RECUR_LIMIT].cp);
            if (!isEmpty(data[RECUR_LIMIT]?.cs)) setRecurLimitCS(data[RECUR_LIMIT].cs);
            if ("note" in data[RECUR_LIMIT]) setNote3(data[RECUR_LIMIT].note);
        }
        if (STANDARD in data) {
            if (!isEmpty(data[STANDARD]?.cp)) setStandardCP(data[STANDARD].cp);
            if (!isEmpty(data[STANDARD]?.cs)) setStandardCS(data[STANDARD].cs);
            if ("note" in data[STANDARD]) setNote1(data[STANDARD].note);
        }
        if (MAX in data) {
            if (!isEmpty(data[MAX]?.cp)) setMaxCP(data[MAX].cp);
            if (!isEmpty(data[MAX]?.cs)) setMaxCS(data[MAX].cs);
            if ("note" in data[MAX]) setNote2(data[MAX].note);
        }
    }

    const updateCategoryInUserState = (category, data, obj) => {
        if (isEmpty(obj)) obj = {};
        if (isEmpty(obj[category])) obj[category] = {};
        obj[category].tradingLimit = data[category]?.new_unsanctioned_limit;
        obj[category].irr = data[category]?.irr;
        
        obj[category].roi = data[category]?.roi;
        obj[category].moratorium_months = data[category]?.moratorium_months;
        obj[category].price = data[category]?.max_tenure_price;
        obj[category].minTenure = data[category]?.custom_tenure_limit_min;
        obj[category].maxTenure = data[category]?.custom_tenure_limit_max;
        obj[category].fee = data[category]?.fees;
        obj[category].isWaived = data[category]?.is_recur_fee_waived;
        obj[category].lenderProcessingFees = data[category]?.lenderProcessingFees;
        obj[category].isLenderFeesWaived = data[category]?.isLenderFeesWaived;
        obj[category].collectionMethods = data[category]?.secondary_rcm ?? {};
        obj[category].feeRes = data[category]?.feeRes ?? {};
        obj[category].showRoi = data?.showRoi;
        obj[category].totalLimit = data[category]?.total_limit;
        obj[category].totStatus = data[category]?.tot_status;
        if (category === DEBT_SYNDICATION) {
            obj[category].financingStructure = data[category]?.financingStructure;
            obj[category].engagementSignDate = data[category]?.engagementSignDate;
            obj[category].elUploadedType = data[category]?.elUploadedType;
            obj[category].signedEngagementLetter = data[category]?.signedEngagementLetter;
            obj[category].maxIrr = data[category]?.maxIrr;
            obj[category].security = data[category]?.security;
        }
        if (data[category]?.mab > 0) {
            obj[category].collectionMethods[MAB] = {
                amount: data[category].mab
            };
        }
        return obj;
    }

    const fetchMonthlyStatusInfo = () => {
        updateMonthlyStatusInfo(onboardingData);
    }

    const updateMonthlyStatusInfo = (data) => {
        let obj = initialStatusInfo();
        const {
            mis,
            debtSchedule,
            financial_statement,
            invoicing,
            bank_account,
            status,
            last_updated_at,
            last_email_sent_at,
            doc_comments,
            additionalReqDocuments,
            cibil,
            gstin,
        } = data;
        let addNewBankNumber = 6;

        if (!_.isEmpty(bank_account)) {
            let currentNum = 6;
            Object.keys(bank_account).forEach((accountNumber, index) => {
                currentNum = index + 6;
                addNewBankNumber = index + 6;
                if (accountNumber !== ADD_BANK) {
                    obj[currentNum] = {...bank_account[accountNumber]};
                    obj[currentNum].category = bank_account[accountNumber].bank_name ? 'Bank (' + bank_account[accountNumber].bank_name + ' - ' + accountNumber + ')' : null;
                    obj[currentNum].isValidBankAccount = checkValidBankAccountOnboarding({
                        bankAccount: bank_account[accountNumber],
                        accountNumber
                    })
                }
            });
        }

        // Gstin NeedInfo Support
        let gstinIndex = addNewBankNumber + 1;
        if (!_.isEmpty(gstin)) {
            Object.keys(gstin).forEach((gstn) => {
                let stateName = gstin[gstn]?.state ? gstin[gstn]?.state : "";
                obj[gstinIndex] = {};
                obj[gstinIndex].status = gstin[gstn].needInfoStatus ? gstin[gstn].needInfoStatus : "";
                obj[gstinIndex].category = `GST (${
                  stateName ? `${stateName} - ` : ""
                }${gstn})${
                  gstin[gstn]?.active_status === GST_INACTIVE
                    ? ` - ${gstin[gstn].active_status}`
                    : ""
                }`;
                obj[gstinIndex].emailCategory = `GST (${stateName ? `${stateName} - ` : ""}${gstn})`;
                obj[gstinIndex].comment = gstin[gstn].comments ? gstin[gstn].comments : "";
                // Identifier for the gstn
                obj[gstinIndex].identifier = gstn;
                gstinIndex++;
            });
        }

        addNewBankNumber = gstinIndex;
        obj[addNewBankNumber] = {};
        obj[addNewBankNumber].category = ADD_BANK;
        obj[addNewBankNumber].status = bank_account?.[ADD_BANK] ?? false;
        if (!_.isEmpty(mis)) {
            obj[1] = JSON.parse(JSON.stringify(mis));
            obj[1].category = PROV_FINANCIAL;
        }
        if (financial_statement && "audited_financial_statement" in financial_statement && !_.isEmpty(financial_statement.audited_financial_statement)) {
            obj[2] = JSON.parse(JSON.stringify(financial_statement.audited_financial_statement));
            obj[2].category = AUD_FINANCIAL;
        }
        if (!_.isEmpty(invoicing)) {
            obj[3] = JSON.parse(JSON.stringify(invoicing));
            obj[3].category = INVOICING;
        }
        if (!_.isEmpty(debtSchedule)) {
            obj[4] = JSON.parse(JSON.stringify(debtSchedule));
            obj[4].category = DEBT_SCHEDULE;
        }
        if (!_.isEmpty(cibil)) {
            obj[5] = JSON.parse(JSON.stringify(cibil));
            obj[5].category = DIRECTOR_DETAILS;
        }
        setStatusInfo(JSON.parse(JSON.stringify(obj)));
        statusInfoRef.current = JSON.parse(JSON.stringify(obj));
        if (status) {
            setStatus(status);
            statusRef.current = status;
        }
        setLastUpdated(last_updated_at);
        setLastEmail(last_email_sent_at);
    };

    const renderSingleCategory = keyName => {
        const {category, status, comment, is_nach_enabled, isValidBankAccount, identifier} = statusInfo[keyName];
        if (category === ADD_BANK) {
            return (
                <span key={keyName} className='fs12 fw300 w-50 dflex-ac'>
                    <input
                        type="checkbox"
                        id='addAccountOb1'
                        className='reviewFinancialCheckbox'
                        checked={status}
                        onChange={e => isModerating && handleStatusInfoChange(e.target.checked, keyName, ADD_BANK)}
                        disabled={!isModerating}
                    />
                    <label htmlFor='addAccountOb1' className='ml-2'><i>Ask to add another bank account & investments</i></label>
                </span>
            );
        }
        if (isModerating) {
            return (
                <span key={keyName}>
                    <div className='dflex-ja'>
                        <div className='fs12 fw500 w-50'>{category}</div>
                        <div className='fs10 fw300 w-50 dflex-ja'>
                            {keyName >= 6 && isEmpty(identifier) && <span className='mr-5 dflex-ac'>
                                <input
                                    type='checkbox'
                                    className='mr-2 reviewFinancialCheckbox'
                                    checked={is_nach_enabled === true}
                                    onChange={e => {
                                        if (isValidBankAccount === false) {
                                            showNotification(SHOW_NOTIFICATION.ERROR, "Invalid Account Details , Cannot be  Nach Account")
                                            return;
                                        }
                                        handleStatusInfoChange(e.target.checked, keyName, IS_NACH)
                                    }}
                                />
                                <span><i>NACH</i></span>
                            </span>}
                            <span className='mr-5 dflex-ac'>
                                <input
                                    type='checkbox'
                                    className='mr-2 reviewFinancialCheckbox'
                                    checked={status === APPROVED}
                                    onChange={e => handleStatusInfoChange(e.target.checked, keyName, APPROVED)}
                                />
                                <span><i>Approve</i></span>
                            </span>
                            <div className="DocumentVaultSwitch ml-">
                                <div className={toggleContainer}>
                                    <ToggleButton
                                        checked={status === ONBOARDING_STATUS.NEEDINFOOPTIONAL}
                                        onChange={e => handleStatusInfoChange(e.target.checked, keyName, ONBOARDING_STATUS.NEEDINFOOPTIONAL)}
                                    />
                                </div>
                            </div>
                            <span className='dflex-ac'>
                                <input
                                    type='checkbox'
                                    className='mr-2 reviewFinancialCheckbox'
                                    checked={status === NEEDINFO}
                                    onChange={e => handleStatusInfoChange(e.target.checked, keyName, NEEDINFO)}
                                />
                                <span><i>Need more Info</i></span>
                            </span>
                        </div>
                    </div>
                    <DynamicValue
                        placeholder={NEED_INFO_ONBOARDING_STATUS.includes(status) && 'Enter Comments'}
                        required
                        disabled={!NEED_INFO_ONBOARDING_STATUS.includes(status)}
                        value={comment}
                        onChange={e => handleStatusInfoChange(e.target.value, keyName)}
                    />
                    <br/><br/>
                </span>
            );
        }
        return (
            <span key={keyName}>
                <div className='dflex-ja'>
                    <div className='fs12 fw500 w-50'>{category}</div>
                    <div className='fs10 fw300 w-50 dflex-ja'>
                        {keyName >= 6 && isEmpty(identifier) && <span className='mr-3 dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2 reviewFinancialCheckbox'
                                checked={is_nach_enabled === true}
                                disabled
                            />
                            <span><i>NACH</i></span>
                        </span>}
                        <span className='dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2 reviewFinancialCheckbox'
                                checked={status === APPROVED}
                                disabled
                            />
                            <span><i>Approve</i></span>
                        </span>
                        <div className="DocumentVaultSwitch ml-">
                            <div className={toggleContainer}>
                                <ToggleButton
                                    checked={status === ONBOARDING_STATUS.NEEDINFOOPTIONAL}
                                    disabled
                                />
                            </div>
                        </div>
                        <span className='dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2 reviewFinancialCheckbox'
                                checked={status === NEEDINFO}
                                disabled
                            />
                            <span><i>Need more Info</i></span>
                        </span>
                    </div>
                </div>
                <DynamicValue
                    required
                    disabled
                    value={comment}
                />
                <br/><br/>
            </span>
        );
    }

    const renderStatus = () => {
        return (
            <div className='dflex-ja mr-4'>
                <div className='fs12 fw500 w-25'>Status:</div>
                <div className='w-75'>
                    <SelectBox value={status} onChange={e => setStatus(e.target.value)} disabled={!isModerating}>
                        <option value={status} disabled hidden>{status.trim() ? status : "Select status"}</option>
                        {statusOptions.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </SelectBox>
                </div>
            </div>
        );
    }

    const disableEmail = () => {
        if(applicationStatus === EXPIRED || applicationStatus === WAITLISTED)return true;
        if (dataType === "monthly" && due_date === ONBOARDING) {
            return (status === "Approved" || status === "Spark Approved") && !emailCheck;
        } else if (dataType === "documentation") {
            return !NEED_INFO_ONBOARDING_STATUS.includes(status);
        }
    }

    const disableSwiftEmail = () => {
        if (applicationStatus === EXPIRED || applicationStatus == WAITLISTED) return true;
        if (
            sendingEmail &&
            !investee_details.backgroundProcessRunning
        )
            return true;
        return false;
    };

    const disableSave = () => {
        if (applicationStatus === EXPIRED || applicationStatus == WAITLISTED) return true;
        if (currentStep === 1 && dataType === "monthly" && due_date === ONBOARDING) {
            return (status === APPROVED || status === SPARK_APPROVED) && !emailCheck;
        }
        if (currentStep === 2 && (!rrfTermToggle)) return true;
        else if ((currentStep === 3) && (!debtSyndicationToggle)) return true;
        return false;
    }
    
    const disableModerate = () => {
        if (applicationStatus === EXPIRED || applicationStatus === WAITLISTED) return true;
        if (currentStep === 2 && (!rrfTermToggle)) return true;
        else if ((currentStep === 3) && (!debtSyndicationToggle)) return true;
        return false;
    }

    const renderButtons = () => {
        if (isModerating) {
            return (
                <div style={currentStep === 2 ? {
                    width: '40rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                } : {}}>
                    {currentStep !== 1 && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS && (
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{fontSize: '0.625rem', fontFamily: 'Gilroy-Semibold,serif', margin: 0}}>Terms
                                Active Till Date:</p>
                            <input
                                type={"date"}
                                className={styles.totExpiryDatePicker}
                                value={totData?.[RECUR_LIMIT]?.tot_expiry_date ? moment(totData?.[RECUR_LIMIT].tot_expiry_date).format("YYYY-MM-DD") : moment().add(30, 'days').format("YYYY-MM-DD")}
                                onFocus={e => e.target.showPicker()}
                                onChange={e => {
                                    const obj = {...totData};
                                    obj[RECUR_LIMIT].tot_expiry_date = new Date(e.target.value).toISOString();
                                    if (obj[STANDARD]) obj[STANDARD].tot_expiry_date = new Date(e.target.value).toISOString();
                                    if (obj[MAX]) obj[MAX].tot_expiry_date = new Date(e.target.value).toISOString();
                                    setTotData(obj);
                                    detectChange[RECUR_LIMIT] = true;
                                }}
                            />
                        </div>)}
                    <div style={{width: '15rem', display: "flex", alignItems: 'center', gap: '0.5rem'}}>
                        <button
                            className="mr-4 btn6 btnht1 d-flex align-items-center justify-content-center"
                            onClick={onCancel}
                        >
                            <img
                                src="../../../../../assets/icon-clear.svg"
                                alt="cross"
                                className="mr-2"
                                style={{width: '11px'}}
                            />
                            <span>Cancel</span>
                        </button>
                        <button
                            className="save-btn btn5 btnht1 d-flex align-items-center justify-content-center"
                            style={disableSave() ? {cursor: 'not-allowed'} : null}
                            onClick={() => onSave()}
                            disabled={disableSave() || isSaveClicked}
                        >
                            <SaveSVG/>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    {currentStep === 1 && (
                        <EmailUserBtn onClick={emailUser} disabled={disableEmail()}>
                            <img src="/assets/email.svg" alt="Email"/>
                            <span>Email User</span>
                        </EmailUserBtn>
                    )}
                    {currentStep === 2 && totData?.base_tot_status !== TOT_STATUS.ACCEPTED && (
                        <>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginRight:'4rem'}}>
                                <p style={{fontSize: '0.625rem', fontFamily: 'Gilroy-Semibold,serif', margin: 0}}>Terms
                                    Active Till Date:</p>
                                <input
                                    type={"date"}
                                    className={styles.totExpiryDatePicker}
                                    value={totData?.[RECUR_LIMIT]?.tot_expiry_date ? moment(totData?.[RECUR_LIMIT].tot_expiry_date).format("YYYY-MM-DD") : moment().add(30, 'days').format("YYYY-MM-DD")}
                                    onFocus={e => e.target.showPicker()}
                                    onChange={e => {
                                        const obj = {...totData};
                                        obj[RECUR_LIMIT].tot_expiry_date = new Date(e.target.value).toISOString();
                                        if (obj[STANDARD]) obj[STANDARD].tot_expiry_date = new Date(e.target.value).toISOString();
                                        if (obj[MAX]) obj[MAX].tot_expiry_date = new Date(e.target.value).toISOString();
                                        setTotData(obj);
                                        detectChange[RECUR_LIMIT] = true;
                                    }}
                                />
                            </div>
                            <EmailUserBtn onClick={emailSwitftUser} disabled={disableSwiftEmail()}>
                                <img src="/assets/email.svg" alt="Email"/>
                                <span>Email Swift Terms</span>
                            </EmailUserBtn>
                        </>
                    )}
                    {currentStep === 3 && !isEmpty(totData[DEBT_SYNDICATION]) && (
                        <EmailUserBtn onClick={emailScaleUser} disabled={disableEmail() || sendingEmail}>
                            <img src="/assets/email.svg" alt="Email"/>
                            <span>Email Scale DS Terms</span>
                        </EmailUserBtn>
                    )}
                    <ModerateBtn disabled={disableModerate()} onClick={() => setIsModerating(true)}>
                        <img className="mr-1" src="/assets/moderate-btn-icon.svg" alt="Moderate"/>{' '}
                        <span>Moderate</span>
                    </ModerateBtn>
                </>
            );
        }
    };

    const handleStatusInfoChange = (val, keyName, statusCategory) => {
        if (isModerating) {
            if (statusCategory === ADD_BANK) {
                const obj = {...statusInfo};
                obj[keyName].status = val;
                setStatusInfo(obj);
            } else if (statusCategory === IS_NACH) {
                const obj = {...statusInfo};
                if (val) {
                    for (let key of Object.keys(obj)) {
                        if (obj[key]?.is_nach_enabled === true) {
                            showNotification(SHOW_NOTIFICATION.ERROR, "Cannot Set More Than one Nach Account")
                            return;
                        }
                    }
                }
                obj[keyName].is_nach_enabled = val;
                setStatusInfo(obj);
            } else if (statusCategory) {
                const obj = {...statusInfo};
                if (val) {
                    obj[keyName].status = statusCategory;
                    if (statusCategory === APPROVED && obj[keyName].comment) obj[keyName].comment = "";

                } else obj[keyName].status = "";
                setStatusInfo(obj);
            } else {
                const obj = {...statusInfo};
                obj[keyName].comment = val;
                setStatusInfo(obj);
            }
        }
    }

    const emailUser = async () => {
        if (status === WAITLISTED) {
            const res = await sendWaitlistedEmailHelper(investeeOrganizationId, IDENTIFIER_TYPE.ONBOARDING, investeeOnboardedId)
            if (res?.responseData?.responseCode === 20) {
                setLastEmail(moment(res?.lastEmailSentDate).format('HH:MM DD-MMM-YYYY'))
                showNotification(SHOW_NOTIFICATION.SUCCESS, res?.responseData.responseMessage ?? 'Email sent successfully')
            } else {
                showNotification(SHOW_NOTIFICATION.ERROR, res?.responseData?.responseMessage ?? "Couldn't send Email!")
            }
        } else {
            let endpoint;
            if (dataType === "monthly") {
                endpoint = `v2/investee-onboarding/${investeeOnboardedId}/email-user?path=${path}`;
            } else if (dataType === "documentation") {
                endpoint = `/admin/onboarding-phase2/${investeeOnboardedId}/need_info_mail`;
            }
            callApi(endpoint, "get").then(res => {
                if (res.status === "Success") {
                    showNotification("Success", "Email sent successfully");
                    if (dataType === "monthly") {
                    } else if (dataType === "documentation") {
                        setLastEmail(res.data?.last_email_sent_at);
                    }
                } else {
                    const message = res.data?.message ? res.data?.message : GENERIC_ERROR;
                    showNotification(SHOW_NOTIFICATION.ERROR, message);
                }
            });
        }
    }

    const emailSwitftUser = async () => {
        setIsSendingEmail(true);
        const emailStatus = await emailSwiftTermsToUser(totId, {
            sendWithoutEmail: false,
            inputNewUnSanc: 0,
        });
        if (emailStatus) {
            showNotification(SHOW_NOTIFICATION.SUCCESS, "Email for Swift Terms sent successfully!");
        }
        setIsSendingEmail(false);
    }

    const emailScaleUser = async () => {
        try {
            const stageTotId = totData[DEBT_SYNDICATION]?.stage_id;
            if (!stageTotId) {
                showNotification('error', "stage id donot exist")
                return
            }
            setIsSendingEmail(true);
            const res = await sendScaleTot(stageTotId);
            if (res.data.data === 'Tot Sent') showNotification('success', 'Email sent successfully');
            else showNotification('error', "Signed EL already uploaded please remove it")
        } catch (err) {
            showNotification('error', "Signed EL already uploaded please remove it")
        } finally {
            setIsSendingEmail(false)
        }

    }

    const onCancel = () => {
        if (currentStep === 1) {
            if (showReqStatusOptions) setShowReqStatusOptions(false);
            if (!_.isEqual(statusInfo, statusInfoRef.current)) setStatusInfo(JSON.parse(JSON.stringify(statusInfoRef.current)));
            if (status !== statusRef.current) setStatus(statusRef.current);
        } else if (currentStep === 2 || currentStep === 3) {
            let data = totData;
            if (data?.[STANDARD]?.secondary_rcm?.Coborrower && coborrowerList[STANDARD])
                data[STANDARD].secondary_rcm.Coborrower = coborrowerList[STANDARD];
            if (data?.[MAX]?.secondary_rcm?.Coborrower && coborrowerList[MAX])
                data[MAX].secondary_rcm.Coborrower = coborrowerList[MAX];
            updateTotData(data, false);
            setDetectChange({
                [DEBT_SYNDICATION]: false,
                [RECUR_LIMIT]: false,
                [STANDARD]: false,
                [MAX]: false
            });
        }
        setIsModerating(false);
    }

    const onSave = async (adminUpdateOveride = false) => {
        try {
            setIsSaveClicked(true);
            const onSuccess = () => {
                fetchMonthlyData();
                fetchTotData();
                setIsModerating(false);
                setIsSaveClicked(false);
            }
            if (currentStep === 1) {
                const changedDealReport = {...dealReportState};
                
                if (status === APPROVED || status === WAITLISTED || status === APPROVED_RUNAWAY) {
                    if (changedDealReport.runway == null) {
                        showNotification(SHOW_NOTIFICATION.ERROR, "Runway is mandatory");
                        return;
                    }
                    if (changedDealReport.debtPercent == null) {
                        showNotification(SHOW_NOTIFICATION.ERROR, "Debt(%) is mandatory");
                        return;
                    }
                    if (changedDealReport.ebitdaPercent == null) {
                        showNotification(SHOW_NOTIFICATION.ERROR, "EBITDA(%) is mandatory");
                        return;
                    }
                }
                if (status === WAITLISTED && (rrfTermToggle || debtSyndicationToggle)) {
                    showNotification(SHOW_NOTIFICATION.ERROR, WAIT_LISTING_TOAST_TEXT);
                    return;
                }
                if (investee_details.status === INVESTEE_ORG_UNCLAIMED) {
                    showNotification(SHOW_NOTIFICATION.ERROR, `The organization needs to be claimed before we can moderate`);
                    return;
                }

                if (status === APPROVED || status === APPROVED_RUNAWAY) {
                    const mandatory_investee_fields = ["display_name", "company_sector", "company_sub_sector", "founded_in", "funding", "company_overview"];
                    const mandatory_onboarding_details = ["cin_number", "pan_number", "gstin_number", "company_website", "current_company_address"];
                    let absent_mandatory_fields = [];
                    for (const field of mandatory_investee_fields) {
                        const present = field in investee_details;
                        if (present && isEmpty(investee_details[field])) absent_mandatory_fields.push(field);
                        else if (!present) absent_mandatory_fields.push(field);
                    }
                    for (const field of mandatory_onboarding_details) {
                        const present = field in onboardingData;
                        if (present && isEmpty(onboardingData[field])) absent_mandatory_fields.push(field);
                        else if (!present) absent_mandatory_fields.push(field);
                    }
                    absent_mandatory_fields = absent_mandatory_fields?.map((field) => field = mandatory_fields_enum[field]);
                    const notification_fields = absent_mandatory_fields.join(', ');
                    if (!isEmpty(notification_fields)) {
                        showNotification(SHOW_NOTIFICATION.ERROR, `Please fill ${notification_fields}`);
                        return;
                    }
                }
                let endpoint;
                const obj = {
                    status,
                    comment: note1
                }
                endpoint = `investee-onboarding/v2/${investeeOnboardedId}/moderate?path=Onboarding`;
                obj.bank_account = {};
                Object.keys(statusInfo).forEach(key => {
                    const tempObj = {...statusInfo[key]};
                    switch (key) {
                        case '1':
                            obj.mis = {...tempObj};
                            break;
                        case '2':
                            obj.financial_statement = {};
                            obj.financial_statement.audited_financial_statement = {...tempObj};
                            break;
                        case '3':
                            obj.invoicing = {...tempObj};
                            break;
                        case '4':
                            obj.debt_schedule = {...tempObj};
                            break;
                        case '5':
                            obj.cibil = {...tempObj};
                            break;
                        default:
                            if (statusInfo[key].identifier) {
                                if(!obj.gstin) obj.gstin = {};
                                obj.gstin[statusInfo[key]?.identifier] = {
                                    category: statusInfo[key]?.emailCategory,
                                    gstin_number: statusInfo[key]?.identifier,
                                    status: statusInfo[key].status ? statusInfo[key].status : "Open",
                                    comments: statusInfo[key].comment ? statusInfo[key].comment : "",
                                }
                            }
                            else if (tempObj.category === ADD_BANK) {
                                obj.bank_account[ADD_BANK] = tempObj.status;
                            } else {
                                if (!tempObj.status) tempObj.status = "Open";
                                obj.bank_account[tempObj.account_number] = {...tempObj};
                            }
                    }
                });
                if (isAddnInfo) {
                    obj.additionalRequiredDocuments = addnInfo.map((item) => {
                        const {status, query, requireDocument, requireText, comment, otherValue} = item
                        return {
                            status,
                            query: otherValue?.length ? otherValue : query,
                            requireDocument,
                            requireText,
                            comment
                        }
                    });
                }
                
                if (onboardingComment) obj.comment = onboardingComment;
                const moderateRes = await callApi(endpoint, "post", obj)
                if (moderateRes.status === "Success") {
                    onSuccess();
                    showNotification("Success", "Saved successfully");
                    updateMonthlyStatusInfo(moderateRes.data);
                    fetchMonthlyData();
                } else {
                    setIsSaveClicked(false);
                    showNotification(SHOW_NOTIFICATION.ERROR, moderateRes?.data?.message ?? GENERIC_ERROR);
                }
            } else if (currentStep === 2 || currentStep === 3) {
                const clonedTotData = JSON.parse(JSON.stringify(totData));
                const debtSyndicationData = {};
                if ("old_rcm" in clonedTotData) delete clonedTotData.old_rcm;
                if ("base_tot_status" in clonedTotData) delete clonedTotData.base_tot_status;
                if ("acceptedTot" in clonedTotData) delete clonedTotData.acceptedTot;
                if ("baseTotId" in clonedTotData) delete clonedTotData.baseTotId;
                if ("emailSentDate" in clonedTotData) delete clonedTotData.emailSentDate;
                if ("AdminCapitalRoadmap" in clonedTotData) delete clonedTotData.AdminCapitalRoadmap;
                if (DEBT_SYNDICATION in clonedTotData) {
                    debtSyndicationData[DEBT_SYNDICATION] = clonedTotData[DEBT_SYNDICATION];
                    delete clonedTotData[DEBT_SYNDICATION];
                }

                if ((!isTotAccepted && limitRoadmapData?.limitRoadmap) && !isLimitRoadmapDataValid()) {
                    setIsSaveClicked(false);
                    return;
                }
                if (showMaxLimit) {
                    const maxTradingLimit = Number(userTotData[MAX]?.tradingLimit);
                    const stdTradingLimit = Number(userTotData[STANDARD]?.tradingLimit);
                    if (maxTradingLimit < stdTradingLimit) {
                        showNotification(SHOW_NOTIFICATION.ERROR, "Max trading limit must be greater than standard trading limit")
                        setIsSaveClicked(false);
                        return;
                    }
                }
                const currentTotCount = getCurrentTotCount(clonedTotData);
                // const createDebtSyndicationLimit = (showDebtSyndication);
                const createDebtSyndicationLimit = isEmpty(debtSyndicationData);
                const createRecurLimit = isEmpty(clonedTotData);
                const createStdLimit = (currentTotCount === 1 && showStandardLimit);
                const createMaxLimit = (currentTotCount === 2 && showMaxLimit);
                const isCreateToBeCalled = createRecurLimit || createStdLimit || createMaxLimit;
                const isUpdateToBeCalled = (currentTotCount === 1 && detectChange[RECUR_LIMIT])
                    || (currentTotCount === 2 && (detectChange[RECUR_LIMIT] || detectChange[STANDARD]))
                    || (currentTotCount === 3 && (detectChange[RECUR_LIMIT] || detectChange[STANDARD] || detectChange[MAX]))
                    || (currentTotCount === 3 && !showMaxLimit)
                    || (currentTotCount === 2 && !showStandardLimit)
                    || (currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS && detectChange[DEBT_SYNDICATION]);

                if (isCreateToBeCalled && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
                    createTot(isUpdateToBeCalled, clonedTotData)
                } else if (createDebtSyndicationLimit && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS) {
                    createTot(false, debtSyndicationData)
                } else if (isUpdateToBeCalled && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
                    updateTot(clonedTotData, adminUpdateOveride);
                } else if (isUpdateToBeCalled && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS) {
                    updateTot(debtSyndicationData);
                }
            }
        } catch (err) {
            showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
        } finally {
            setIsSaveClicked(false)
        }
    }

    const createTot = (isUpdateToBeCalled = false, clonedTotData) => {
        const currentTotCount = getCurrentTotCount(clonedTotData);
        callCreateTotApi({
            category: {
                [DEBT_SYNDICATION]: (currentTotCount === 0 && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS),
                [RECUR_LIMIT]: (currentTotCount === 0 && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS),
                [STANDARD]: (currentTotCount === 0 || currentTotCount === 1) && showStandardLimit && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS,
                [MAX]: (currentTotCount === 0 || currentTotCount === 1 || currentTotCount === 2) && showMaxLimit && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS
            },
            isUpdateToBeCalled,
            clonedTotData
        });
    }

    const updateTot = (clonedTotData, adminUpdateOveride = false) => {
        const currentTotCount = getCurrentTotCount(clonedTotData); // Description: Subtracted 1 from the length as showRoi is being used here which is not a part of the clonedTotData
        callUpdateTotApi({
            category: {
                [DEBT_SYNDICATION]: detectChange[DEBT_SYNDICATION] && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS,
                [RECUR_LIMIT]: detectChange[RECUR_LIMIT] && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS,
                [STANDARD]: (detectChange[STANDARD] || (currentTotCount === 2 && !showStandardLimit)) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS,
                [MAX]: (detectChange[MAX] || (currentTotCount === 3 && !showMaxLimit)) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS
            },
            isDeletedInMax: currentTotCount === 3 && !showMaxLimit,
            isDeletedInStandard: currentTotCount === 2 && !showStandardLimit,
            adminUpdateOveride
        });
    }

    const handleValidation = (category) => {
        const COBORROWER = "Coborrower";
        const START_DATE = "start_date";
        const END_DATE = "end_date";
        const AMOUNT = "amount";
        let isValid = true;
        let message = '';
        const methods = {...userTotData[category].collectionMethods};
        const validateDates = (type) => {
            if (!methods[type]?.[START_DATE]) {
                isValid = false;
                message = `Start date of ${type} is not entered`;
            } else if (methods[type]?.[START_DATE] < 1) {
                isValid = false;
                message = `Start date of ${type} is invalid`;
            } else if (!methods[type]?.[END_DATE]) {
                isValid = false;
                message = `End date of ${type} is not entered`;
            } else if (Number(methods[type]?.[END_DATE]) > 31) {
                isValid = false;
                message = "End date can not be greater than 31";
            } else if (Number(methods[type]?.[START_DATE]) >= Number(methods[type]?.[END_DATE])) {
                isValid = false;
                message = "Start date can not be greater than or equal to end date";
            }
        }
        const validateAmount = (category) => {
            if (typeof methods[category] !== "object" || !methods[category] || !(AMOUNT in methods[category]) || !methods[category][AMOUNT]) {
                isValid = false;
                message = `Please enter amount for ${category}`;
            }
        }
        const validateCoborrower = (category) => {
            const TYPE = "type";
            const NAME = "name";
            if (!isEmpty(methods[category])) {
                for (const data of methods[category]) {
                    if (!(data[NAME] && data[TYPE])) {
                        isValid = false;
                        message = `Please enter coborrower details`
                        return;
                    }
                }
            }
        }
        if (MAB in methods) validateAmount(MAB);
        if (isValid && DIRECT_COLLECTION_KEY in methods) validateAmount(DIRECT_COLLECTION_KEY);
        if (isValid && RAZORPAY_SPLIT_KEY in methods) validateDates(RAZORPAY_SPLIT_KEY);
        if (isValid && CASHFREE_SPLIT_KEY in methods) validateDates(CASHFREE_SPLIT_KEY);
        if (isValid && COBORROWER in methods) validateCoborrower(COBORROWER);
        return {
            isValid,
            message
        }
    }

    const getObjToSend = (category) => {
        const emptyStrRemover = (state, setState) => {
            const arr = state.filter(item => item.trim() !== "");
            return arr;
        }
        const obj = {...totData[category]};
        obj.identifier = category;
        obj.new_unsanctioned_limit = Number(userTotData[category]?.tradingLimit) ?? userTotData[category]?.tradingLimit;

        if (((showMaxLimit && limitRoadmapData?.limitRoadmap && category === MAX) || ((showStandardLimit && !showMaxLimit) && limitRoadmapData?.limitRoadmap && category === STANDARD)) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS)
            obj.total_limit = Number(limitRoadmapData.limitRoadmap);
        else if ((showStandardLimit && showMaxLimit) && limitRoadmapData?.limitRoadmap && category === STANDARD && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS)
            obj.total_limit = obj.new_unsanctioned_limit;
        else obj.total_limit = null;

        obj.irr = Number(userTotData[category]?.irr) ?? userTotData[category]?.irr;
        obj.roi = Number(userTotData[category]?.roi) ?? userTotData[category]?.roi;
        obj.moratorium_months = Number(userTotData[category]?.moratorium_months) ?? userTotData[category]?.moratorium_months;
        obj.custom_tenure_limit_min = Number(userTotData[category]?.minTenure) ?? userTotData[category]?.minTenure;
        obj.custom_tenure_limit_max = Number(userTotData[category]?.maxTenure) ?? userTotData[category]?.maxTenure;
        obj.fees = Number(userTotData[category]?.fee) ?? userTotData[category]?.fee;
        obj.is_recur_fee_waived = userTotData[category]?.isWaived;
        obj.lenderProcessingFees = userTotData[category]?.lenderProcessingFees;
        obj.isLenderFeesWaived = userTotData[category]?.isLenderFeesWaived;
        obj.secondary_rcm = userTotData[category]?.collectionMethods;
        obj.mab = Number(userTotData[category]?.collectionMethods?.[MAB]?.amount) ?? userTotData[category]?.collectionMethods?.[MAB]?.amount;
        if (category === DEBT_SYNDICATION) {
            obj.financingStructure = userTotData[category]?.financingStructure;
            obj.engagementSignDate = userTotData[category]?.engagementSignDate;
            obj.signedEngagementLetter = userTotData[category]?.signedEngagementLetter;
            obj.elUploadedType = userTotData[category]?.elUploadedType;
            obj.maxIrr = Number(userTotData[category]?.maxIrr) ?? userTotData[category]?.maxIrr;
            obj.security = userTotData[category]?.security;
        }
        delete obj.secondary_rcm?.mab;
        obj.cp = category === RECUR_LIMIT ? emptyStrRemover([...recurLimitCP], setRecurLimitCP) : category === STANDARD ? emptyStrRemover([...standardCP], setStandardCP) : category === DEBT_SYNDICATION ? emptyStrRemover([...CP4], setCP4) : emptyStrRemover([...maxCP], setMaxCP);
        obj.cs = category === RECUR_LIMIT ? emptyStrRemover([...recurLimitCS], setRecurLimitCS) : category === STANDARD ? emptyStrRemover([...standardCS], setStandardCS) : category === DEBT_SYNDICATION ? emptyStrRemover([...CS4], setCS4) : emptyStrRemover([...maxCS], setMaxCS);
        obj.note = category === STANDARD ? note1 : category === MAX ? note2 : category === DEBT_SYNDICATION ? note4 : note3;
        return obj;
    }

    const handleTotValidation = (obj, terms) => {
        let message = '';
        let isValid = true;
       
        if(currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS && terms === PREPARE_TERMS_TAB.DS_TERMS ){
            if(isNil(obj?.maxIrr) || obj?.irr <= 0 || obj.maxIrr <= 0 || (obj?.signedEngagementLetter === "" && obj?.elUploadedType !== "Standard EL") ){
                message = "All details are not filled";
                isValid = false;
            } 
            if(obj.maxIrr < obj.irr){
                message = "Maximum ROI cannot be less than the Minimum ROI";
                isValid = false; 
            } 

            if(obj?.maxTenure > 0 && obj.maxTenure < obj.minTenure){
                message = "Maximum Tenure cannot be less than the Minimum Tenure";
                isValid = false; 
            } 
        } else if(terms === PREPARE_TERMS_TAB.RRF_TERMS) {
            if (!obj || !Number(obj.tradingLimit) || !Number(obj.irr) || !Number(obj.minTenure) || !Number(obj.maxTenure) || !Number(obj.fee)) {
                message = "All details are not filled";
                isValid = false;
            } 
        }


        if (!isValid) setIsSaveClicked(false);
        return {
            isValid,
            message,
        }
    }

    const onCreateSuccess = (isUpdateToBeCalled = false, tots = [], updatedData, clonedTotData) => {
        let createdTotIdentifier = null;

        if (tots?.length === 1) {
            createdTotIdentifier = tots?.[0]?.identifier;
            setTotData((tots) => ({
                ...tots,
                [createdTotIdentifier]: {
                    ...tots[createdTotIdentifier],
                    ...updatedData
                }
            }))
            if (isUpdateToBeCalled) updateTot(clonedTotData);
        }
        fetchTotData(isUpdateToBeCalled, clonedTotData);
        setIsModerating(false);
        dispatch(fetchInvesteeDetails(investeeOrgId));
    }

    const callCreateTotApi = (props) => {
        const {category, isUpdateToBeCalled, clonedTotData} = props ?? {};
        if (category?.[STANDARD]) {
            const validateStandard = handleTotValidation(userTotData[STANDARD]);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
        }
        if (category?.[MAX]) {
            const validateMax = handleTotValidation(userTotData[MAX]);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
        }
        if (category?.[RECUR_LIMIT]) {
            const validateRecurLimit = handleTotValidation(
                userTotData[RECUR_LIMIT]
            );
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
        }
        if (category?.[DEBT_SYNDICATION]) {
            const validateDebtSyndication = handleTotValidation(userTotData[DEBT_SYNDICATION]);
            if (!validateDebtSyndication.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
                return;
            }
        }
        const tots = [];
        const recurLimitObj = getObjToSend(RECUR_LIMIT);
        recurLimitObj.tot_expiry_date = dayjs(new Date()).add(30, "day").format("YYYY-MM-DD");
        const standardObj = getObjToSend(STANDARD);
        standardObj.tot_expiry_date = dayjs(new Date()).add(30, "day").format("YYYY-MM-DD");
        const maxObj = getObjToSend(MAX);
        maxObj.tot_expiry_date = dayjs(new Date()).add(7, "day").format("YYYY-MM-DD");
        const debtSyndicationObj = getObjToSend(DEBT_SYNDICATION);
        let validateRecurLimit, validateStandard, validateMax, validateDebtSyndication;
        if (category?.[STANDARD]) {
            validateStandard = handleValidation(STANDARD);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
            tots.push(standardObj);
            setDetectChange(state => ({
                ...state,
                [STANDARD]: false
            }))
        }
        if (category?.[MAX]) {
            validateMax = handleValidation(MAX);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
            if (showMaxLimit) tots.push(maxObj);
            setDetectChange(state => ({
                ...state,
                [MAX]: false
            }))
        }
        if (category?.[RECUR_LIMIT]) {
            validateRecurLimit = handleValidation(RECUR_LIMIT);
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
            tots.push(recurLimitObj);
            setDetectChange(state => ({
                ...state,
                [RECUR_LIMIT]: false
            }))
        }
        if (category?.[DEBT_SYNDICATION]) {
            validateDebtSyndication = handleValidation(DEBT_SYNDICATION);
            if (!validateDebtSyndication.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
                return;
            }
            tots.push(debtSyndicationObj);
            setDetectChange(state => ({
                ...state,
                [DEBT_SYNDICATION]: false
            }))
        }
        const body = {
            baseTotUpdates: {
                "discount_expiry_date": userTotData[RECUR_LIMIT]?.discount_expiry_date,
                "showRoi": userTotData[RECUR_LIMIT]?.showRoi,
            },
            investee_organization_id: investeeOrgId,
            onboarding_id: investeeOnboardedId,
            tots,
        };
        if (currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
            body.capitalLimit = getLimitRoadmapPayload()
            body.stage_id = totData[RECUR_LIMIT]?.stage_id;
        } else {
            body.stage_id = totData[DEBT_SYNDICATION]?.stage_id;
        }
        callApi("/admin/tot-history/addTot", "post", body).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                const updatedData = res.data;
                onCreateSuccess(isUpdateToBeCalled, tots, updatedData, clonedTotData);
            } else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
            setIsSaveClicked(false);
        });
    }
    const handleUserUpdateConfirmation = (data) => {
        setShowTotUpdatePopUp(true);
        setModalMessaging(data.messaging)
    }
    const handleUserUpdateCancel = () => {
        setShowTotUpdatePopUp(false);
        onCancel();
    }
    // Updating a Term
    const handleConfirmUpdate = () => {
        setShowTotUpdatePopUp(false);
        onSave(true);
    }
    const callUpdateTotApi = (props) => {
        const {category, isDeletedInMax, isDeletedInStandard, adminUpdateOveride} = props ?? {};
        const onSuccess = () => {
            fetchTotData();
            setIsModerating(false);
            dispatch(fetchInvesteeDetails(investeeOrgId));
        }
        if (category?.[RECUR_LIMIT]) {
            const validateRecurLimit = handleTotValidation(
                userTotData[RECUR_LIMIT],PREPARE_TERMS_TAB.RRF_TERMS
            );
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
        }
        if (category?.[STANDARD]) {
            const validateStandard = handleTotValidation(userTotData[STANDARD],PREPARE_TERMS_TAB.RRF_TERMS);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
        }
        if (category?.[MAX]) {
            const validateMax = handleTotValidation(userTotData[MAX], PREPARE_TERMS_TAB.RRF_TERMS);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
        }
        if (category?.[DEBT_SYNDICATION]) {
            const validateDebtSyndication = handleTotValidation(userTotData[DEBT_SYNDICATION], PREPARE_TERMS_TAB.DS_TERMS);
            if (!validateDebtSyndication.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
                return;
            }
        }
        const tots = [];
        const recurLimitObj = getObjToSend(RECUR_LIMIT);
        recurLimitObj.stage_tot_id = totData[RECUR_LIMIT]?._id;
        const standardObj = getObjToSend(STANDARD);
        standardObj.stage_tot_id = totData[STANDARD]?._id;
        if (isDeletedInStandard) standardObj.is_deleted = true;
        const maxObj = getObjToSend(MAX);
        maxObj.stage_tot_id = totData[MAX]?._id;
        if (isDeletedInMax) maxObj.is_deleted = true;
        const debtSyndicationObj = getObjToSend(DEBT_SYNDICATION);
        debtSyndicationObj.stage_tot_id = totData[DEBT_SYNDICATION]?._id;
        let validateStandard, validateMax, validateRecurLimit, validateDebtSyndication;
        if (category?.[STANDARD]) {
            validateStandard = handleValidation(STANDARD);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
            tots.push(standardObj);
        }
        if (category?.[MAX]) {
            validateMax = handleValidation(MAX);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
            tots.push(maxObj);
        }
        if (category?.[RECUR_LIMIT]) {
            validateRecurLimit = handleValidation(RECUR_LIMIT);
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
            tots.push(recurLimitObj);
        }
        if (category?.[DEBT_SYNDICATION]) {
            validateDebtSyndication = handleValidation(DEBT_SYNDICATION);
            if (!validateDebtSyndication.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
                return;
            }
            tots.push(debtSyndicationObj);
            setDetectChange(state => ({
                ...state,
                [DEBT_SYNDICATION]: false
            }))
        }
        const payload = {
            baseTotUpdates: {
                "discount_expiry_date": userTotData[RECUR_LIMIT]?.discount_expiry_date,
                "showRoi": userTotData[RECUR_LIMIT]?.showRoi,
            },
            tots: tots?.filter((tot) => {
                return tot._id
            }),
        }
        if (currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
            payload.capitalLimit = getLimitRoadmapPayload()
            payload.stage_id = totData[RECUR_LIMIT]?.stage_id ?? totData[STANDARD]?.stage_id;
            payload.allowUpdateAnyway = adminUpdateOveride;
        } else {
            payload.stage_id = totData[DEBT_SYNDICATION]?.stage_id;
        }
        callApi("admin/tot-history/tot-history-update", "post", payload).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                if (res.data?.messaging?.showModal && !adminUpdateOveride) {
                    handleUserUpdateConfirmation(res.data)
                    return;
                }
                onSuccess();
            } else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
            setIsSaveClicked(false);
        });

    }

    const getLimitRoadmapPayload = () => {
        return ({
            orgId: investeeOrgId,
            baseTotId: totId,
            ...limitRoadmapData,
            tranches: limitRoadmapData.tranches.map((tranch) => ({
                ...tranch,
                tenure: tranch?.tenure?.split(" ")?.[0] || ''
            }))
        })
    }

    const isLimitRoadmapDataValid = () => {
        const limitRoadmap = Number(limitRoadmapData?.limitRoadmap);
        const tranches = limitRoadmapData?.tranches;
        let sum = 0;
        for (let i = 0; i < tranches?.length; i++) {
            let tranch = tranches[i];
            sum += Number(tranch?.amount) || 0;
            if (!tranch.tenure) {
                showNotification("Error", `Please select timeline for Tranche ${i + 2}`);
                return false;
            }
            if (!tranch.amount) {
                showNotification("Error", `Please enter amount for Tranche ${i + 2}`);
                return false;
            }
            if (!tranch.comments) {
                showNotification("Error", `Please enter condition to unlock for Tranche ${i + 2}`);
                return false;
            }
        }

        if (showMaxLimit) {
            const tradingLimit = Number(userTotData[MAX]?.tradingLimit) || 0
            if (limitRoadmap <= tradingLimit) {
                showNotification("Error", "Limit roadmap value must be greater than max trading limit");
                return false;
            }
            if (limitRoadmap !== (tradingLimit + sum)) {
                showNotification("Error", "Limit roadmap value must be equal to sum of tranches and max trading limit");
                return false;
            }
        } else if (showStandardLimit) {
            const tradingLimit = Number(userTotData[STANDARD]?.tradingLimit) || 0
            if (limitRoadmap <= tradingLimit) {
                showNotification("Error", "Limit roadmap value must be greater than standard trading limit");
                return false;
            }
            if (limitRoadmap !== (tradingLimit + sum)) {
                showNotification("Error", "Limit roadmap value must be equal to sum of tranches and standard trading limit");
                return false;
            }
        }
        return true;
    }

    const addMore = (state, setState, disabled) => {
            if(disabled) return;
            const arr = [...state];
            arr.push("");
            setState(arr);
    }

    const onLimitRoadmapChange = () => {
        if (showMaxLimit) setDetectChange((state) => ({
            ...state,
            [MAX]: true,
            [STANDARD]: true
        }))
        else if (showStandardLimit) setDetectChange((state) => ({
            ...state,
            [STANDARD]: true
        }))
    }

    const getTotData = () => {
        const result = {};
        if (userTotData) {
            result[RECUR_LIMIT] = getObjToSend(RECUR_LIMIT);
            result[STANDARD] = getObjToSend(STANDARD);
            result[MAX] = getObjToSend(MAX);
        }
        return result;
    }

    const memoizedTotData = useMemo(() => getTotData(), [userTotData, standardCP, standardCS, maxCP, maxCS, recurLimitCP, recurLimitCS, note1, note2, note3]);

    const renderTextBox = (state, setState, disabled, category, field) => {
        return (
        <RenderTextBox
            state={state}
            setState={setState}
            detectChange={detectChange}
            setDetectChange={setDetectChange}
            category={category}
            disabled={disabled}
            isModerating={isModerating}
            field={field}
            totData={memoizedTotData}
        />)
    };

    const createProps = (cp, setCp, cs, setCs, note, setNote, suffix) => ({
        cp,
        setCp,
        cs,
        setCs,
        [`renderCP${suffix}`]: (disabled, category) => renderTextBox(cp, setCp, disabled, category, 'cp'),
        [`renderCS${suffix}`]: (disabled, category) => renderTextBox(cs, setCs, disabled, category, 'cs'),
        [`renderNote${suffix}`]: (disabled, category) => renderTextBox(note, setNote, disabled, category, 'note'),
        [`addMoreCP${suffix}`]: (disabled) => {
            addMore(cp, setCp, disabled);
        },
        [`addMoreCS${suffix}`]: (disabled) => {
            addMore(cs, setCs, disabled);
        },
    });
    
    const standardProps = createProps(standardCP, setStandardCP, standardCS, setStandardCS, note1, setNote1, '1');
    const maxProps = createProps(maxCP, setMaxCP, maxCS, setMaxCS, note2, setNote2, '2');
    const recurLimitProps = createProps(recurLimitCP, setRecurLimitCP, recurLimitCS, setRecurLimitCS, note3, setNote3, '3');

    return (
        <div className="modal fade " id="Moderation-Comment" role="dialog" style={{overflowY: 'auto'}}>
            <div className="modal-dialog">
                <div className="modal-content shadowBorder ">
                    <div className="modal-header pb-0 d-flex">
                        {showTotUpdatePopUp && (
                            <ConfirmTotUpdate
                                title={
                                    <>
                                        Changing terms at this stage will{" "}
                                        <span className="Bold">{modalMessaging.changingTermsMessage}</span> about this
                                        change.
                                    </>
                                }
                                show={modalMessaging.showModal}
                                onHide={handleUserUpdateCancel}
                                header={modalMessaging.header}
                                onDelete={handleConfirmUpdate}
                                showMessage2={modalMessaging.showSharedMfaSlMessge}
                            />
                        )}
                        <StepperTabs
                            Steps={STEPS}
                            setCurrentStep={setCurrentStep}
                            currentStep={currentStep}
                            rrfTermToggle={rrfTermToggle}
                            setRrfTermToggle={setRrfTermToggle}
                            debtSyndicationToggle={debtSyndicationToggle}
                            setDebtSyndicationToggle={setDebtSyndicationToggle}
                            id={investeeOnboardedId}
                            isAddOn={false}
                            toggleState={toggleState}
                            isApplicationExpired ={applicationStatus ==="Expired"}
                            toggleEnabled={(!archived)}
                        />
                        <div className='mt-4'>
                            <button type="button" className="close" data-dismiss="modal">
                                <img src="../../../../../assets/modal-cross.svg" alt=""></img>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body pb-4 pt-0">
                        <div className='row m-0'>
                            <div style={{minWidth: "25%", maxWidth: "35%"}}>
                                <div className='d-flex flex-column justify-content-around h-100 mt-3'
                                     style={{overflowY: 'auto'}}>
                                    {/* <Stepper
                                        steps={[
                                            {
                                                label: STEPS[0],
                                                check: (moderateRow.status === APPROVED|| moderateRow.status === SPARK_APPROVED),
                                            },
                                            {
                                                label: STEPS[1],
                                                // check: !isEmpty(totData),
                                                // block: !(moderateRow.status === APPROVED || moderateRow.status === SPARK_APPROVED),
                                            }
                                        ]}
                                        currentStep={currentStep}
                                        setCurrentStep={setCurrentStep}
                                        currentStepRef={currentStepRef}
                                    /> */}
                                    {/* <DataVaultUnderwriting investeeOrganization={investee_details} /> */}
                                    <ModeratorDetails
                                        identifierType="tot_onboarding"
                                        isApplicationExpired = {applicationStatus === EXPIRED}
                                        investeeOnboardedId={investeeOnboardedId}
                                        investeeOrgId={investeeOrgId}
                                    />
                                    <DealEvaluation
                                        identifierId={investeeOnboardedId}
                                        orgId={investeeOrgId}
                                        status={status}
                                        currentStep={currentStep}
                                        note1={note1}
                                        currentModerateTab={currentModerateTab}
                                        monthlyData={monthlyData}
                                        setRrfTermToggle={setRrfTermToggle}
                                        disableModerate={disableModerate}
                                        setDealReportState={setDealReportState}
                                        dealReportState={dealReportState}
                                    />
                                </div>
                            </div>
                            <div style={{width:'65%'}}>
                                {currentStep === 1 && (
                                    <ReviewFinancialData
                                        renderFields={(
                                            <div>
                                                <div className='light-round-border p-4'>
                                                    {Object.keys(statusInfo).map(item => renderSingleCategory(item))}
                                                    <DetailsInput
                                                        disabled={!isModerating}
                                                        comment={onboardingComment}
                                                        handleChange={(value) => {
                                                            setOnboardingComment(value)
                                                        }}
                                                    />
                                                </div>
                                                <AdditionalInfo isModerating={isModerating}
                                                                setIsAddnInfo={setIsAddnInfo} addnInfo={addnInfo}
                                                                setAddnInfo={setAddnInfo} renderStatus={renderStatus}/>
                                            </div>
                                        )}
                                    />
                                )}
                                {(currentStep === 2 || currentStep === 3) && (
                                    <>
                                        <PrepareTerms
                                            standardProps={standardProps}
                                            maxProps={maxProps}
                                            recurLimitProps={recurLimitProps}
                                            isModerating={isModerating}
                                            renderNote4={(disabled, category) => renderTextBox(note4, setNote4, disabled, category)}
                                            totData={userTotData}
                                            setTotData={setUserTotData}
                                            initialTotData={initialUserTotData}
                                            setInitialTotData={setInitialUserTotData}
                                            showMaxLimit={showMaxLimit}
                                            setShowMaxLimit={setShowMaxLimit}
                                            showStandardLimit={showStandardLimit}
                                            setShowStandardLimit={setShowStandardLimit}
                                            detectChange={detectChange}
                                            setDetectChange={setDetectChange}
                                            userNameForCoborrower={userNameForCoborrower}
                                            setUserNameForCoborrower={setUserNameForCoborrower}
                                            moratDropdownList={moratDropdownList}
                                            originalTotData={totData}
                                            totalAvailableLimit={totalAvailableLimit}
                                            updateToDefaultUserTotData={updateToDefaultUserTotData}
                                            isTotAccepted={isTotAccepted}
                                            setCurrentModerateTab={setCurrentModerateTab}
                                            currentModerateTab={currentModerateTab}
                                            investeeOnboardedId={investeeOnboardedId}
                                            isAddOn={false}
                                        />
                                        {((showStandardLimit || showMaxLimit) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) ? (
                                            <LimitRoadmap
                                                limitRoadmapData={limitRoadmapData}
                                                setLimitRoadmapData={setLimitRoadmapData}
                                                onLimitRoadmapChange={onLimitRoadmapChange}
                                                isModerating={isModerating}
                                                isTotAccepted={isTotAccepted}
                                            />
                                        ) : null}
                                    </>

                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <MultipleTotFooter
                            renderButtons={() => (
                                <>
                                    {currentStep === 1 && !archived && (
                                        <span>{renderStatus()}</span>
                                    )}
                                    {!archived && renderButtons()}
                                </>
                            )}
                            lastEmail={currentStep === 1 ? lastEmail : totData?.emailSentDate}
                            showToggle={currentStep === 2 && monthlyData[0]._id === moderateRow._id}
                            isExpired={isExpired}
                            setIsExpired={setIsExpired}
                            applicationType={APPLICATION_TYPE.ONBOARDING}
                            applicationId={moderateRow?._id}
                            fetchMonthlyData={fetchMonthlyData}
                            isDrawdownTaken={moderateRow?.drawDownCount > 0}
                        />
                    </div>

                </div>
            </div>

        </div>
    )
}