import FilterHeaderComponent from "../../../../../components/FilterHeaderComponent/FilterHeaderComponent";
import { INVOICE_SORT_BY_TYPE } from "../../../../../constants/commonConstants";

function InvoicesTableHeader(props) {
  return (
    <thead className='team-head invoices-table'>
      <tr>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Name
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Type
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Month
        </th>
        <th scope='col' style={{paddingRight: '30px', fontWeight: "600"}}>
        Fee/Amount
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          GST (18%)
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Total 
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Invoice Date
          <button
            onClick={() => props.requestSort(INVOICE_SORT_BY_TYPE.INVOICE_DATE)}
            style={{ border: "none", marginLeft: "7px" }}
          >
              &#8645;
          </button>
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          # Invoice
          <button
            onClick={() => props.requestSort(INVOICE_SORT_BY_TYPE.INVOICE_NUMBER)}
            style={{ border: "none", marginLeft: "7px" }}
          >
              &#8645;
          </button>
        </th>
        <FilterHeaderComponent
                style={{fontWeight: "600"}}
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Status'}
                labelText={'Filter Invoice Status'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjInvoiceStatus.keyName}
                value = {props.searchObjInvoiceStatus.value}
            />
    
        <th></th>
      </tr>
    </thead>
  );
}

export default InvoicesTableHeader;
