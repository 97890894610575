import { useState, useEffect } from "react";

type DebounceCallback = (...args: any[]) => void;

function useDebounce(callback: DebounceCallback, delay: number): DebounceCallback {
    const [timer, setTimer] = useState<number | null>(null);

    const debouncedFunction: DebounceCallback = (...args) => {
        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = window.setTimeout(() => {
            callback(...args);
        }, delay);

        setTimer(newTimer);
    };

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return debouncedFunction;
}

export default useDebounce;
