export const REFUND_STATUS_MAP = {
    'REFUND_PENDING': 'Pending',
    'REFUND_INITIATED': 'Initiated',
    'REFUND_APPROVED': 'Approved',
    'REFUND_REJECTED': 'Rejected'
};

export const REFUND_APPROVED = 'REFUND_APPROVED';
export const REFUND_PENDING = 'REFUND_PENDING';
export const REFUND_INITIATED = 'REFUND_INITIATED';
export const REFUND_REJECTED = 'REFUND_REJECTED';

export const REFUND_REQUEST_BY_ADMIN = 'REFUND_REQUEST_BY_ADMIN';
export const UTR = 'UTR';