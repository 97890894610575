import React from "react";
import styles from "./RecordWireTransfer.module.scss";
import { Company, fileType, PaymentDetailsType, selectedInvestor } from "../../WireTransferDrawerTypes";
import { Select } from "../../../../common/_custom/Select/Select";
import { Input } from "../../../../common/_custom/Input/Input";
import UploadCard from "../../../UploadCard/UploadCard";
import DragDropUpload from "../../../DragDropUpload";
import { CATEGORIES } from "../../../UploadCard/uploadDocUtils";
import PaymentDetails from "../PaymentDetails/PaymentDetails";

interface RecordWireTransferProps {
  file: fileType[];
  isLoading: boolean;
  investeeList: Company[];
  investorList: { investorMap: { [key: string]: string } };
  showDropDown: boolean;
  paymentDetails: PaymentDetailsType;
  inputCompanyName: string;
  selectedInvestor: selectedInvestor;
  selectedCompanyOrg: string;
  setShowDropDown: (value: boolean) => void;
  handleDateChange: (value: string) => void;
  setPaymentDetails: (value: PaymentDetailsType) => void;
  removeUploadedFile: () => void;
  setInputCompanyName: (value: string) => void;
  handleInvestorChange: (value: string) => void;
  handleCompanySelection: (company: Company) => void;
  startParsingTheDocs: (details: { docId: string | number; fileName?: string }) => void;
  uploadScreenShotMixPanelEvent: () => void;
}

function RecordWireTransfer({
  file,
  isLoading,
  showDropDown,
  investeeList,
  investorList,
  paymentDetails,
  selectedInvestor,
  inputCompanyName,
  selectedCompanyOrg,
  setShowDropDown,
  handleDateChange,
  setPaymentDetails,
  removeUploadedFile,
  setInputCompanyName,
  startParsingTheDocs,
  handleInvestorChange,
  handleCompanySelection,
  uploadScreenShotMixPanelEvent,
}: RecordWireTransferProps) {

  return (
    <div className={styles.RecordWireTransferContainer}>
      <div className={styles.inputContainer}>
        <div className={styles.companyInputContainer}>
          <Input
            label="Company Name"
            id="companyName"
            showImg={true}
            handleChange={setInputCompanyName}
            value={inputCompanyName ?? ""}
            onFocus={() => setShowDropDown(true)}
            onBlur={() => setTimeout(() => setShowDropDown(false), 300)}
          />
          {(inputCompanyName.length >= 3 && showDropDown) && (
            <div className={styles.companyNameDiv}>
              <Select
                isLoading={isLoading}
                isLoadingText="Loading Companies..."
                notFoundText="No Companies Found"
                items={investeeList}
                handleOnClick={(item) => handleCompanySelection(item as Company)}
              />
            </div>
          )}
        </div>
        <div className={styles.investorSelectContainer}>
          <label htmlFor="investorName">Investor Name</label>
          <select
            id="investorName"
            className={styles.investorSelect}
            value={selectedInvestor.name ?? ""}
            onChange={(e) => handleInvestorChange(e.target.value)}
          >
            <option value={""} disabled={true}>Select Investor</option>
            {Object.keys(investorList.investorMap ?? {}).map((investorName: string) => (
              <option value={investorName} key={investorName}>
                {investorName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.uploadScreenShot}>
        <label htmlFor="uploadScreenShot">Submit a screenshot of the payment confirmation</label>
        <UploadCard
          hideDragDrop={file.length !== 0}
          renderComponent={<DragDropUpload />}
          category={CATEGORIES.WIRE_TRANSFER_REPAYMENT_PROOF}
          files={file}
          hideDeleteIcon={false}
          allowMultiple={false}
          truncateSmallerText={true}
          isMnemosyneFlow={true}
          onSuccess={(docId: string | number, fileName?: string) => {
            uploadScreenShotMixPanelEvent();
            startParsingTheDocs({ docId: docId, fileName: fileName })
          }}
          onDelete={() => removeUploadedFile()}
          disableUpload={true}
          investeeOrganizationId={selectedCompanyOrg}
        />
      </div>
      {file.length >= 1 && selectedCompanyOrg && selectedInvestor.id && (
        <PaymentDetails
          paymentDetails={paymentDetails}
          setPaymentDetails={setPaymentDetails}
          handleDateChange={handleDateChange}
        />
      )}
    </div>
  );
}

export default RecordWireTransfer;