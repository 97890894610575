import React, { Component } from "react";
import AdminHead from "../../components/AdminHead.js";
import styles from "./invoicesPage.module.scss";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import InvoicesTableHeader from "./components/InvoicesTableHeader";
import InvoicesTableRow from "./components/InvoicesTableRow";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCheckIfUserHasRelevantPermissions } from "../../../App/AppReducer.js";
import { debounce } from "lodash";
import { INVOICE_SORT_BY_TYPE, INVOICE_SORT_ORDER } from "../../../../constants/commonConstants.js";

class InvoicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input_value: "",
      is_fetching_invoices: false,
      invoices: [],
      type: [],
      filterObj: {
        sortFilter: {
          sortBy: INVOICE_SORT_BY_TYPE.INVOICE_NUMBER, // default sort by invoice number
          sortOrder: INVOICE_SORT_ORDER.DESC, // default sort order is descending
        },
        yyyyMM: moment().format("YYYY-MM"),
      },
      pageNum: 1,
      lenderText: "",
      typeText: "All Types",
      pageSize: 200,
      totalCount: 0,
      qtext: "",
      searchObjInvoiceStatus: {
        keyName: "invoice_status",
        value: "",
      },
      date: moment().format("YYYY-MM"),
    };
    this.debounceSearch = debounce(() => this.handleApplyFilters(), 800);
  }

  keyStateObjNameDict = {
    invoice_status: "searchObjInvoiceStatus"
  };

  componentDidMount() {
    this.fetchInvoices();
  }


  requestSort = (key) => {
    this.setState((prevState) => {
      const isSameKey = prevState.filterObj?.sortFilter?.sortBy === key;
      const sortOrder = isSameKey && prevState.filterObj?.sortFilter?.sortOrder === INVOICE_SORT_ORDER.ASC ? INVOICE_SORT_ORDER.DESC : INVOICE_SORT_ORDER.ASC;
      return {
        ...prevState,
        filterObj: {
          ...prevState.filterObj,
          sortFilter: {
            sortBy: key,
            sortOrder: sortOrder,
          },
        },
      };
    }, () => {
      this.handleApplyFilters();
    });
  };

  orgNameFilter = (e) => {
    this.setState({
      ...this.state,
      filterObj: {
        ...this.state.filterObj,
        orgName: e.target.value
      },
      lenderText : e.target.value
    }
    ,() => {
      this.debounceSearch();
    });
  }


  allTypeFilter = (e) => {
    if(e.target.value === "All Types") { 
      this.setState({
        ...this.state,
        filterObj : {
          ...this.state.filterObj,
          typeText: null
        },
        typeText: null
      },() => {
        this.handleApplyFilters();
      });
    } else {
      this.setState({
        ...this.state,
        filterObj:{
          ...this.state.filterObj,
          typeText: e.target.value
        },
        typeText: e.target.value
      },() => {
        this.handleApplyFilters();
      });
    }
  }


  fetchInvoices = () => {
    this.setState({ is_fetching_invoices: true, ...this.state });
    let filters = this.state.filterObj;

    callApi(`admin/invoices/list`, "post", filters).then((res) => {
      if (res.status === "Success") {
        let invoices = res.data.invoices;

        const types = res?.data?.types ?? [];
        types.unshift("All Types");

        
        this.setState({
          ...this.state,  
          invoices,
          totalCount: res.data.count,
          type: types,
          typeText: this.state.typeText ?? "All Types",
        });

      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ ...this.state, is_fetching_invoices: false });
    });
  };

  handleApplyFilters = () => {
    this.fetchInvoices();
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    });
  };

  updateInvoiceStats = (invoice_id) => {
    const body = { invoice: {} };
    body.invoice.status = "Paid";
    body.invoice.paid_on = new Date();
    callApi(
      `admin/invoice/${invoice_id}/data-update`,
      "post",
      body
    ).then((res) => {
      if (res.status === "Success") {
        showNotification("Success", "Invoice stats updated!");
        const invoices = [...this.state.invoices];
        const updatedInvoices = invoices.map((invoice) => {
          if (invoice._id === invoice_id) {
            invoice.status = "Paid"
            invoice.paid_on = moment(body.invoice.paid_on).format("DD MMM YYYY")
          }
          return invoice
        })
        this.setState({
          invoices: updatedInvoices
        })
      } else {
        showNotification("error", "Some error occured");
      }
    });
  };

  handleDateChange = (e) => {
    const { value } = e.target
    const filterObj = this.state.filterObj;
    this.setState({ 
      ...this.state,
      filterObj: {
        ...filterObj,
        yyyyMM: value
      },
      date: value
    }, () => {
      this.handleApplyFilters();
    });
  }

  handleRecurStatementApiCall = () => {
    if (this.state.date) {
      const d = new Date(this.state.date)
      callApi("admin/download_recur_account_statement/match_invoice_number", "post", {
        "date": `${d.getMonth() + 1}-${d.getFullYear()}`,
        "escrow_pay_user_id": 133,
        "table_name": "recur_escrow_account_statement"
      }).then(res => {
        if (res.status === "Success") {
          showNotification("success", "Statement sent successfully")
        }
      })
    } else showNotification("error", "Please Select a date")
  }

  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section id='admin-company'>
          <div className='d-flex justify-content-between has-search p-4'>
            <div className="fs21 fw600" style={{ marginLeft: "-20px", marginBottom: "-20px" }}>Invoices</div>
          </div>
          <div className="page-head-bottom-border"></div>
          <div className="d-flex m-2 mr-3">
            <div className="ml-auto" style={{display:"flex",alignItems:"center"}}>
              <div className={styles.investorOrgNameInput}>
                <input
                  placeholder="Search by Organization Name..."
                  onChange={(e) => {this.orgNameFilter(e)}}
                  type="text"
                  value={this.state.lenderText}
                  />
              </div>

                <select
                  className={styles.investorOrgName}
                  defaultChecked={this.state.typeText}
                  onChange={(e) => {this.allTypeFilter(e)}}
                >
                  <option value={this.state.typeText}>{this.state.typeText}</option>
                  {this.state.type?.map((item, index) => (
                      <option key={index} value={item}>
                          {item}
                      </option>
                  ))}
                </select>
               
                <input
                  type="month"
                  value={this.state.filterObj.yyyyMM}
                  name="date"
                  onChange={(e) => this.handleDateChange(e)}
                  className="mr-2 fs12"
                />
              {<span className='btn btn3 fs10 align-items-center' onClick={this.handleRecurStatementApiCall}>
                Recur Statement
              </span>}
            </div>
          </div>
          <div className='table-head'>
            <table className='table team'>
              <InvoicesTableHeader
                searchObjInvoiceStatus={this.state.searchObjInvoiceStatus}
                handleSearchFilter={this.handleSearchFilter}
                requestSort={this.requestSort}
              />
              <tbody style={{ display: 'none' }}></tbody>
            </table>
          </div>
          <div className='tableFixHead table-body'>
            <table
              className='table team'
            >
              <thead></thead>
              <tbody className='team-body'>
                {this.state.invoices &&
                  this.state.invoices.map((item, index) => (
                    <InvoicesTableRow
                      isUserHasUWViewAccess={this.props.isUserHasUWViewAccess}
                      key={item._id}
                      item={item}
                      loader={this.state.is_fetching_invoices}
                      hasMore={this.hasMore}
                      loadMore={this.loadMore}
                      dataLength={this.state.invoices.length}
                      index={index}
                      updateInvoiceStats={this.updateInvoiceStats}
                      fetchInvoices={this.fetchInvoices}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isUserHasUWViewAccess: getCheckIfUserHasRelevantPermissions(state,["underwriting:edit"])
  };
}

export default withRouter(connect(mapStateToProps)(InvoicesPage)) ;
