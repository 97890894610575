import { useHistory, useLocation } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';

export const useQueryParams = () => {
    const history = useHistory();
    const location = useLocation();
    const [params, setParams] = useState(new URLSearchParams(location.search));

    const updateQueryParams = useCallback((updates: Record<string, any>) => {
        const searchParams = new URLSearchParams(location.search);

        Object.entries(updates).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
                searchParams.set(key, value.toString());
            } else {
                searchParams.delete(key);
            }
        });

        history.replace({
            pathname: location.pathname,
            search: searchParams.toString(),
        });

        setParams(new URLSearchParams(searchParams.toString()));
    }, [history, location.pathname, location.search]);

    useEffect(() => {
        const newParams = new URLSearchParams(location.search);
        if (newParams.toString() !== params.toString()) {
            setParams(newParams);
        }
    }, [location.search, params]);

    return { params, updateQueryParams };
};

export default useQueryParams;