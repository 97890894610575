import dayjs from "dayjs";
import UTC from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import camelCase from "lodash/camelCase";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Drawer } from "@material-ui/core";

import { getObjAndFileName } from "../../../overviewUtility";
import callApi from "../../../../../../../../../../../util/apiCaller";
import {
  downloadFileFromArrayBuffer,
  downloadFileUsingFetch,
  generateRequestId,
  isEnvProd,
  mixPanelTrack,
  priceFormat,
  showNotification,
  uploadDocumentInS3,
} from "../../../../../../../../../../../util/utility";
import SaveSVG from "../../../../../../../../../../../components/SVGs/SaveSVG";
import KycDocs from "./KycDocs";
import { getInvesteesOrganization } from "../../../../../../../../../AdminReducers";
import "./ViewDocsModal.scss";
import {
  changeScreenOnCompaniesOverview,
  updateSelectedTotIdToRedirect,
} from "../../../../../../../../../../App/AppActions";
import {
  ACTION_STATUS,
  DEAL_AlERT_BUTTON_TXT,
  DEAL_DRAWER_ALERT_TYPE,
  INDICATIVE_TERMS,
  LAST_ACTIVITY_STATUS,
  OVERVIEW_SCREENMAP,
  TOT_FEE_URI,
} from "../../../../../../../../../../../common/ENUM";
import {
  getUserName,
  getViewOnCompaniesOverview,
} from "../../../../../../../../../../App/AppReducer";
import {
  API_STATUS,
  TOAST_TYPES,
  LAKH,
  WAIVED,
  NOT_WAIVED,
  USER_ID,
  queryTabs,
  SHOW_NOTIFICATION,
  API_STATUS_CODE,
  SHOW_NOTIFICATION_STATUS,
} from "../../../../../../../../../../../constants/enums";
import { RECUR_INVESTOR_DEAL_NAME } from "../../../../DocumentVault/consts/consts";
import ViewIndicativeTerms from "./ViewIndicativeTerms";
import UWQueries from "./ViewDocsDrawer/UWQueries/UWQueries";
import AlertModal from "./AlertModal";
import { fetchMfaSlUploadsInvestorsHelper, sendUpdateDealOffer, updateLanID, updateVanID } from "../../../../../../../../../../../common/ApiHelpers";
import CancelDocumentModal from "./CancelDocumentModal/CancelDocumentModal";
import { MFA_SL_ALERT_MSG } from "../../../../../../../../../../../constants/commonConstants";


dayjs.extend(isToday);
dayjs.extend(UTC);

const BlackText = styled.span`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
`;
const BlueText = styled.span`
  color: #4c8dff;
  font-size: 10px;
  font-weight: 500;
  word-break: break-all;
  margin-right: 10px;
`;
const GreyText = styled.span`
  color: #e8e8e8;
  font-size: 10px;
  font-weight: 500;
`;
const UploadFile = styled.button`
  color: #4c8dff;
  font-size: 8px;
  width: max-content;
  border: 0.5px solid #4c8dff;
  border-radius: 2px;
  background-color: white;
  margin-right: 15px;
  height: 17px;
`;
const Uploaded = styled.button`
  color: #00aa00;
  height: 17px;
  width: max-content;
  font-size: 8px;
  border: 0.5px solid #e8ffe8;
  border-radius: 2px;
  background-color: #e8ffe8;
  margin-right: 15px;
  font-weight: 300;
  display: flex;
  align-items: center;
  cursor: default !important;
`;
const RefIDInput = styled.input`
  height: 17px !important;
  background-color: transparent !important;
  border: none !important;
  &:valid {
    border: 0.5px solid #4c8dff !important;
    background-color: #ffffff !important;
  }
`;
const SkipSigning = styled.button`
  background-color: transparent;
  border: none;
  color: #d95656;
  font-size: 10px;
`;
const blueColorBorder = {
  color: "#2F8FFF",
  borderBottom: "3.5px solid #2F8FFF",
};
const greyColorBox = {
  fontSize: "8px",
  width: "83px",
  alignItems: "center",
  backgroundColor: "#E8E8E8",
  border: "1px solid #D6D6D6",
  padding: "2.5px 20px 2.0px 2.5px",
  borderRadius: "4px",
};

const MFA_SL_SIGNING_WORKFLOWS = {
  AADHAAR: "AADHAAR",
  DSC: "DSC",
};

const DEAL_DOCUMENT_TYPE = {
  MFA_LETTER: "MFA Letter",
  SANCTION_LETTER: "Sanction Letter",
};

export default function ViewDocsModal({
  showViewDocsDrawer,
  closeDealDrawer,
  investor,
  fetchDeals,
  dealId,
  totId,
  dealsTotId,
  investeeOrgId,
  resetQueryParams,
  dealName,
  totMaxTenure,
  getAllDealsData
}) {
  const KYC_AUDIT_TRAIL = "KYCAuditTrail";
  const MFA = "mfa";
  const SANCTION = "sanction";
  const APPLICATION_FORM = "applicationForm";
  const ACTIVATION = "activation";
  const ADDITION_DOCUMENT = "additionalDocs";
  const DRAWDOWN = "drawdown_letters";
  const DRAWDOWN_CALC_SHEET = "DRAWDOWN_CALC_SHEET";
  const INVESTMENT_REP = "investment";
  const SENT_FOR_SIGNING = "sent_for_signing";
  const FAILED = "failed";
  const SIGNING = "signing";
  const SIGNED = "signed";
  const INVESTOR_SIGNED = "investor_signed";
  const OPEN = "open";
  // const DRAWDOWN_OPEN = "Open";
  const DRAWDOWN_NOT_APPROVED = "Not Approved";
  const INVESTOR_DEALS_DOCS_READY = "Docs Ready";
  const INVESTOR_OFFER_APPROVED = "Approved";
  const INVESTOR_OFFER_NOT_SENT = "Not Sent";
  const INVESTOR_OFFER_SENT = "Sent";
  const dispatch = useDispatch();
  const investorIsUGRO = useMemo(
    () => investor?.investor_organization_id === process.env.REACT_APP_UGRO_ID || investor?.investor_organization_id === process.env.REACT_APP_UGRO_RECUR_ID,
    [investor]
  );
  const SAMFIN_INVESTOR_ORG_ID = process.env.REACT_APP_SAMFIN_ORG_ID;
  const {DOCUMENTS,DEAL_TERMS,KYC_DOCS,UW_QUERIES,KYC_QUERIES} = queryTabs;
  const {
    investor_name,
    investor_organization_id,
    sanction_letter,
    investment_report,
    mfa_letter,
    application_form,
    feeder_sheet,
    feeder_application_sheet,
    feeder_coapplication_sheet,
    mfa_sign_date,
    end_date,
    expiration_date,
    activation_sheet,
    deal_repayment_date,
    approved,
    drawn,
    offer_custom_tenure,
    offer_id,
    drawdown_letters,
    coborrowerSanctionDetails,
    coborrowerMfaDetails,
    primaryUserSanctionStatus,
    primaryUserMfaStatus,
  } = investor;
  const {
    actionStatus,
    lastActivityStatus,
    investeeOrganizationId,offerId
  } = showViewDocsDrawer;

  const OverviewViewIs = useSelector(getViewOnCompaniesOverview);
  const investeeOrganization = useSelector(getInvesteesOrganization);
  const [viewIs, setViewIs] = useState(DOCUMENTS);
  const [isDownloadingFile, setIsDownloadingFile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLanUpdating, setIsLanUpdating] = useState(false);
  const [hasClickedAddAdditionalDocs, setHasClickedAddAdditionalDocs] =
    useState(false);
  const [updatingDTField, setUpdatingDTField] = useState("");
  const [isSanctionUploaded, setIsSanctionUploaded] = useState(false);
  const [isMfaUploaded, setIsMfaUploaded] = useState(false);
  const [hasSentForSigning, setHasSentForSigning] = useState(false);
  const [sanctionRefId, setSanctionRefId] = useState("");
  const [lanId, setLanId] = useState("");
  const[lineItems, setLineItems]=useState([])
  const[originalLineItems, setOriginalLineItems]=useState([])
  const [sanctionId, setSanctionId] = useState("");
  const [uGroLAF_ID, setUGroLAF_ID] = useState("");
  // const [investorInterested, setInvestorInterested] = useState(true);
  const [additionalDocDescription, setAdditionalDocDescription] = useState("");
  const [endDate, setEndDate] = useState(
    end_date ? moment(end_date).format("YYYY-MM-DD") : end_date
  );
  const [endDateBkp, setEndDateBkp] = useState(
    end_date ? moment(end_date).format("YYYY-MM-DD") : end_date
  );
  const [repaymentDay, setRepaymentDay] = useState(deal_repayment_date);
  const [skipSigning, setSkipSigning] = useState({
    view: false,
    isSanctionUploaded: false,
    isMfaUploaded: false,
    signingDate: null,
  });
  const [expiryDate, setExpiryDate] = useState();
  const [sanctionDate, setSanctionDate] = useState("");
  const [mfaDate, setMfaDate] = useState("");
  const [offerTenure, setOfferTenure] = useState(
    offer_custom_tenure ?? totMaxTenure
  );
  const [showSaveTenureBtn, setShowSaveTenureBtn] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [canceledDocType, setCanceledDocType] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [showCancelDocumentModal, setShowCancelDocumentModal] = useState(false);
  const [showAlertModal,setShowAlertModal] = useState(false);
  const [actionBtnNameOnAlrt,setActionBtnNameOnAlrt] = useState("Send");
  const [msgOnAlert,setMsgOnAlert] = useState("");
  const [alertType,setAlertType] = useState("");
  const [mfaInvestorList,setMfaInvestorList]= useState([]);
  const [slInvestorList,setSlInvestorList]= useState([]);
  const [lenderPf, setLenderPf] = useState(investor?.lenderProcessingFees);
  const [showSaveLenderPfBtn, setShowSaveLenderPfBtn] = useState(false);
  const initialLenderPfRef = useRef(investor?.lenderProcessingFees);

  const userName = useSelector((state) => getUserName(state));
  const sanctionFileRef = useRef(null);
  const sanctionSignedFileRef = useRef(null);
  const mfaFileRef = useRef(null);
  const mfaSignedFileRef = useRef(null);

  const mapping = {
    sanction: sanction_letter,
    mfa: mfa_letter,
    activation: feeder_sheet,
    investment: investment_report,
    applicationForm: application_form,
  };

  const getMfaSlInvestorWiseData = async (investeeOrgId) => {
    const payload = {
      investee_organization_id: investeeOrgId,
      dealId: dealId
    };
    const res = await fetchMfaSlUploadsInvestorsHelper(payload);
    if (res?.data) {
      if (res.data?.mfaList) {
        setMfaInvestorList(res.data?.mfaList);
      } else {
        setMfaInvestorList([]);
      }
      if (res.data?.slList) {
        setSlInvestorList(res.data?.slList);
      } else {
        setSlInvestorList([]);
      }
    } else {
      setMfaInvestorList([]);
      setSlInvestorList([]);
    }
  };

  const isSanctionPartiallySigned = useMemo(() => {
    if (primaryUserSanctionStatus === SIGNED) return true;
    for (const coborrowerSanction of coborrowerSanctionDetails ?? []) {
      if (coborrowerSanction?.signStatus === SIGNED) return true;
    }
    return false;
  }, [primaryUserSanctionStatus, coborrowerSanctionDetails]);

  const isMfaPartiallySigned = useMemo(() => {
    if (primaryUserMfaStatus === SIGNED) return true;
    for (const coborrowerMfa of coborrowerMfaDetails ?? []) {
      if (coborrowerMfa?.signStatus === SIGNED) return true;
    }
    return false;
  }, [primaryUserMfaStatus, coborrowerMfaDetails]);

  useEffect(() => {
    getMfaSlInvestorWiseData(investeeOrgId);
    initialiseModal();
    setLineItems(updateLineItems())
    setOriginalLineItems(updateLineItems())
  }, [investor]);

  useEffect(() => {
    if(actionStatus === ACTION_STATUS.resolve_query) {
      if(lastActivityStatus === LAST_ACTIVITY_STATUS.uw_query_recieved) setViewIs(UW_QUERIES);
      else if(lastActivityStatus === LAST_ACTIVITY_STATUS.kyc_query_recieved) setViewIs(KYC_QUERIES);
    } else {
      if (investor_name) {
        investor_name.includes(RECUR_INVESTOR_DEAL_NAME)
          ? setViewIs(DEAL_TERMS)
          : setViewIs(DOCUMENTS);
      }
    }
  }, [investor_name,actionStatus,lastActivityStatus]);

  useEffect(() => {
    if (offer_custom_tenure) setOfferTenure(offer_custom_tenure);
    else setOfferTenure(totMaxTenure);
  }, [totMaxTenure, offer_custom_tenure]);

  useEffect(() => {
    initialLenderPfRef.current = investor?.lenderProcessingFees;
  }, [investor?.lenderProcessingFees]);

  const updateLineItems = () =>{
    const lineItemsArray = []
    if(Array.isArray(drawdown_letters)){
    for (const drawdown of drawdown_letters) {
      const { _id, lan_id,order_id } = drawdown;
      lineItemsArray.push({ _id, lan_id,order_id,isLanUpdating:false });
    }
    }
    return lineItemsArray
  }

  const investorIsSamFin = () => {
    return investor?.investor_organization_id === SAMFIN_INVESTOR_ORG_ID;
  }; 

  const initialiseModal = () => {
    if (!expiryDate && expiration_date) {
      setExpiryDate(
        expiration_date.length === 10
          ? expiration_date
          : moment(expiration_date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD")
      );
    }
    if (
      investor.mfa_status &&
      investor.mfa_status !== OPEN &&
      investor.mfa_status !== FAILED
    ) {
      setHasSentForSigning(true);
    }
    if (
      investor?.mfa_status === OPEN ||
      investor?.mfa_status === FAILED ||
      investor?.sanction_status === OPEN ||
      investor?.sanction_status === FAILED
    ) {
      setHasSentForSigning(false);
    }
    if (isInvestorABFL()) {
      if (investor?.sanction_reference_id) {
        setSanctionRefId(investor?.sanction_reference_id);
        setSanctionId(investor?.sanction_reference_id);
      }
    }
    if (investorIsSamFin()) {
      if (investor?.laf_id_number) {
        setSanctionRefId(investor?.laf_id_number);
        setSanctionId(investor?.laf_id_number);
      }
    }
    setUGroLAF_ID(investor?.laf_id_number);
    // setInvestorInterested(investor?.investor_interested);
    let sanctionSignedUploaded = false;
    let mfaSignedUploaded = false;
    if (investor?.sanction_uploads?.uploaded_signed_doc)
      sanctionSignedUploaded = true;
    if (investor?.sanction_uploads?.uploaded_doc) setIsSanctionUploaded(true);
    if (investor?.mfa_uploads?.uploaded_signed_doc) mfaSignedUploaded = true;
    if (investor?.mfa_uploads?.uploaded_doc) setIsMfaUploaded(true);
    if (sanctionSignedUploaded || mfaSignedUploaded) {
      setSkipSigning({
        ...skipSigning,
        isSanctionUploaded: sanctionSignedUploaded,
        isMfaUploaded: mfaSignedUploaded,
      });
    }
    setRepaymentDay(deal_repayment_date);
  };

  const downloadKYCAuditTrail = (e, category, investeeOrgid, investorOrgid) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDownloadingFile(category);
    const cb = () => setIsDownloadingFile(null);
    downloadFileUsingFetch(
      `/admin/investeeOrganization/${investeeOrgid}/kyc-audit-trail/${investorOrgid}/incred`,
      "get",
      {
        "content-type": "application/json",
      },
      "KYC_Audit_Trail.pdf",
      cb
    );
  };

  const isInvestorABFL = () => {
    return (
      investor?.investor_organization_id === process.env.REACT_APP_ABFL_ORG_ID
    );
  };

  const validOrganizationIds = [
    process.env.REACT_APP_INCRED_ORG_ID,
    process.env.REACT_APP_ABFL_ORG_ID,
    process.env.REACT_APP_UGRO_ID,
    process.env.REACT_APP_SAMFIN_ORG_ID,
    process.env.REACT_APP_GHALLA_ORG_ID,
    process.env.REACT_APP_EQUENTIA_ORG_ID,
    process.env.REACT_APP_INCRED_ORG_RECUR_ID,
    process.env.REACT_APP_ABFL_ORG_RECUR_ID,
    process.env.REACT_APP_UGRO_RECUR_ID
  ];

  const isInvestorValidForLanIdInput = () => validOrganizationIds.includes(investor_organization_id);

  const downloadLetter = (category, _letter, fileType = null) => {
    if (
      category === ACTIVATION &&
      !(
        feeder_sheet ||
        feeder_application_sheet ||
        feeder_coapplication_sheet
      ) &&
      !activation_sheet
    ) {
      showNotification(TOAST_TYPES.ERROR, "Activation sheet not generated yet");
      return;
    }
    setIsDownloadingFile(category);
    let letter;
    if (!isNil(_letter)) {
      letter = _letter;
    } else letter = mapping[category];
    if (category === SANCTION && investor?.sanction_status !== SIGNED) {
      letter = !isEmpty(investor?.sanction_uploads?.uploaded_doc)
        ? investor?.sanction_uploads?.uploaded_doc
        : letter;
    } else if (category === MFA && investor?.mfa_status !== SIGNED) {
      letter = !isEmpty(investor?.mfa_uploads?.uploaded_doc)
        ? investor?.mfa_uploads?.uploaded_doc
        : letter;
    } else if (category === APPLICATION_FORM && investor?.application_form_status !== SIGNED) {
      letter = !isEmpty(investor?.application_form_uploads?.uploaded_doc)
        ? investor?.application_form_uploads?.uploaded_doc
        : letter;
    }
    const { obj, fileName } = getObjAndFileName(letter);
    const getBuffer = (data) => {
      return data.file.Body.data;
    };
    const cb = () => setIsDownloadingFile(null);
    downloadFileFromArrayBuffer(
      "deals/admin/download-from-s3",
      "post",
      obj,
      null,
      fileName,
      { type: fileType && "application/pdf" },
      getBuffer,
      cb
    );
  };

  const onFileChangeCapture = (e, key) => {
    const file = e.target.files[0];
    const splitter = file.name.split(".");
    const input = {
      fileName: file.name,
      file_type: file.type,
      extension: splitter[splitter.length - 1],
      investor_deal_id: investor._id,
    };
    const metaDataInput = {
      investor_deal_id: investor._id,
      ...(investor?.mfa_status !== SIGNED && { mfa_status: OPEN }),
      ...(investor?.sanction_status !== SIGNED && {
        sanction_status: OPEN,
      }),
      ...(sanctionDate && { sanctionDate }),
      ...(mfaDate && { mfaDate }),
    };
    const onUploadSuccess = () => {
      if (key === "sanction_uploaded_signed_link")
        setSkipSigning({
          ...skipSigning,
          isSanctionUploaded: true,
        });
      if (key === "mfa_uploaded_signed_link")
        setSkipSigning({ ...skipSigning, isMfaUploaded: true });
      if (key === "sanction_uploaded_link") setIsSanctionUploaded(true);
      if (key === "mfa_uploaded_link") setIsMfaUploaded(true);
      setHasSentForSigning(false);
      fetchDeals(investeeOrgId,totId,offerId);
    };
    uploadDocumentInS3(
      "admin/deals/deal_documents/s3/sign",
      input,
      file,
      "deals/investor_deals/investor_deal_update",
      metaDataInput,
      key,
      onUploadSuccess
    );
  };

  const onAdditionalDocsUpload = (e, key) => {
    if (!additionalDocDescription?.trim()) {
      showNotification(
        TOAST_TYPES.ERROR,
        "Please enter additional doc description"
      );
      e.target.value = null;
      return;
    }
    const file = e.target.files[0];
    const splitter = file.name.split(".");
    const docName = `${investor._id}_${moment().format("yyyyMMDDHHmmss")}_${file.name
      }`;
    const input = {
      fileName: docName,
      file_type: file.type,
      extension: splitter[splitter.length - 1],
      key: key,
    };
    let metaDataInput = {
      offer_id: investor?._id,
      additional_deal_document: {
        doc_name: docName,
        description: additionalDocDescription?.trim(),
        doc_link: "",
      },
    };
    if (investor?.offer_status === INVESTOR_OFFER_APPROVED)
      metaDataInput.offer_id = investor?.offer_id;
    const onUploadSuccess = () => {
      showNotification(
        TOAST_TYPES.SUCCESS,
        `${file.name} uploaded successfully`
      );
      setAdditionalDocDescription("");
      setHasClickedAddAdditionalDocs(false);
      e.target.value = null;
      fetchDeals(investeeOrgId,totId,offerId);
    };
    uploadDocumentInS3(
      "admin/deals/deal_documents/s3/sign",
      input,
      file,
      "/deals/admin/additional_deal_documents/offer_update",
      metaDataInput,
      "additional_deal_documents.doc_link",
      onUploadSuccess
    );
  };

  const deleteAdditionalDocs = (docId) => {
    let obj = {
      offer_id: investor?._id,
      additional_deal_document: docId,
    };
    if (investor?.offer_status === INVESTOR_OFFER_APPROVED)
      obj.offer_id = investor?.offer_id;
    callApi(
      "deals/admin/delete_additional_deal_doc/offer_update",
      "post",
      obj
    ).then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        showNotification(TOAST_TYPES.SUCCESS, "Additional document deleted");
        fetchDeals(investeeOrgId,totId,offerId);
      }
    });
  };

  const renderIcon = () => {
    if (
      investor?.sanction_status === "signed" &&
      investor?.mfa_status === "signed"
    ) {
      return <img alt="done" src="/assets/done.svg" width="16px" />;
    }
    if (!isUpdating)
      return <img alt="pending" src="/assets/pending.svg" width="16px" />;
    return null;
  };

  const renderDealStatus = () => {
    if (
      investor?.sanction_status === SIGNED &&
      investor?.mfa_status === SIGNED
    ) {
      return <div className="signed">Active</div>;
    }
    if (
      investor?.sanction_status === SENT_FOR_SIGNING ||
      investor?.sanction_status === SIGNING ||
      investor?.mfa_status === SENT_FOR_SIGNING ||
      investor?.mfa_status === SIGNING
    )
      return <div className="signing">Signing</div>;
    if (
      investor?.sanction_status === FAILED ||
      investor?.mfa_status === FAILED
    ) {
      <div className="sent">failed</div>;
    }
    return <div className="notSent">Not Sent</div>;
  };

  const renderStatus = (status) => {
    if (!isUpdating) {
      if (status === INVESTOR_SIGNED)
        return <div className="countersigned">Countersigned</div>;
      if (status === SIGNED) return <div className="signed">Signed</div>;
      if (status === SENT_FOR_SIGNING || status === SIGNING)
        return <div className="signing">Signing</div>;
      if (status === FAILED) {
        <div className="sent">Failed</div>;
      }
      return <div className="notSent">Not Sent</div>;
    }
    return null;
  };
  const shouldShowEditIcon = () => {
    const isInvestorSamFin = investorIsSamFin();
    const isSanctionStatusValid = ![SENT_FOR_SIGNING, SIGNING, SIGNED].includes(investor?.sanction_status);
    const isNotUpdating = !isUpdating;
    return isNotUpdating && (!isInvestorSamFin || (isInvestorSamFin && isSanctionStatusValid));
  };
  const renderSanctionRefId = () => {
    if (isInvestorABFL() || investorIsSamFin()) {
      return (
        <div
          className="dflexJA bgclr10 fs8 py-1 px-2 br2"
          style={{
            width: "124px",
            height: "17px",
          }}
        >
          <RefIDInput
            type="text"
            placeholder={(investorIsSamFin) ? "Enter ARN Number" : isUpdating ? "Enter Reference ID" : "No Reference ID"}
            value={sanctionRefId}
            onChange={(e) => setSanctionRefId(e.target.value)}
            disabled={!isUpdating}
            required
          ></RefIDInput>
          {(shouldShowEditIcon())&& (
            <span
              className="clr11"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setIsUpdating(true)}
            >
              <img
                width="14px"
                height="14px"
                src="/assets/editIcon.svg"
                alt=""
              />
            </span>
          )}
          {isUpdating && (
            <>
              <span
                className="dflex"
                style={{
                  cursor: "pointer",
                }}
                onClick={onSaveClick}
              >
                <img
                  alt="save"
                  src="/assets/save-icon1.svg"
                  width="11px"
                  className="mr-1"
                />
              </span>
              <span
                className="dflex"
                style={{
                  cursor: "pointer",
                }}
                onClick={onCancelClick}
              >
                <img alt="cross" src="/assets/clear-red.svg" />
              </span>
            </>
          )}
        </div>
      );
    }
    return null;
  };

    // Function to toggle the edit state for a specific lanId
    const toggleEditState = (index) => {
      const updatedLineItems = [...lineItems];
      updatedLineItems[index].isLanUpdating = !updatedLineItems[index].isLanUpdating;
      setLineItems(updatedLineItems);
    };

    const toggleCancelState = (index) => {
      const updatedLineItems = [...lineItems]
      updatedLineItems[index].isLanUpdating = !updatedLineItems[index].isLanUpdating;
      updatedLineItems[index].lan_id = originalLineItems[index].lan_id
      setLineItems(updatedLineItems);
    };
  
  
    // Function to handle changes to LAN ID input for a specific lanId
    const handleLanIdChange = (index, value) => {
      const updatedLineItems = [...lineItems];
      updatedLineItems[index].lan_id = value;
      setLineItems(updatedLineItems);
    };

  const saveLanId = async (orderDetails,index) => {
    if(orderDetails.lan_id === originalLineItems[index].lan_id){
      showNotification(SHOW_NOTIFICATION.ERROR,"Please Change the Value in order to save")
      return
    }
    try{
      if (
        investor.drawdown_letters.length <= 1 &&
        [process.env.REACT_APP_UGRO_ID, process.env.REACT_APP_UGRO_RECUR_ID].includes(investor.investor_organization_id)
      ) {
        await updateVanID(orderDetails?.lan_id, investor?._id, investor.investor_organization_id, investeeOrgId )
       }
       const response = await updateLanID(orderDetails)
      if(response === API_STATUS_CODE.SUCCESS){
        fetchDeals(investeeOrgId,totId,offerId);
      }
    }finally{
      toggleEditState(index)
    }

  }
  const renderLanIdforValidInvestor = (drawdown,index) =>{
    return (
      <div
        className="dflexJA bgclr10 fs8 py-1 px-2 br2"
        key={drawdown._id}
        style={{
          width: "180px",
          height: "17px",
        }}
      >
        <RefIDInput
          type="text"
          style={{ width: "160px" }}
          placeholder={lineItems[index]?.isLanUpdating ? "Enter LAN ID" : "Input LAN ID"}
          value={lineItems[index]?.lan_id}
          onChange={(e) => handleLanIdChange(index, e.target.value)}
          disabled={!lineItems[index]?.isLanUpdating}
          required
        ></RefIDInput>
        {!lineItems[index]?.isLanUpdating && (
          <span
            className="clr11"
            style={{
              cursor: "pointer",
            }}
            onClick={() => toggleEditState(index)}
          >
            <img width="14px" height="14px" src="/assets/editIcon.svg" alt="" />
          </span>
        )}
        {lineItems[index]?.isLanUpdating && (
          <>
            <span
              className="dflex"
              style={{
                cursor: "pointer",
              }}
              
            >
              <img
                alt="save"
                src="/assets/save-icon1.svg"
                width="11px"
                className="mr-1"
                onClick={() => saveLanId(lineItems[index],index)}
              />
            </span>
            <span
              className="dflex"
              style={{
                cursor: "pointer",
              }}
              onClick={() => toggleCancelState(index)}
            >
              <img alt="cross" src="/assets/clear-red.svg" />
            </span>
          </>
        )}
      </div>
    );
  }

  const onSaveClick = () => {
    if (investorIsSamFin()) {
      callApi("/deals/admin/arn_number_update/offer_update", "post", {
        offer_id: investor.offer_id,
        laf_id_number: sanctionRefId,
      }).then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          setSanctionId(sanctionRefId);
          handleRegenerate("Sanction Letter")
        } else {
          setSanctionRefId(sanctionId);
          showNotification(TOAST_TYPES.ERROR, "Some error occurred while updating laf id");
        }
        setIsUpdating(false);
      });
    }
    else {
      callApi("deals/investor_deals/investor_deal_update", "post", {
        investor_deal_id: investor._id,
        sanction_reference_id: sanctionRefId,
      }).then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          setSanctionId(sanctionRefId);
        } else {
          setSanctionRefId(sanctionId);
          showNotification(TOAST_TYPES.ERROR, "Some error occurred!");
        }
        setIsUpdating(false);
      });
    }
  };

  const onSaveClickDT = (body) => {
    callApi("deals/investor_deals/investor_deal_update", "post", {
      investor_deal_id: investor._id,
      ...body,
    }).then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        setUpdatingDTField("");
        setEndDateBkp(endDate);
      } else {
        showNotification(TOAST_TYPES.ERROR, "Some error occurred!");
      }
    });
  };

  const onCancelClick = () => {
    setIsUpdating(false);
    setSanctionRefId(sanctionId);
  };
  const onLanidCancelClick = () => {
    setIsLanUpdating(false);
  };

  const onCancelClickDT = () => {
    setUpdatingDTField("");
    setEndDate(endDateBkp);
  };

  const deleteUploadedFile = (investor_deal_id, key, deleted_link) => {
    callApi("deals/investor_deals/investor_deal_update", "post", {
      investor_deal_id,
      [key]: deleted_link,
      ...(investor?.mfa_status !== SIGNED && { mfa_status: OPEN }),
      ...(investor?.sanction_status !== SIGNED && {
        sanction_status: OPEN,
      }),
    }).then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        if (key === "sanction_uploaded_signed_link")
          setSkipSigning({
            ...skipSigning,
            isSanctionUploaded: false,
          });
        if (key === "mfa_uploaded_signed_link")
          setSkipSigning({ ...skipSigning, isMfaUploaded: false });
        if (key === "sanction_uploaded_link") setIsSanctionUploaded(false);
        if (key === "mfa_uploaded_link") setIsMfaUploaded(false);
        setHasSentForSigning(false);
        fetchDeals(investeeOrgId,totId,offerId);
      } else {
        showNotification(TOAST_TYPES.ERROR, "Some error occurred!");
      }
    });
  };

  const sendDealToUgro = () => {
    callApi("/deals/admin/laf_id_update/offer_update", "post", {
      offer_id: investor?._id,
      laf_id_number: uGroLAF_ID,
    }).then((response) => {
      if (response.status === API_STATUS.SUCCESS) {
        let obj = {
          deal_id: dealId,
          tot_id: dealsTotId,
          investee_org_name: investeeOrganization?.name,
          offers: [
            {
              ...investor,
              laf_id_number: uGroLAF_ID,
              offered_amount: investor?.offer_amount,
            },
          ],
        };
        callApi("deals/admin/send_offers_to_investors", "post", obj).then(
          (res) => {
            if (res.status === API_STATUS.SUCCESS) {
              showNotification(
                TOAST_TYPES.SUCCESS,
                "Deal sent to UGRO successfully"
              );
              fetchDeals(investeeOrgId,totId,offerId);
            } else {
              const message = res?.data.message ?? "Something went wrong";
              showNotification(TOAST_TYPES.ERROR, message);
            }
          }
        );
      } else {
        const message = response?.data.message ?? "Something went wrong";
        showNotification(TOAST_TYPES.ERROR, message);
      }
    });
  };
  const sendDealToSamFin = () => {
    callApi("/deals/admin/arn_number_update/offer_update", "post", {
      offer_id: investor?._id,
      laf_id_number: uGroLAF_ID,
    }).then((response) => {
      if (response.status === API_STATUS.SUCCESS) {
        let obj = {
          deal_id: dealId,
          tot_id: dealsTotId,
          investee_org_name: investeeOrganization?.name,
          offers: [
            {
              ...investor,
              laf_id_number: uGroLAF_ID,
              offered_amount: investor?.offer_amount,
            },
          ],
        };
        callApi("deals/admin/send_offers_to_investors", "post", obj).then(
          (res) => {
            if (res.status === API_STATUS.SUCCESS) {
              showNotification(
                TOAST_TYPES.SUCCESS,
                "Deal sent to SamFin successfully"
              );
              fetchDeals(investeeOrgId,totId,offerId);
            } else {
              const message = res?.data.message ?? "Something went wrong";
              showNotification(TOAST_TYPES.ERROR, message);
            }
          }
        );
      } else {
        const message = response?.data.message ?? "Something went wrong";
        showNotification(TOAST_TYPES.ERROR, message);
      }
    });
  };

  const renderUploadButton = (ref, key) => {
    if (
      (key === "sanction_uploaded_signed_link" ||
        key === "sanction_uploaded_link") &&
      investor?.sanction_status === SIGNED
    ) {
      return null;
    }
    if (
      (key === "mfa_uploaded_signed_link" || key === "mfa_uploaded_link") &&
      investor?.mfa_status === SIGNED
    ) {
      return null;
    }
    if (
      (key === "sanction_uploaded_signed_link" &&
        skipSigning.isSanctionUploaded) ||
      (key === "mfa_uploaded_signed_link" && skipSigning.isMfaUploaded) ||
      (key === "sanction_uploaded_link" && isSanctionUploaded) ||
      (key === "mfa_uploaded_link" && isMfaUploaded)
    ) {
      return (
        <Uploaded>
          <span>Uploaded</span>
          <span
            className="dflex-ac"
            style={{ cursor: "pointer", marginLeft: "5px" }}
            onClick={() =>
              deleteUploadedFile(
                investor?._id,
                key,
                isInvestorABFL() && key === "sanction_uploaded_link"
                  ? investor?.sanction_word_doc ?? "delete"
                  : "delete"
              )
            }
          >
            <img alt="cross" src="/assets/clear-green.svg" width="8px" />
          </span>
        </Uploaded>
      );
    }
    if (skipSigning.view) {
      const disabled =
        (key === "sanction_uploaded_signed_link" && !sanctionDate) ||
        (key === "mfa_uploaded_signed_link" && !mfaDate);

      return (
        <>
          {key === "sanction_uploaded_signed_link" ? (
            <input
              style={{ width: "75px", height: "17px" }}
              className="mx-2 fw300"
              type="date"
              id="sanction-date"
              name="sanction-date"
              onChange={(e) => setSanctionDate(e.target.value)}
              value={sanctionDate}
            />
          ) : (
            <input
              style={{ width: "75px", height: "17px" }}
              className="mx-2 fw300"
              type="date"
              id="mfa-date"
              name="mfa-date"
              onChange={(e) => setMfaDate(e.target.value)}
              value={mfaDate}
            />
          )}
          <input
            type="file"
            ref={ref}
            onChangeCapture={(e) => onFileChangeCapture(e, key)}
            style={{ display: "none" }}
            accept=".pdf"
            disabled={disabled}
          />
          <UploadFile
            onClick={() => ref.current.click()}
            title="Please enter creation date first"
          >
            Upload Signed Document
          </UploadFile>
        </>
      );
    }
    const disabled =
      (key === "sanction_uploaded_link" && !sanctionDate) ||
      (key === "mfa_uploaded_link" && !mfaDate);
    return (
      <>
        <input
          type="file"
          ref={ref}
          onChangeCapture={(e) => onFileChangeCapture(e, key)}
          style={{ display: "none" }}
          accept=".pdf"
        />
        <UploadFile
          onClick={() => ref.current.click()}
          disabled={disabled}
          title="Please enter creation date first"
        >
          Replace Doc for signing
        </UploadFile>
      </>
    );
  };

  const saveAndApproveLimit = (investor_deal_id) => {
    const input = {
      investor_deal_id,
      mfa_status: SIGNED,
      sanction_status: SIGNED,
      mfa_sign_date: skipSigning.signingDate,
      sanction_sign_date: skipSigning.signingDate,
    };
    if (investor?.sanction_uploads?.uploaded_signed_doc) {
      input.sanction_link = investor?.sanction_uploads?.uploaded_signed_doc;
    } else if (investor?.sanction_uploads?.auto_generated_doc) {
      input.sanction_link = investor?.sanction_uploads?.auto_generated_doc;
    }
    if (investor?.mfa_uploads?.uploaded_signed_doc) {
      input.mfa_link = investor?.mfa_uploads?.uploaded_signed_doc;
    } else if (investor?.mfa_uploads?.auto_generated_doc) {
      input.mfa_link = investor?.mfa_uploads?.auto_generated_doc;
    }
    callApi("deals/investor_deals/investor_deal_update", "post", input).then(
      (res) => {
        if (res.status === API_STATUS.SUCCESS) {
          fetchDeals(investeeOrgId,totId,offerId);
        } else {
          showNotification(
            TOAST_TYPES.ERROR,
            res?.data?.message ?? "Some error occurred!"
          );
        }
      }
    );
  };

  const isMfaOrSlOrAfPresentInDeal = (category) => {
    let letter = mapping[category];
    let result = false;
    if (category === SANCTION) {
      if (investor?.sanction_status !== SIGNED) {
        letter = investor?.sanction_uploads?.uploaded_doc || letter;
        result = !!letter;
      } else {
        result = true;
      }
    } else if (category === MFA) {
      if (investor?.mfa_status !== SIGNED) {
        letter = investor?.mfa_uploads?.uploaded_doc || letter;
        result = !!letter;
      } else {
        result = true;
      }
    } else if (category === APPLICATION_FORM) {
      if (investor?.application_form_status !== SIGNED) {
        letter = investor?.application_form_uploads?.uploaded_doc || letter;
        result = !!letter;
      } else {
        result = true;
      }
    }
    return result;
  };
  
  const OnCancelAlertHandler = () => {
    setShowAlertModal(false);
    setAlertType("");
    setActionBtnNameOnAlrt("")

  }
  const actionOnAlert = () => {
    if (alertType === DEAL_DRAWER_ALERT_TYPE.sendForSinging) {
      sendForSigning(investor?._id)
    }
    else if (alertType === DEAL_DOCUMENT_TYPE.MFA_LETTER) {
      handleRegenerate(DEAL_DOCUMENT_TYPE.MFA_LETTER)
    }
    else if (alertType === DEAL_DOCUMENT_TYPE.SANCTION_LETTER) {
      handleRegenerate(DEAL_DOCUMENT_TYPE.SANCTION_LETTER)
    }
    setShowAlertModal(false);
    setAlertType("");
  }

  const categoryErrorMessages = {
    [SANCTION]: "Last SL Signed was Manually uploaded, kindly have a look",
    [MFA]: "Last MFA Signed was Manually uploaded, kindly have a look",
    [APPLICATION_FORM]: "Last Application Form was Manually uploaded, kindly have a look",
  };
  
  const handleDownloadMfaOrSlOrAf = (category) => {
    if (isMfaOrSlOrAfPresentInDeal(category)) {
      downloadLetter(category);
      return;
    }
  
    const errorMessage = categoryErrorMessages[category];
    if (errorMessage) {
      showNotification(SHOW_NOTIFICATION_STATUS.ERROR, errorMessage);
    }
  };
  

  const sendForSigningHelper = (investor_deal_id) => {
    const investorOrgId = investor?.investor_organization_id
    if (investorOrgId && ((mfaInvestorList.length > 0 &&  !isMfaUploaded) || (slInvestorList.length > 0 && !isSanctionUploaded))) {
      setAlertType(DEAL_DRAWER_ALERT_TYPE.sendForSinging);
      setActionBtnNameOnAlrt(DEAL_AlERT_BUTTON_TXT.SEND);
      setMsgOnAlert(MFA_SL_ALERT_MSG);
      setShowAlertModal(true);
    }
    else {
      sendForSigning(investor_deal_id);
    }
  }

  const sendForSigning = (investor_deal_id) => {
    const input = {
      investor_deal_id,
      ...(investor?.mfa_status !== SIGNED && {
        mfa_status: SENT_FOR_SIGNING,
      }),
      ...(investor?.sanction_status !== SIGNED && {
        sanction_status: SENT_FOR_SIGNING,
      }),
    };
    if (investor?.sanction_uploads?.uploaded_doc) {
      input.sanction_link = investor?.sanction_uploads?.uploaded_doc;
    } else if (investor?.sanction_uploads?.auto_generated_doc) {
      input.sanction_link = investor?.sanction_uploads?.auto_generated_doc;
    }
    if (investor?.mfa_uploads?.uploaded_doc) {
      input.mfa_link = investor?.mfa_uploads?.uploaded_doc;
    } else if (investor?.mfa_uploads?.auto_generated_doc) {
      input.mfa_link = investor?.mfa_uploads?.auto_generated_doc;
    }
    callApi("deals/investor_deals/investor_deal_update", "post", input).then(
      (res) => {
        if (res.status === API_STATUS.SUCCESS) {
          fetchDeals(investeeOrgId,totId,offerId);
        } else {
          const message = res.data?.message
            ? res.data?.message
            : "Some error occurred!";
          showNotification(TOAST_TYPES.ERROR, message);
        }
      }
    );
  };

  const renderSaveApproveBtn = useMemo(() => {
    if (
      investor?.sanction_status === SIGNED &&
      investor?.mfa_status === SIGNED
    ) {
      return (
        <span className="dflex fs10 fw400 m-0">
          Sanction Status {"\u00a0"}
          {renderDealStatus()}
        </span>
      );
    }
    let buttonDisabled = false;
    if (isInvestorABFL()) {
      buttonDisabled =
        !sanctionId ||
        !skipSigning.isSanctionUploaded ||
        !skipSigning.isMfaUploaded ||
        !skipSigning.signingDate;
    } else {
      buttonDisabled =
        !skipSigning.isSanctionUploaded ||
        !skipSigning.isMfaUploaded ||
        !skipSigning.signingDate;
    }
    return (
      <button
        className="btn3 fs10 fw500 m-0 py-2"
        disabled={buttonDisabled}
        onClick={() => saveAndApproveLimit(investor?._id)}
      >
        Save and Approve Sanction Limit
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investor, sanctionId, skipSigning]);

  const renderCaptureSigningDate = () => {
    return (
      <div className="dflex-ja">
        <label
          style={{ whiteSpace: "nowrap", marginRight: "12px" }}
          className="fs10 fw400 m-2"
          htmlFor="signing-date"
        >
          Sanction Signing Date
        </label>
        <input
          type="date"
          name="signing-date"
          id="signing-date"
          disabled={
            investor?.sanction_status === SIGNED &&
            investor?.mfa_status === SIGNED
          }
          value={skipSigning.signingDate}
          onChange={(e) =>
            setSkipSigning({
              ...skipSigning,
              signingDate: e.target.value,
            })
          }
        />
      </div>
    );
  };

  const investorDealUpdate = (input, cb) => {
    callApi("deals/investor_deals/investor_deal_update", "post", input)
      .then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          fetchDeals(investeeOrgId,totId,offerId);
          if (cb) cb();
        } else {
          const message = res.data.message ?? "Some error occurred!";
          showNotification(TOAST_TYPES.ERROR, message);
          cb(res.data.message ?? "Some error occurred!");
        }
      })
      .catch((err) => {
        showNotification(
          TOAST_TYPES.ERROR,
          err.message ?? "Some error occurred!"
        );
        cb(err.message ?? "Some error occurred!");
      });
  };

  const renderSendButton = () => {
    if (hasSentForSigning) {
      return (
        <span className="dflex fs10 fw400 m-0 mt-4">
          Sanction Status {"\u00a0"}
          {renderDealStatus()}
        </span>
      );
    }
    if (isInvestorABFL()) {
      if (sanctionId && isSanctionUploaded) {
        return (
          <div className="mt-4 dflex">
            <SkipSigning
              className="btn1"
              onClick={() =>
                setSkipSigning({
                  ...skipSigning,
                  view: !skipSigning.view,
                })
              }
            >
              Skip Signing
            </SkipSigning>
            <button
              className="resend-for-signing-btn"
              onClick={() => sendForSigningHelper(investor?._id)}
              disabled={!isMfaOrSlOrAfPresentInDeal(SANCTION) || !isMfaOrSlOrAfPresentInDeal(MFA)}
            >
              Send for Signing
            </button>
          </div>
        );
      }
      return (
        <div className="mt-4 dflex">
          <SkipSigning
            onClick={() =>
              setSkipSigning({
                ...skipSigning,
                view: !skipSigning.view,
              })
            }
          >
            Skip Signing
          </SkipSigning>
          <button
            className="resend-for-signing-btn"
            style={{ color: "white", cursor: "default", border: "none" }}
          >
            Send for Signing
          </button>
        </div>
      );
    }
    if (investorIsSamFin()) {
      if (sanctionId) {
        return (
          <div className="mt-4 dflex">
            <SkipSigning
              className="btn1"
              onClick={() =>
                setSkipSigning({
                  ...skipSigning,
                  view: !skipSigning.view,
                })
              }
            >
              Skip Signing
            </SkipSigning>
            <button
              className="resend-for-signing-btn"
              onClick={() => sendForSigningHelper(investor?._id)}
              disabled={!isMfaOrSlOrAfPresentInDeal(SANCTION) || !isMfaOrSlOrAfPresentInDeal(MFA)}
            >
              Send for Signing
            </button>
          </div>
        );
      }
      else {
        return (
          <div className="mt-4 dflex">
            <SkipSigning
              className="btn1"
              onClick={() =>
                setSkipSigning({
                  ...skipSigning,
                  view: !skipSigning.view,
                })
              }
            >
              Skip Signing
            </SkipSigning>
            <button
              className="resend-for-signing-btn"
              onClick={() => showNotification(TOAST_TYPES.ERROR, `ARN Number missing. Add ARN number before sending MFA/SL for signing`)}
            >
              Send for Signing
            </button>
          </div>
        );

      }

    }
    return (
      <div className="mt-4 dflex">
      <SkipSigning
        className=""
        onClick={() =>
        setSkipSigning({
          ...skipSigning,
          view: true,
        })
        }
      >
        Skip Signing
      </SkipSigning>
      <button
        className="resend-for-signing-btn"
        onClick={() => sendForSigningHelper(investor?._id)}
        disabled={!isMfaOrSlOrAfPresentInDeal(SANCTION) || !isMfaOrSlOrAfPresentInDeal(MFA)}
      >
        Send for Signing
      </button>
      </div>
    );
  };

  const renderSkipSigning = () => {
    return (
      <>
        <div className="modal-body pt-0 px-4 mb-2">
          <div className="CardBorder mb-0">
            <button
              className="btn-link mb-3"
              onClick={() => setSkipSigning({ ...skipSigning, view: false })}
            >
              Go Back
            </button>
            <div className="dflexJA mb-4">
              <div className="dflex-ac">
                {investor.sanction_status === OPEN ? (
                  <span className="mr-3 dflex-ac">
                    <BlackText>Sanction Letter</BlackText>
                  </span>
                ) : (
                  <span
                    className="mr-3 dflex-ac"
                    onClick={() => downloadLetter(SANCTION)}
                    style={{
                      cursor: "pointer",
                      width: "max-content",
                    }}
                  >
                    {isDownloadingFile === SANCTION ? (
                      <div id="loading-spinner" className="mr-2">
                        <div
                          className="spin-icon"
                          style={{
                            width: "8px",
                            height: "8px",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <img
                        alt="download"
                        src="/assets/blue-download.svg"
                        className="mr-2"
                      />
                    )}
                    <BlueText>Sanction Letter</BlueText>
                  </span>
                )}
                {renderSanctionRefId()}
              </div>
              <div className="dflex-ac">
                {renderUploadButton(
                  sanctionSignedFileRef,
                  "sanction_uploaded_signed_link"
                )}
                {renderStatus(investor?.sanction_status)}
              </div>
            </div>
            <div className="dflexJA mb-4">
              {investor.mfa_status === OPEN ? (
                <span className="mr-3 dflex-ac">
                  <BlackText>Master Facility Agreement</BlackText>
                </span>
              ) : (
                <span
                  className="mr-3 dflex-ac"
                  onClick={() => downloadLetter(MFA)}
                  style={{ cursor: "pointer" }}
                >
                  {isDownloadingFile === MFA ? (
                    <div id="loading-spinner" className="mr-2">
                      <div
                        className="spin-icon"
                        style={{
                          width: "8px",
                          height: "8px",
                        }}
                      ></div>
                    </div>
                  ) : (
                    <img
                      alt="download"
                      src="/assets/blue-download.svg"
                      className="mr-2"
                    />
                  )}
                  <BlueText>Master Facility Agreement</BlueText>
                </span>
              )}
              <span className="dflex ml-auto">
                {renderUploadButton(
                  mfaSignedFileRef,
                  "mfa_uploaded_signed_link"
                )}
              </span>
              {renderStatus(
                investor?.investor_mfa_status === INVESTOR_SIGNED
                  ? investor?.investor_mfa_status
                  : investor?.mfa_status
              )}
            </div>
            <div className="dflexJA">{renderActivationSheetAndUGROLafId()}</div>
          </div>
        </div>
        <div className="modal-footer dflex-ja border-0 pt-0 px-4 mb-1">
          {renderCaptureSigningDate()}
          {renderSaveApproveBtn}
        </div>
      </>
    );
  };

  const updateExpiryDate = (date, investor_deal_id) => {
    const input = {
      investor_deal_id,
      expiration_date: date,
    };
    if (
      date !==
      (expiration_date?.length === 10
        ? expiration_date
        : moment(expiration_date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"))
    )
      investorDealUpdate(input, () => {
        setExpiryDate(date);
        showNotification(
          TOAST_TYPES.SUCCESS,
          "Expiry date updated successfully"
        );
      });
  };

  const getInvestorStatus = () => {
    if (
      investor.offer_status === INVESTOR_OFFER_APPROVED &&
      investor?.investor_deal_status
    )
      return investor.investor_deal_status;
    return investor.offer_status;
  };

  const handleSendKycAndDocsClick = () => {
    callApi("deals/admin/send_kyc_docs/investor_deal_update", "post", {
      investor_deal_id: investor._id,
    })
      .then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          if (isEnvProd) {
            mixPanelTrack({
              id: investeeOrganization?._id,
              trackUserObj: {
                clicked_by: userName,
                deal_name: dealName,
                deal_id: dealId,
                investor_name: investor_name,
              },
              trackCategoryName: "Share KYC with investor",
            });
          }
          fetchDeals(investeeOrgId,totId,offerId);
          showNotification("success", "Kyc & Docs sent successfully");
        } else
          showNotification(
            "error",
            "Error while sending Kyc & Docs Sanction Request"
          );
      })
      .catch((error) => {
        showNotification(
          "error",
          "Error while sending Kyc & Docs Sanction Request"
        );
      });
  };

  // const handleSendDrawdownRequest = () => {
  //   callApi("/deals/admin/send_drawdown_request/investor_deal_update", "post", {
  //     investor_deal_id: investor._id,
  //     drawdown_id: latestOpenDrawdownLetter._id,
  //   })
  //     .then((res) => {
  //       if (res.status === API_STATUS.SUCCESS) {
  //         showNotification("success", "Drawdown Request sent successfully");
  //       } else showNotification("error", "Error while sending Drawdown Request");
  //     })
  //     .catch((error) => {
  //       showNotification("error", "Error while sending Drawdown Request");
  //     });
  // };

  const renderActivationSheetAndUGROLafId = () => {
    return (
      <>
        {feeder_sheet ||
          feeder_application_sheet ||
          feeder_coapplication_sheet ||
          activation_sheet ? (
          <span
            className="mr-3 dflex-ac"
            onClick={() => {
              if (feeder_sheet) {
                downloadLetter(ACTIVATION, feeder_sheet);
              } else if (
                feeder_application_sheet &&
                feeder_coapplication_sheet
              ) {
                downloadLetter(ACTIVATION, feeder_application_sheet);
                downloadLetter(ACTIVATION, feeder_coapplication_sheet);
              } else {
                downloadLetter(ACTIVATION, activation_sheet);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {isDownloadingFile === ACTIVATION ? (
              <div id="loading-spinner" className="mr-2">
                <div
                  className="spin-icon"
                  style={{
                    width: "8px",
                    height: "8px",
                  }}
                ></div>
              </div>
            ) : (
              <img
                alt="download"
                src="/assets/blue-download.svg"
                className="mr-2"
              />
            )}
            <BlueText>Activation Sheet</BlueText>
          </span>
        ) : (
          <span className="mr-3 dflex-ac">
            <img
              alt="download"
              src="/assets/grey_download.svg"
              className="mr-2"
            />
            <GreyText>Activation Sheet</GreyText>
          </span>
        )}
        {investorIsUGRO && !skipSigning.view && (
          <div
            className="d-flex ml-3"
            style={{
              backgroundColor: "#F8F8F8",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              placeholder="Enter UGRO LAF ID"
              disabled={investor?.offer_status !== INVESTOR_OFFER_NOT_SENT}
              value={uGroLAF_ID}
              onChange={(e) => setUGroLAF_ID(e.target.value)}
              className="p-2"
              style={{
                width: "136px",
                border: "none",
                backgroundColor: "#F8F8F8",
              }}
            />
            <img className="mr-2" src="/assets/editIcon.svg" alt="" />
          </div>
        )}
      </>
    );
  };

  const updateRepaymentDate = () => {
    if (repaymentDay < 1 || repaymentDay > 28) {
      showNotification(
        TOAST_TYPES.ERROR,
        "Repayment day must be between 1 and 28"
      );
      return;
    }
    investorDealUpdate(
      {
        investor_deal_id: investor?._id,
        deal_repayment_date: repaymentDay,
      },
      () => {
        showNotification(
          TOAST_TYPES.SUCCESS,
          "Repayment day updated successfully"
        );
      }
    );
  };

  const updateOfferTenure = () => {
    const payload = {
      offerId: offer_id,
      totId: totId,
      customTenure: Number(offerTenure),
    };
    callApi("/admin/update-deal-offer", "post", payload).then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        showNotification(TOAST_TYPES.SUCCESS, "Offer Tenure Saved", 5000);
        showNotification(
          TOAST_TYPES.WARNING,
          "Please regenerate IR and reshare deal",
          ""
        );
      } else {
        showNotification(TOAST_TYPES.ERROR, res?.data?.message);
      }
    });
  };

  const remainingLimit = (approved, drawn) => {
    if (Number.isFinite(approved) && Number.isFinite(drawn)) {
      return priceFormat(
        Number.parseFloat(approved.toFixed(2)) -
        Number.parseFloat(drawn.toFixed(2)),
        2
      );
    }
    return "-";
  };

  const regenerateApplicationForm = () => {
    callApi("/pdf/generateApplicationFormIncred", "post", {
      "offerId": offerId
    })
    .then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        showNotification(TOAST_TYPES.SUCCESS, "Regenerated successfully");
        fetchDeals(investeeOrgId,totId,offerId);
      } else {
        showNotification(TOAST_TYPES.ERROR, "Error while regenerating Application Form");
      }
    })
    .catch((error) => {
      showNotification(TOAST_TYPES.ERROR, "Error while regenerating Application Form");
    });
  };

  const regenerateMfaAndSl = (pdfType) => {
    const investorOrgId = investor?.investor_organization_id
    if((pdfType === DEAL_DOCUMENT_TYPE.MFA_LETTER && isMfaUploaded)|| (pdfType === DEAL_DOCUMENT_TYPE.SANCTION_LETTER && isSanctionUploaded)){
      handleRegenerate(pdfType)  // docs are already uploaded
      return;
    } 
    if ( !isMfaOrSlOrAfPresentInDeal(SANCTION) && pdfType === DEAL_DOCUMENT_TYPE.SANCTION_LETTER && investorOrgId && slInvestorList.length > 0 && slInvestorList.includes(investorOrgId)) {
      setAlertType(DEAL_DOCUMENT_TYPE.SANCTION_LETTER);
      setActionBtnNameOnAlrt(DEAL_AlERT_BUTTON_TXT.REGENERATE);
      setMsgOnAlert(MFA_SL_ALERT_MSG);
      setShowAlertModal(true);
    } else if ( !isMfaOrSlOrAfPresentInDeal(MFA) && pdfType === DEAL_DOCUMENT_TYPE.MFA_LETTER && investorOrgId && mfaInvestorList.length > 0 && mfaInvestorList.includes(investorOrgId)) {
      setAlertType(DEAL_DOCUMENT_TYPE.MFA_LETTER);
      setActionBtnNameOnAlrt(DEAL_AlERT_BUTTON_TXT.REGENERATE);
      setMsgOnAlert(MFA_SL_ALERT_MSG);
      setShowAlertModal(true);
    } else {
      handleRegenerate(pdfType)
    }
  }
  const handleRegenerate = (pdfType) => {
    callApi("/admin/create-deal-pdf", "post", {
      pdfType: pdfType,
      investor_organization_id: investor?.investor_organization_id,
      deal_id: investor?.deal_id,
      update: true,
    })
      .then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          fetchDeals(investeeOrgId,totId,offerId);
          showNotification(TOAST_TYPES.SUCCESS, "Regenerated successfully");
        } else {
          const message = res.data.message ?? "Some error occurred!";
          showNotification(TOAST_TYPES.ERROR, message);
        }
      })
      .catch((error) => {
        showNotification(TOAST_TYPES.ERROR, "Error while regenerating");
      });
  };

  const isDealDebtSyndication = () => {
    return investor?.loanType === "Debt Syndication";
  };

  // const latestOpenDrawdownLetter = useMemo(() => {
  //   const openDrawdownLetters = investor?.drawdown_letters
  //     ?.filter((d) => d.drawdown_status === DRAWDOWN_OPEN)
  //     .sort((a, b) => {
  //       const dateA = dayjs(a);
  //       const dateB = dayjs(b);
  //       if (dateA.isAfter(dateB)) return -1;
  //       else return 1;
  //     });
  //   if (openDrawdownLetters && openDrawdownLetters.length > 0) {
  //     return openDrawdownLetters[0];
  //   }
  //   return null;
  // }, [investor]);

  const handleOfferTenureChange = (offerTenureValue) => {
    setOfferTenure(offerTenureValue);
    if (
      Number(offerTenureValue) !== (offer_custom_tenure ?? totMaxTenure) &&
      offerTenureValue &&
      Number(offerTenureValue) > 0
    )
      setShowSaveTenureBtn(true);
    else setShowSaveTenureBtn(false);
  };

  const handleSigningMethodChange = (signingMethod) => {
    if (signingMethod === investor?.signing_method) return;
    setDisableSwitch(true);
    investorDealUpdate(
      {
        investor_deal_id: investor?._id,
        signingMethod,
      },
      (err) => {
        setDisableSwitch(false);
        if (err) return;
        showNotification(
          TOAST_TYPES.SUCCESS,
          "Signing method updated successfully"
        );
      }
    );
  };

  const handleLenderPfChange = (value) => {
    if (value === '') {
      setLenderPf(value);
      setShowSaveLenderPfBtn(false);
      return;
    }

    const numValue = Number(value);
    if (numValue < 0) {
      showNotification(TOAST_TYPES.ERROR, "Lender PF cannot be negative.");
      return;
    }

    setLenderPf(value);
    if (numValue !== Number(initialLenderPfRef.current) || 
        (numValue === 0 && Number(initialLenderPfRef.current) !== 0)) {
      setShowSaveLenderPfBtn(true);
    } else {
      setShowSaveLenderPfBtn(false);
    }
  };

  const updateLenderPf = async () => {

    if (!lenderPf) {
      showNotification(TOAST_TYPES.ERROR, "Please enter a valid Lender PF value");
      return;
    }

    const payload = {
      offerId: offer_id,
      totId: totId,
      lenderProcessingFees: Number(lenderPf)
    };
    
    const res = await sendUpdateDealOffer(payload);
    if (res.status === API_STATUS.SUCCESS) {
      setShowSaveLenderPfBtn(false);
      showNotification(TOAST_TYPES.SUCCESS, "Lender PF Saved, please regenerate mfa/sl", 5000);
    } else {
      showNotification(TOAST_TYPES.ERROR, res?.message);
    }
  };

  const sendWhatsapp = (text) => {
    const message = encodeURIComponent(
      text.map((item) => `${item.name}: ${item.invitationUrl}`).join("\n")
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareLeegalityLinks = (docType) => {
    if(investorIsSamFin()&&!sanctionId){
      showNotification( TOAST_TYPES.ERROR, `ARN Number missing. Add ARN number before sending MFA/SL for signing`)
      return;
    }
    if (
      window.confirm(
        `Are you sure? This will share a link to you on the email as well as open a Whatsapp tab to send the ${docType} links there as well.`
      )
    ) {
      callApi("deals/sendLeegalityLinksToSpoc", "post", {
        docType,
        investorDealId: investor?._id,
        investeeOrgId: investor?.investee_organization_id,
        investorOrgId: investor?.investor_organization_id,
        dealId: investor?.deal_id,
      })
        .then((res) => {
          if (res.status === API_STATUS.SUCCESS) {
            sendWhatsapp(res.data);
            showNotification(TOAST_TYPES.SUCCESS, "Links shared successfully");
          } else {
            const message = res.data.message ?? "Some error occurred!";
            showNotification(TOAST_TYPES.ERROR, message);
          }
        })
        .catch((err) => {
          showNotification(TOAST_TYPES.ERROR, "Some error occurred!");
        });
    }
  };

  const openCancelDocumentModal = (docType) => {
    setCanceledDocType(docType);
    setShowCancelDocumentModal(true);
  }

  const cancelSignedDocument = () => {
    if (isEmpty(canceledDocType) || isEmpty(cancellationReason)) return;
    callApi("admin/cancel-sanction-mfa", "post", {
      docType: canceledDocType,
      investorDealId: investor?._id,
      cancellationReason: cancellationReason,
    })
      .then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          fetchDeals(investeeOrgId,totId,offerId);
          showNotification(TOAST_TYPES.SUCCESS, `${canceledDocType} cancelled successfully!`);
        } else {
          const message = res.data.message ?? "Some error occurred!";
          showNotification(TOAST_TYPES.ERROR, message);
        }
        setShowCancelDocumentModal(false);
        setCancellationReason("");
        setCanceledDocType("");
      })
      .catch((err) => {
        showNotification(TOAST_TYPES.ERROR, "Some error occurred!");
        setShowCancelDocumentModal(false);
        setCancellationReason("");
        setCanceledDocType("");
      });
  };

  const renderCardContent = () => {
    switch (viewIs) {
      case DOCUMENTS:
        if (skipSigning.view) return renderSkipSigning();
        return (
          <>
            <div className="modal-body pt-0 px-4 mb-2">
              <div className="CardBorder mb-0">
                <div className="dflexJA mb-3">
                  <div className="fs12">Deal Documents</div>
                  <div className="dflexJA">
                    {/* <div className={`signSwitch ${disableSwitch ? 'disabled' : ''}`}>
                      <button
                        className={
                          isEmpty(investor?.signing_method) ||
                          investor?.signing_method === MFA_SL_SIGNING_WORKFLOWS.AADHAAR ? 'aadhaar' : ''
                        }
                        onClick={() => handleSigningMethodChange(MFA_SL_SIGNING_WORKFLOWS.AADHAAR)}
                        disabled={disableSwitch}
                      >
                        Aadhaar
                      </button>
                      <button
                        className={investor?.signing_method === MFA_SL_SIGNING_WORKFLOWS.DSC ? 'dsc' : ''}
                        onClick={() => handleSigningMethodChange(MFA_SL_SIGNING_WORKFLOWS.DSC)}
                        disabled={disableSwitch}
                      >
                        DSC
                      </button>
                    </div> */}
                    <div>{renderIcon()}</div>
                  </div>
                </div>
                {/* Sanction Letter download */}
                <div className="dflexJA mb-4">
                  {investor?.offer_status !== INVESTOR_OFFER_APPROVED ? (
                    <div className="dflex">
                      <img
                        alt="download"
                        src="/assets/grey_download.svg"
                        className="mr-2"
                      />
                      <span className="">
                        <GreyText>Sanction Letter</GreyText>
                      </span>
                    </div>
                  ) : (
                    <> 
                      <div className="dflex-ac">
                        <span
                          className="mr-3 dflex-ac"
                          onClick={() => handleDownloadMfaOrSlOrAf(SANCTION)}
                          style={{
                            cursor: "pointer",
                            width: "max-content",
                          }}
                        >
                          {isDownloadingFile === SANCTION ? (
                            <div id="loading-spinner" className="mr-2">
                              <div
                                className="spin-icon"
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                              ></div>
                            </div>
                          ) : (
                            <img
                              alt="download"
                              src="/assets/blue-download.svg"
                              className="mr-2"
                            />
                          )}
                          <BlueText>Sanction Letter</BlueText>
                        </span>
                        {renderSanctionRefId()}
                      </div>
                      <div className="dflex-ac">
                        <input
                          style={{ width: "75px", height: "17px" }}
                          className="mx-2 fw300"
                          type="date"
                          id="sanction-date"
                          name="sanction-date"
                          onChange={(e) => setSanctionDate(e.target.value)}
                          value={sanctionDate}
                        />
                        {renderUploadButton(
                          sanctionFileRef,
                          "sanction_uploaded_link"
                        )}
                        {renderStatus(investor?.sanction_status)}
                        {[OPEN, SENT_FOR_SIGNING].includes(investor?.sanction_status) && !isSanctionPartiallySigned && (
                            <button
                              className="regenerateBtn"
                              onClick={() => regenerateMfaAndSl(DEAL_DOCUMENT_TYPE.SANCTION_LETTER)}
                            >
                              Regenerate
                            </button>
                          )}
                        {[SENT_FOR_SIGNING].includes(investor?.sanction_status) && (
                            <button
                              className="regenerateBtn"
                              onClick={() => shareLeegalityLinks("sanction")}
                            >
                              Send Links
                            </button>
                          )}
                        {[SENT_FOR_SIGNING, SIGNING, SIGNED].includes(investor?.sanction_status) && isSanctionPartiallySigned && (
                          <button
                            className="cancelSignedBtn"
                            onClick={() => openCancelDocumentModal(DEAL_DOCUMENT_TYPE.SANCTION_LETTER)}
                          >
                            Cancel Signed
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* Master Facility Agreement (MFA) Letter Download */}
                <div className="dflexJA mb-4">
                  {investor?.offer_status !== INVESTOR_OFFER_APPROVED ? (
                    <div>
                      <img
                        alt="download"
                        src="/assets/grey_download.svg"
                        className="mr-2"
                      />
                      <span className="">
                        <GreyText>Master Facility Agreement</GreyText>
                      </span>
                    </div>
                  ) : (
                    <>
                      <span
                        className="mr-3 dflex-ac"
                        onClick={() => handleDownloadMfaOrSlOrAf(MFA)}
                        style={{ cursor: "pointer" }}
                      >
                        {isDownloadingFile === MFA ? (
                          <div id="loading-spinner" className="mr-2">
                            <div
                              className="spin-icon"
                              style={{
                                width: "8px",
                                height: "8px",
                              }}
                            ></div>
                          </div>
                        ) : (
                          <img
                            alt="download"
                            src="/assets/blue-download.svg"
                            className="mr-2"
                          />
                        )}
                        <BlueText>Master Facility Agreement</BlueText>
                      </span>
                      <span className="dflex ml-auto">
                        <input
                          style={{ width: "75px", height: "17px" }}
                          className="mx-2 fw300"
                          type="date"
                          id="mfa-date"
                          name="mfa-date"
                          onChange={(e) => setMfaDate(e.target.value)}
                          value={mfaDate}
                        />
                        {renderUploadButton(mfaFileRef, "mfa_uploaded_link")}
                      </span>
                      {renderStatus(
                        investor?.investor_mfa_status === INVESTOR_SIGNED
                          ? investor?.investor_mfa_status
                          : investor?.mfa_status
                      )}
                      {[OPEN, SENT_FOR_SIGNING].includes(investor?.mfa_status) && !isMfaPartiallySigned && (
                        <button
                          className="regenerateBtn"
                          onClick={() => regenerateMfaAndSl(DEAL_DOCUMENT_TYPE.MFA_LETTER)}
                        >
                          Regenerate
                        </button>
                      )}
                      {[SENT_FOR_SIGNING].includes(investor?.mfa_status) && (
                        <button
                          className="regenerateBtn"
                          onClick={() => shareLeegalityLinks("mfa")}
                        >
                          Send Links
                        </button>
                      )}
                      {[SENT_FOR_SIGNING, SIGNING, SIGNED].includes(investor?.mfa_status) && isMfaPartiallySigned && (
                        <button
                          className="cancelSignedBtn"
                          onClick={() => openCancelDocumentModal(DEAL_DOCUMENT_TYPE.MFA_LETTER)}
                        >
                          Cancel Signed
                        </button>
                      )}
                    </>
                  )}
                </div>
                {/* Application Form Download */}
                {investor?.application_form && (
                  <div className="dflexJA mb-4">
                    {investor?.offer_status !== INVESTOR_OFFER_APPROVED ? (
                      <div>
                        <img
                          alt="download"
                          src="/assets/grey_download.svg"
                          className="mr-2"
                        />
                        <span className="">
                          <GreyText>Application Form</GreyText>
                        </span>
                      </div>
                    ) : (
                      <>
                        <span
                          className="mr-3 dflex-ac"
                          onClick={() => handleDownloadMfaOrSlOrAf(APPLICATION_FORM)}
                          style={{ cursor: "pointer" }}
                        >
                          {isDownloadingFile === APPLICATION_FORM ? (
                            <div id="loading-spinner" className="mr-2">
                              <div
                                className="spin-icon"
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                              ></div>
                            </div>
                          ) : (
                            <img
                              alt="download"
                              src="/assets/blue-download.svg"
                              className="mr-2"
                            />
                          )}
                          <BlueText>Application Form</BlueText>
                        </span>
                        <button
                          className="regenerateBtn"
                          onClick={() => regenerateApplicationForm()}
                        >
                          Regenerate
                        </button>
                      </>
                    )}
                  </div>
                )}
                {/* KYC Audit Trail Download */}
                {([process.env.REACT_APP_INCRED_ORG_ID,process.env.REACT_APP_INCRED_ORG_RECUR_ID].includes(investor_organization_id))&& (
                    <div className="dflexJA mb-4 kyc">
                      <>
                        <span
                          className="mr-3 dflex-ac"
                          onClick={(e) =>
                            downloadKYCAuditTrail(
                              e,
                              KYC_AUDIT_TRAIL,
                              investeeOrganization?._id,
                              investor_organization_id
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {isDownloadingFile === KYC_AUDIT_TRAIL ? (
                            <div id="loading-spinner" className="mr-2">
                              <div
                                className="spin-icon"
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                              ></div>
                            </div>
                          ) : (
                            <img
                              alt="download"
                              src="/assets/blue-download.svg"
                              className="mr-2"
                            />
                          )}
                          <BlueText>KYC Audit Trail</BlueText>
                        </span>
                      </>
                    </div>
                  )}
                {/* Activation Sheet download and uGro LAIF Id */}
                <div className="d-flex mb-4">
                  {renderActivationSheetAndUGROLafId()}
                </div>

                {/* Investment Report download */}
                <div className="d-flex mb-4">
                  {!investor?.investment_report ? (
                    <div>
                      <img
                        alt="download"
                        src="/assets/grey_download.svg"
                        className="mr-2"
                      />
                      <span className="">
                        <GreyText>Investment Report</GreyText>
                      </span>
                    </div>
                  ) : (
                    <span
                      className="mr-3 dflex-ac"
                      onClick={() => downloadLetter(INVESTMENT_REP)}
                      style={{ cursor: "pointer" }}
                    >
                      {isDownloadingFile === INVESTMENT_REP ? (
                        <div id="loading-spinner" className="mr-2">
                          <div
                            className="spin-icon"
                            style={{
                              width: "8px",
                              height: "8px",
                            }}
                          ></div>
                        </div>
                      ) : (
                        <img
                          alt="download"
                          src="/assets/blue-download.svg"
                          className="mr-2"
                        />
                      )}
                      <BlueText>Investment Report</BlueText>
                    </span>
                  )}
                </div>

                {/* Drawdown letter */}
                {!isEmpty(investor?.drawdown_letters) && (
                  <div>
                    {investor?.drawdown_letters
                      ?.filter(
                        (d) => d.drawdown_status !== DRAWDOWN_NOT_APPROVED
                      )
                      .map((drawdown, index) => (
                        <div className="dflex-ac my-3">
                          <span
                            key={index}
                            className="mr-3 dflex-ac"
                            onClick={() => {
                              downloadLetter(
                                DRAWDOWN,
                                drawdown?.drawdown_letter
                              );
                              downloadLetter(
                                DRAWDOWN_CALC_SHEET,
                                drawdown?.dd_calc_sheet,
                                "application/xlsx"
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              width: "280px",
                            }}
                          >
                            <img
                              alt="download"
                              src="/assets/blue-download.svg"
                              className="mr-2"
                            />
                            <BlueText>
                              Drawdown for ₹{" "}
                              {priceFormat(drawdown?.drawdown_amount * LAKH, 2)}{" "}
                              {`on ${dayjs(drawdown?.drawdown_date).format(
                                "DD MMM YYYY"
                              )}`}
                            </BlueText>

                          </span>
                          <div>
                          { isInvestorValidForLanIdInput() && renderLanIdforValidInvestor(drawdown,index)}
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {/* Additional Documents */}
                <>
                  {/* Render Additional Documents to Download */}
                  {investor?.additional_deal_documents?.map((doc) => (
                    <div
                      className="dflexJA my-3"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          downloadLetter(ADDITION_DOCUMENT, doc?.doc_link)
                        }
                        className="w-50"
                      >
                        <img
                          alt="download"
                          src="/assets/blue-download.svg"
                          className="mr-2"
                        />
                        <BlueText>{doc?.description}</BlueText>
                      </span>
                      <span className="dflex-ac w-50 bgclr12 px-2 py-1 br6">
                        <BlueText>{doc?.doc_name}</BlueText>
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => deleteAdditionalDocs(doc?._id)}
                        >
                          <img
                            alt="cross"
                            src="/assets/clear-grey.svg"
                            className="mb-1"
                            width="11px"
                          />
                        </span>
                      </span>
                    </div>
                  ))}
                  {/* Additional Documents Upload */}
                  {hasClickedAddAdditionalDocs && (
                    <div className="additional-doc-inputs-wrapper">
                      <input
                        className="additional-doc-input-description"
                        type="text"
                        value={additionalDocDescription}
                        placeholder="Enter File Description"
                        onChange={(e) =>
                          setAdditionalDocDescription(e.target.value)
                        }
                      />
                      <label>
                        <input
                          type="file"
                          hidden
                          onChange={(e) =>
                            onAdditionalDocsUpload(
                              e,
                              "additional_deal_documents"
                            )
                          }
                        />
                        <div className="add-Additional-docs-upload">
                          <img
                            width="10px"
                            height="10px"
                            src="/assets/file-upload.svg"
                            alt="Upload"
                            className="mr-1 ml-1"
                          />
                          <span className="">Upload</span>
                        </div>
                      </label>
                    </div>
                  )}

                  {/* Add Additional Docs */}
                  {/* <div
                    className="add-Additional-docs"
                    onClick={() => setHasClickedAddAdditionalDocs(true)}
                  >
                    <label className="dflex-ac mb-0">
                      <img
                        src="/assets/Add-blue.svg"
                        alt="Add"
                        width="10px"
                        height="10px"
                        style={{ margin: "0 5px" }}
                      />
                      Additional Deal Documents
                    </label>
                  </div> */}
                </>
              </div>

              <div className="d-flex mt-4">
                {/* Company Status */}
                <div
                  style={{
                    width: "49%",
                    padding: "10px",
                    paddingBottom: "20px",
                  }}
                  className="CardBorder mb-0 mr-1"
                >
                  <div className="dflexJA fs12">
                    <span>Company Status:</span>
                    <span>{renderDealStatus()}</span>
                  </div>
                  {investor?.offer_status === INVESTOR_OFFER_APPROVED && (
                    <div className="border-0 pt-0 mb-1">
                      {hasSentForSigning && (
                        <div className="dflex-ac fs10 fw300 mt-3">
                          <span>Signing Expiry Date</span>
                          <input
                            style={{ width: "75px", height: "17px" }}
                            className="mx-2 fw300"
                            type="date"
                            id="expiry-date"
                            name="expiry-date"
                            onChange={(e) => setExpiryDate(e.target.value)}
                            value={expiryDate}
                          />
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              updateExpiryDate(expiryDate, investor?._id)
                            }
                          >
                            <SaveSVG
                              width="14px"
                              height="15px"
                              fill="#4c8dff"
                            />
                          </span>
                        </div>
                      )}
                      {renderSendButton()}
                    </div>
                  )}
                </div>

                {/* Investor Status */}
                <div
                  style={{
                    width: "49%",
                    padding: "10px",
                    paddingBottom: "20px",
                  }}
                  className="CardBorder ml-1 mb-0"
                >
                  <div className="dflexJA fs12">
                    <span>Investor Status:</span>
                    <span className={`${camelCase(getInvestorStatus())}`}>
                      {getInvestorStatus()}
                    </span>
                  </div>

                  {/* Send Deal to Ugro */}
                  {investor?.offer_status === INVESTOR_OFFER_NOT_SENT &&
                    investorIsUGRO && (
                      <button
                        className={
                          isEmpty(uGroLAF_ID)
                            ? "send-deal-toUgrow-btn-disabled mt-4 fs10"
                            : "send-deal-toUgrow-btn mt-4 fs10"
                        }
                        onClick={sendDealToUgro}
                        disabled={isEmpty(uGroLAF_ID?.trim())}
                      >
                        Send Deal to UGRO
                      </button>
                    )}
                  {investor?.offer_status === INVESTOR_OFFER_NOT_SENT &&
                    investorIsSamFin() && (
                      <button
                        className={
                          isEmpty(uGroLAF_ID)
                            ? "send-deal-toUgrow-btn-disabled mt-4 fs10"
                            : "send-deal-toUgrow-btn mt-4 fs10"
                        }
                        onClick={sendDealToSamFin}
                        disabled={isEmpty(uGroLAF_ID?.trim())}
                      >
                        Send Deal to SamFin
                      </button>
                    )}

                  {/* Investor interested */}
                  {/* {investor?.offer_status === INVESTOR_OFFER_SENT && (
                    <div className="dflexJA fs10 mt-4">
                      Investor Interested
                      <div className="investor-intrested-wrapper">
                        <span
                          onClick={() => {
                            setInvestorInterested(false);
                            handleInvestorNotInterested();
                          }}
                          className={
                            !investorInterested ? "selected-option" : ""
                          }
                        >
                          No
                        </span>
                        <span
                          className={
                            investorInterested ? "selected-option" : ""
                          }
                        >
                          Yes
                        </span>
                      </div>
                    </div>
                  )} */}

                  {/* Drawdown letter */}
                  {/* {latestOpenDrawdownLetter && (
                    <div className="dflex-ac my-3">
                      <span
                        className="mr-3 dflex-ac"
                        onClick={() =>
                          downloadLetter(DRAWDOWN, latestOpenDrawdownLetter?.drawdown_letter)
                        }
                        style={{
                          cursor: "pointer",
                          width: "max-content",
                        }}
                      >
                        <img alt="download" src="/assets/blue-download.svg" className="mr-2" />
                        <BlueText>
                          Drawdown for{" "}
                          ₹ {priceFormat(latestOpenDrawdownLetter?.drawdown_amount * LAKH, 2)} (
                          {dayjs(latestOpenDrawdownLetter?.drawdown_date).isToday()
                            ? "New Request"
                            : `on ${dayjs(latestOpenDrawdownLetter?.drawdown_date).format(
                                "DD MMM YYYY"
                              )}`}
                          )
                        </BlueText>
                      </span>
                    </div>
                  )} */}

                  {/* {latestOpenDrawdownLetter && (
                    <button
                      className="send-deal-toUgrow-btn mt-4 fs10"
                      onClick={handleSendDrawdownRequest}
                    >
                      Send Drawdown Request
                    </button>
                  )} */}

                  {/* Send KYC & Docs */}
                  {investor?.investor_deal_status ===
                    INVESTOR_DEALS_DOCS_READY && (
                      <button
                        className="send-deal-toUgrow-btn mt-4 fs10"
                        onClick={handleSendKycAndDocsClick}
                      >
                        Send KYC & Docs
                      </button>
                    )}
                </div>
              </div>
              {(showAlertModal) && <AlertModal
                title="Alert!"
                show={showAlertModal}
                textMsg={msgOnAlert}
                buttonName={actionBtnNameOnAlrt}
                actionMethod={actionOnAlert}
                handleClose={OnCancelAlertHandler}
              ></AlertModal>
              }
            </div>
          </>
        );
      case DEAL_TERMS:
        return (
          <>
            {!isDealDebtSyndication() && (
              <>
                <div className="modal-body pt-0 px-4 mb-2">
                  <div className="CardBorder mb-0">
                    <div className="fs12 d-flex mb-2">
                      Deal Terms
                      <div
                        style={{ cursor: "pointer" }}
                        className="ml-auto btn-link"
                        data-dismiss="modal"
                        onClick={() => {
                          if (OverviewViewIs !== OVERVIEW_SCREENMAP.TOT) {
                            dispatch(
                              changeScreenOnCompaniesOverview(
                                OVERVIEW_SCREENMAP.TOT
                              )
                            );
                            dispatch(updateSelectedTotIdToRedirect(dealsTotId));
                          }
                          closeDrawer();
                        }}
                      >
                        <img
                          src="/assets/deal-doc.svg"
                          alt=""
                          className="mr-1"
                          style={{ marginTop: "-3px" }}
                        />
                        View detailed terms
                      </div>
                    </div>
                    <div className="row fs12 fw300">
                      <div className="col-md-6">
                        <div className="dflexJA mt-2">
                          <div>Signing Date</div>
                          <div style={greyColorBox}>
                            {!mfa_sign_date
                              ? "Auto generated"
                              : moment(mfa_sign_date).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="dflexJA mt-2">
                          <div>End Date</div>
                          {!endDate ? (
                            <div
                              style={{
                                fontSize: "8px",
                                width: "83px",
                                alignItems: "center",
                                padding: "2.5px 20px 2.0px 2.5px",
                                border: "1px solid #D6D6D6",
                                borderRadius: "4px",
                              }}
                            >
                              Auto generated
                            </div>
                          ) : updatingDTField === "end_date" ? (
                            <span className="dflexJA" style={{ width: "65%" }}>
                              <input
                                style={{
                                  fontSize: "8px",
                                  width: "83px",
                                  height: "20px",
                                  alignItems: "center",
                                }}
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  e.target.value === ""
                                    ? setEndDate(moment().format("YYYY-MM-DD"))
                                    : setEndDate(e.target.value);
                                }}
                              />
                              <span className="dflex">
                                <img
                                  alt="cross"
                                  src="/assets/clear-red.svg"
                                  width="11px"
                                  style={{ cursor: "pointer" }}
                                  onClick={onCancelClickDT}
                                  className="ml-1 mr-4"
                                />
                                <img
                                  onClick={() => {
                                    onSaveClickDT({
                                      end_date: moment(endDate, "YYYY-MM-DD")
                                        .endOf("day")
                                        .toString(),
                                    });
                                  }}
                                  alt="save"
                                  src="/assets/save-icon1.svg"
                                  width="11px"
                                  className="mr-1"
                                />
                              </span>
                            </span>
                          ) : (
                            <span>
                              <span
                                className="mr-1"
                                style={{
                                  fontSize: "8px",
                                  alignItems: "center",
                                  padding: "5px 12px",
                                  border: "1px solid #D6D6D6",
                                  borderRadius: "4px",
                                }}
                              >
                                {moment(endDate).format("DD/MM/YYYY")}
                              </span>
                              <img
                                onClick={() => setUpdatingDTField("end_date")}
                                width="14px"
                                height="14px"
                                src="/assets/editIcon.svg"
                                alt="Edit"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row fs12 fw300">
                      <div className="col-md-6">
                        <div className="dflexJA mt-2">
                          <div>Repayment Day (1-28)</div>
                          {Number.isFinite(deal_repayment_date) ? (
                            <input
                              type="number"
                              style={{ maxWidth: "83px" }}
                              name="repayment-day"
                              id="repayment-day"
                              value={repaymentDay?.toString()}
                              onChange={(e) =>
                                setRepaymentDay(
                                  Number.parseInt(e.target.value, 10)
                                )
                              }
                            />
                          ) : (
                            <div style={greyColorBox}>-</div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="dflexJA mt-2">
                          <div>Remaining Limit</div>
                          <div style={greyColorBox}>
                            {remainingLimit(approved, drawn)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 fs12 fw300" style={{ width: "47.5%" }}>
                        <div className="dflexJA mt-2">
                          <div>Max Tenure (months)</div>
                          {offerTenure || totMaxTenure ? (
                            <input
                              type="number"
                              style={{ maxWidth: "83px" }}
                              name="offer_tenure"
                              id="offer_tenure"
                              value={offerTenure}
                              onChange={(e) =>
                                handleOfferTenureChange(e.target.value)
                              }
                            />
                          ) : (
                            <div style={greyColorBox}>-</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 fs12 fw300" style={{ width: "47.5%" }}>
                        <div className="dflexJA mt-2">
                          <div>Lender PF (%)</div>
                          <input
                            type="number"
                            style={{ maxWidth: "83px" }}
                            name="lender_pf"
                            id="lender_pf"
                            value={lenderPf}
                            onChange={(e) => handleLenderPfChange(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-4">
                    {/* Company Status */}
                    <div
                      style={{ width: "49%", padding: "10px" }}
                      className="CardBorder mb-0 mr-1"
                    >
                      <div className="dflexJA fs12">
                        <span className="ml-2">Company Status:</span>
                        <span>{renderDealStatus()}</span>
                      </div>
                    </div>

                    {/* Investor Status */}
                    <div
                      style={{ width: "49%", padding: "10px" }}
                      className="CardBorder ml-1 mb-0"
                    >
                      <div className="dflexJA fs12">
                        <span className="ml-2">Investor Status:</span>
                        <span className={`${camelCase(getInvestorStatus())}`}>
                          {getInvestorStatus()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 pt-0 px-4 mb-1">
                  {!!showSaveTenureBtn && (
                    <button className="btn3 mr-3 fs10" onClick={updateOfferTenure}>
                      Save Offer Tenure
                    </button>
                  )}
                  {!!showSaveLenderPfBtn && (
                    <button className="btn3 mr-3 fs10" onClick={updateLenderPf}>
                      Save Lender PF
                    </button>
                  )}
                  {repaymentDay !== deal_repayment_date && (
                    <button
                      className="btn3 mr-3 fs10"
                      onClick={updateRepaymentDate}
                    >
                      Save Repayment Day
                    </button>
                  )}
                  <span className="dflex fs10 fw400 m-0">
                    Sanction Status {"\u00a0"}
                    {renderStatus(investor?.sanction_status)}
                  </span>
                </div>
              </>
            )}
            {isDealDebtSyndication() && (
              <ViewIndicativeTerms
                investor={investor}
                fetchDeals={() => fetchDeals(investeeOrgId,totId,offerId)}
              />
            )}
          </>
        );
      case KYC_DOCS:
        return (
          <KycDocs
            investorDealId={investor._id}
            investorDealStatus={investor?.investor_deal_status}
            investor_organization_id={investor.investor_organization_id}
            investeeOrganizationId={investeeOrganizationId}
          />
        );
      case UW_QUERIES:
        case KYC_QUERIES:
          return (
            <UWQueries
            deal={showViewDocsDrawer} 
            currentTab={viewIs}
            getAllDealsData={() => getAllDealsData()}
            fetchDeals={() => fetchDeals(investeeOrgId,totId,offerId)}
            />
          )
      default:
        return <></>;
    }
  };

  const closeDrawer = () => {
    closeDealDrawer();
  };

  const closeCancelDocumentModal = () => {
    setCanceledDocType("");
    setCancellationReason("");
    setShowCancelDocumentModal(false);
  }

  return (
    <Drawer
      anchor="right"
      open={!!showViewDocsDrawer}
      onClose={closeDrawer}
      transitionDuration={200}
      disableEnforceFocus
      PaperProps={{
        style: {
          width: "60%",
          zIndex: 9999,
          height: "100%",
          padding: "30px 10px 30px 10px",
          fontFamily: "Graphik",
        },
      }}
    >
      <CancelDocumentModal
        showModal={showCancelDocumentModal}
        close={closeCancelDocumentModal}
        cancellationReason={cancellationReason}
        setCancellationReason={setCancellationReason}
        submitCancellation={cancelSignedDocument}
      />
      <div className=" px-3 mb-3 border-0 d-flex justify-content-between align-items-center  view-docs-modals-container">
        <div>{investor_name} Documents</div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={closeDrawer}
        >
          <img src="../../../../../assets/modal-cross.svg" alt="Close Modal" />
        </button>
      </div>
      <div className="ml-4 mb-2 pb-3">
        {!investor_name?.includes(RECUR_INVESTOR_DEAL_NAME) && (
          <span
            className="mr-5 pb-1"
            style={
              viewIs === DOCUMENTS ? blueColorBorder : { cursor: "pointer" }
            }
            onClick={() => setViewIs(DOCUMENTS)}
          >
            Documents
          </span>
        )}
        <span
          className="mr-5 pb-1"
          style={
            viewIs === DEAL_TERMS ? blueColorBorder : { cursor: "pointer" }
          }
          onClick={() => setViewIs(DEAL_TERMS)}
        >
          Deal Terms
        </span>
        {!investor_name?.includes(RECUR_INVESTOR_DEAL_NAME) && (
          <span
            className=" mr-5 pb-1"
            style={
              viewIs === KYC_DOCS ? blueColorBorder : { cursor: "pointer" }
            }
            onClick={() => setViewIs(KYC_DOCS)}
          >
            KYC Docs
          </span>
        )}
        <span
          className="mr-5  pb-1"
          style={
            viewIs === UW_QUERIES ? blueColorBorder : { cursor: "pointer" }
          }
          onClick={() => setViewIs(UW_QUERIES)}
        >
          UW Queries
        </span>
       <span
          className="pb-1"
          style={
            viewIs === KYC_QUERIES ? blueColorBorder : { cursor: "pointer" }
          }
          onClick={() => setViewIs(KYC_QUERIES)}
        >
          KYC Queries
        </span>
      </div>
      <div className="view-docs-modals-container">{renderCardContent()}</div>
    </Drawer>
  );
}