import axios from "axios";
import { GET, POST } from "../../common/Interceptor";
import { OLYMPUS_BASE_URL } from "../../util/apiCaller";
import { CustomRequest } from "../../common/common";

export function callParseDocument (input: { docId: string; extractionConfigId: string }) {
    const payload: CustomRequest = {
        method: POST,
        baseURL: OLYMPUS_BASE_URL,
        url: API_ENDPOINTS.BEFE_PARSE_DOCUMENT,
        data: {...input},
        isAuthRequired: true,
    }
    return axios(payload);
}

export function fetchParsedData (textractIdReference: string) {
    const payload: CustomRequest = {
        method: GET,
        baseURL: OLYMPUS_BASE_URL,
        url: API_ENDPOINTS.PAYMENT_GET_PARSED_DATA,
        params: {
            textractReferenceId: textractIdReference
        },
        isAuthRequired: true,
    }
    return axios(payload);
}

export function submitWireTransferRepaymentProof (data: Object) {
    const payload: CustomRequest = {
        method: POST,
        baseURL: OLYMPUS_BASE_URL,
        url: API_ENDPOINTS.PAYMENT_RECORD_PAYMENT,
        data,
        isAuthRequired: true,
    }
    return axios(payload);
}

export function fetchCompanyList (prefix: string) {
    const payload: CustomRequest = {
        method: GET,
        baseURL: OLYMPUS_BASE_URL,
        url: API_ENDPOINTS.GET_COMPANY_SEARCH_BY_PREFIX,
        params: {prefixName: prefix},
        isAuthRequired: true,
    }
    return axios(payload);
}

export const fetchInvestorWiseCollectionData = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        baseURL: OLYMPUS_BASE_URL,
        url: API_ENDPOINTS.GET_COLLECTION_VIEW_INVESTOR_WISE,
        params: {
            investeeOrganizationId: investeeOrgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

const API_ENDPOINTS = {
    BEFE_PARSE_DOCUMENT:'befe/parseDocument',
    PAYMENT_GET_PARSED_DATA:'payment/getParsedData',
    PAYMENT_RECORD_PAYMENT: 'payment/recordPayment',
    GET_COMPANY_SEARCH_BY_PREFIX: 'investeeOrganization/search-companies',
    GET_COLLECTION_VIEW_INVESTOR_WISE :'collection/getCollectionViewInvestorWise',
};
