import { useEffect, useState, useCallback, useRef } from 'react';
import useDebounce from '../../common/customHooks/useDebounce.ts';
import {
  getCompanyListMatchedWithPrefix,
  getExtractedDataForWireTransfer,
  parsedDocumentForWireTransfer,
  gettingAllInvestorWiseCollectionData,
  uploadTheWireTransferProof
} from '../../store/wireTransferStore/wireTransferHelper.ts';
import { SHOW_NOTIFICATION_STATUS, STATUS_TYPES } from '../../constants/enums.js';
import { getDecimalAndNumberFromInput, isValidDate, showNotification } from '../../util/utility.js';
import { Company, fileType, Investor, PaymentDetailsType, selectedInvestor } from './WireTransferDrawerTypes';
import useQueryParams from '../../common/customHooks/useQueryParams.ts';
import { Mixpanel } from '../../util/mixpanel.js';
import { ADMIN_SCREENSHOT_UPLOAD } from '../../constants/mixpanel/AdminEvents.ts';

type useWireTransferDrawerLogicType = {
  setWireTransferUploadSuccess: (value: boolean) => void;
}

const useWireTransferDrawerLogic = ({ setWireTransferUploadSuccess }: useWireTransferDrawerLogicType) => {
  const { params, updateQueryParams } = useQueryParams();
  const AdminEmail = localStorage.getItem('recur_email_id');
  const [isLoading, setIsLoading] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [investeeList, setInvesteeList] = useState<Company[]>([]);
  const [inputCompanyName, setInputCompanyName] = useState('');
  const previousCompanyName = useRef(inputCompanyName);
  const [investorList, setInvestorList] = useState<Investor | null>(null);
  const [file, setFile] = useState<fileType[]>([]);
  const [selectedInvestor, setSelectedInvestor] = useState<selectedInvestor>({ id: '', name: '' });
  const [selectedCompanyOrg, setSelectedCompanyOrg] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsType>({
    amount: '',
    utr: '',
    transactionDate: '',
  });

  const debouncedFetch = useDebounce(async (company: string) => {
    if (company.length >= 3) {
      try {
        setIsLoading(true);
        await getCompanyListMatchedWithPrefix(company, {
          OnSuccess: setInvesteeList,
          OnError: (error) => { console.error('Error fetching investee list:', error); setInvesteeList([]); },
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, 300);

  useEffect(() => {
    debouncedFetch(inputCompanyName);
  }, [inputCompanyName]);

  useEffect(() => {
    if (params.get('refId')) {
      GetExtractedData(params.get('refId') ?? '');
      setFile([
        {
          doc_name: params.get('fileName') ?? '',
          doc_link: params.get('docId') ?? '',
        },
      ]);
    }
  }, []);

  useEffect(() => {
    setSubmitDisabled(handleValidation());
  }, [paymentDetails, selectedInvestor, selectedCompanyOrg, file]);


  useEffect(() => {
    if (previousCompanyName.current && inputCompanyName === "") {
      setSelectedCompanyOrg("");
      setSelectedInvestor({ id: "", name: "" });
      setInvestorList(null);
    }
    previousCompanyName.current = inputCompanyName;
  }, [inputCompanyName]);


  const handleValidation = useCallback((): boolean => {
    let validation = false;
    if (paymentDetails.amount === null || paymentDetails.utr === null) {
      validation = true;
    }
    if (paymentDetails.amount === '' || paymentDetails.utr === '') {
      validation = true;
    }
    if (selectedInvestor.id === null || selectedInvestor.id === '') {
      validation = true;
    }
    if (selectedCompanyOrg === null || selectedCompanyOrg === '') {
      validation = true;
    }
    if (file.length === 0) {
      validation = true;
    }
    if (paymentDetails.transactionDate === null || paymentDetails.transactionDate === '') {
      validation = true;
    }
    return validation;
  }, [paymentDetails, selectedInvestor, selectedCompanyOrg, file]);

  const handleCompanySelection = useCallback(async (company: Company) => {
    const response = await gettingAllInvestorWiseCollectionData(company.id);
    if (response) {
      setInvestorList(response);
    }
    setSelectedCompanyOrg(company.id);
    setInputCompanyName(company.displayName);
    setShowDropDown(false);
  }, []);

  const handleInvestorChange = useCallback(
    (investorName: string) => {
      const selectedInvestorId = investorList?.investorMap[investorName];
      if (selectedInvestorId) {
        setSelectedInvestor({ id: selectedInvestorId, name: investorName });
      }
    },
    [investorList]
  );

  const handleDateChange = useCallback((value: string) => {
    if (value) {
      setPaymentDetails((prev) => ({ ...prev, transactionDate: value }));
    }
  }, []);

  const startParsingTheDocs = useCallback(async (input: { docId: string | number; fileName?: string }) => {
    updateQueryParams({
      wireTransferDrawer: 'true',
      docId: input.docId.toString(),
      fileName: input.fileName ?? '',
    });

    const res = await parsedDocumentForWireTransfer({
      docId: input.docId.toString(),
      extractionConfigId: 'RECORD_PAYMENT',
    });

    if (res) {
      updateQueryParams({
        wireTransferDrawer: 'true',
        docId: input.docId.toString(),
        fileName: input.fileName ?? '',
        refId: res.textractReferenceId,
      });

      GetExtractedData(res.textractReferenceId, input.fileName, input.docId.toString());
    } else {
      updateQueryParams({ apiFailed: 'true' });
    }
  }, [updateQueryParams]);

  const GetExtractedData = useCallback(
    async (textractId: string, fileName?: string, docId?: string) => {
      let count = 0;
      const interval = setInterval(async () => {
        if (count > 4) {
          clearInterval(interval);
          setFile([{ doc_name: fileName ?? '', doc_link: docId ?? '' }]);
          setPaymentDetails({ amount: '', utr: '', transactionDate: null });
        } else {
          const res = await getExtractedDataForWireTransfer(textractId);
          if (res.parsingStatus !== STATUS_TYPES.PENDING) {
            clearInterval(interval);
            setFile([{ doc_name: fileName ?? '', doc_link: docId ?? '' }]);
            setPaymentDetails({
              amount: getDecimalAndNumberFromInput(res?.extractedData?.AMOUNT ?? '') ?? '',
              utr: res?.extractedData?.UTR ?? '',
              transactionDate: res?.extractedData?.TRANSACTION_DATE ? res.extractedData.TRANSACTION_DATE : null,
            });
          } else {
            count++;
          }
        }
      }, 2000);
    },
    []
  );

  const submitWireTransfer = useCallback(async () => {

    if(isValidDate(paymentDetails.transactionDate)) {
      setSubmitDisabled(true);

      const payload = {
        investeeOrganizationId: selectedCompanyOrg,
        investorOrganizationId: selectedInvestor.id,
        amount: Number(paymentDetails.amount?.replaceAll(',', '')),
        utr: paymentDetails.utr,
        transactionDate: paymentDetails.transactionDate,
        referenceId: params.get('refId') ?? '',
        docId: params.get('docId') ?? '',
        sendEmail: true,
      };

      if (!payload.docId || !payload.referenceId) {
        updateQueryParams({ docId: null, fileName: null, refId: null });
        setFile([]);
        setPaymentDetails({ amount: '', utr: '', transactionDate: '' });
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Please upload the payment screenshot');
        setSubmitDisabled(false);
        return;
      }

      const res = await uploadTheWireTransferProof(payload);
      if (res) {
        setWireTransferUploadSuccess(true);
        closeWireTransferDrawer();
      }
      setSubmitDisabled(false);
    } 
  }, [selectedCompanyOrg, selectedInvestor, paymentDetails, params, updateQueryParams]);

  const removeUploadedFile = useCallback(() => {
    updateQueryParams({ docId: null, fileName: null, refId: null });
    setPaymentDetails({ amount: null, utr: null, transactionDate: null });
    setFile([]);
  }, []);

  const regesterAdminWTSSUploadMixpanelEvent = () => {
    Mixpanel.track(ADMIN_SCREENSHOT_UPLOAD, {
      Company_Name: inputCompanyName,
      Investor_Name: selectedInvestor.name,
      Email: AdminEmail,
    });
  }

  const closeWireTransferDrawer = useCallback(() => {
    updateQueryParams({
      wireTransferDrawer: null,
      refId: null,
      docId: null,
      fileName: null,
    });

    setInputCompanyName('');
    setSelectedInvestor({ id: '', name: '' });
    setSelectedCompanyOrg('');
    setPaymentDetails({ amount: '', utr: '', transactionDate: '' });
    setFile([]);
    setShowDropDown(false);
    setInvesteeList([]);
    setInvestorList(null);
  }, [updateQueryParams]);

  return {
    file,
    isLoading,
    investeeList,
    investorList,
    showDropDown,
    submitDisabled,
    paymentDetails,
    inputCompanyName,
    selectedInvestor,
    selectedCompanyOrg,
    isWireTransferDrawerOpen: params.get('wireTransferDrawer') === 'true',
    setShowDropDown,
    setPaymentDetails,
    handleDateChange,
    submitWireTransfer,
    removeUploadedFile,
    setInputCompanyName,
    startParsingTheDocs,
    handleInvestorChange,
    handleCompanySelection,
    closeWireTransferDrawer,
    regesterAdminWTSSUploadMixpanelEvent,
  };
};

export default useWireTransferDrawerLogic;