import React from 'react';
import styles from './PaymentDetails.module.scss';
import { Input } from '../../../../common/_custom/Input/Input';
import { getDecimalAndNumberFromInput } from '../../../../util/utility';
import { PaymentDetailsType } from '../../WireTransferDrawerTypes';

function PaymentDetails({
    paymentDetails,
    setPaymentDetails,
    handleDateChange,
}: {
    paymentDetails: PaymentDetailsType;
    setPaymentDetails: (value: PaymentDetailsType) => void;
    handleDateChange: (value: string) => void;
}) {

    return (
        <div className={styles.paymentInputContainer}>
            <div className={styles.selectDate}>
                <label>Submit Payment Details</label>
                <Input
                    label="Transaction Date"
                    id="transactionDate"
                    value={paymentDetails.transactionDate}
                    handleChange={handleDateChange}
                    type="date"
                    maxDateAllowed={new Date().toISOString().split('T')[0]}
                />
            </div>
            <div className={styles.UtrAndAmountDiv}>
                <Input
                    label="Amount"
                    id="amount"
                    value={paymentDetails.amount}
                    handleChange={(value) => setPaymentDetails({ ...paymentDetails, amount: getDecimalAndNumberFromInput(value) })}
                    type="text"
                />
                <Input
                    label="UTR"
                    id="utr"
                    value={paymentDetails.utr}
                    handleChange={(value) => setPaymentDetails({ ...paymentDetails, utr: value })}
                    type="text"
                />
            </div>
        </div>
    );
}

export default PaymentDetails;