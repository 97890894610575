import axios from "axios";
import { CustomRequest } from "../../common/common";
import { OLYMPUS_URL, POST, PUT, GET } from "../../common/Interceptor";
import { CreateNewRefundRequestData, GetRefundsParams } from "../../modules/Admin/pages/RecurRefundsPage/hooks/useRecurRefunds";

export const fetchRefunds = (filters?: GetRefundsParams) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}refund`,
        data: filters,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateRefund = (refundId: string, data: {
    status: string;
    updatedBy: string;
    transactionDate?: string;
    transactionReferenceId?: string;
    transactionReferenceIdentifier?: string;
}) => {
    const payload: CustomRequest = {
        method: PUT,
        url: `${OLYMPUS_URL}refund/update/${refundId}`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchLenders = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}investorOrganization/getInvestorOrganizationsHavingInvestorOrder`,
        params: {
            investeeOrgId: investeeOrgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const createNewRefund = (data: CreateNewRefundRequestData) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}refund/create`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};