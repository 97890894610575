import React, { useEffect } from 'react';
import styles from './RecurRefundsPage.module.scss';
import clearIcon from '../../../../assets/clear.svg';
import { useRecurRefunds } from './hooks/useRecurRefunds.tsx';
import RecurTable from '../../../../components/RecurTable/RecurTable.jsx';
import AdminHead from '../../components/AdminHead.js';
import PageLoader from '../../../../components/loader/PageLoader.js';
import RecurRefundsTableRow from './components/RecurRefundsTableRow/RecurRefundsTableRow.tsx';
import CreateRefundsModal from './components/CreateRefundsModal/CreateRefundsModal.tsx';
import { REFUND_STATUS_MAP } from './recurRefundsConsts.ts';
import { REFUND_PAGE_VIEWED } from '../../../../constants/mixpanel/AdminEvents.ts';
import { Mixpanel } from '../../../../util/mixpanel.js';

const RecurRefundsPage = () => {

    const {
        refundsData,
        isLoading,
        isPageLoading,
        filters,
        modalState,
        handleTextSearch,
        handleStatusFilter,
        handleCreateRefund,
        handleModalClose,
        handleModalSubmit,
        filterObjRep,
        clearFilters,
        isEmpty,
        handleScroll,
        isLoadingMore,
        fetchRefundsData,
        userEmail
    } = useRecurRefunds();

    useEffect(() => {
        Mixpanel.track(REFUND_PAGE_VIEWED,{
            User_Email: userEmail
        })
    }, [])

    const getHeaders = () => [
        {
            jsx: <span style={{width: '20px !important'}} >Company Name</span>,
        },
        {
            jsx: <span style={{width: '11%'}} >LenderName</span>,
        },
        {
            jsx: <span style={{width: '11%'}} >Amount</span>,
        },
        {
            jsx: <span style={{width: '11%'}} >Transaction Date</span>,
        },
        {
            jsx: <span style={{width: '11%'}} >UTR</span>,
        },
        {
            jsx: (
                <div style={{width: '11%'}} className={styles.statusHeader}>
                    <select 
                        value={filters.status?.[filters.status.length - 1] || ''} 
                        onChange={(e) => handleStatusFilter(e.target.value)}
                        className={styles.statusFilter}
                    >
                        <option value="">All</option>
                        {Object.values(REFUND_STATUS_MAP).map((status) => (
                            <option key={status} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                </div>
            ),
        },
        {
            jsx: <span style={{width: '11%'}} ></span>,
        },
    ];

    const renderRows = (data: any[]) => {
        if (isEmpty(data)) {
            return (
                <tbody>
                    <tr>
                        <td colSpan={7} className={styles.emptyMessage}>No refunds available.</td>
                    </tr>
                </tbody>
            );
        }
        
        return (
            <tbody>
                {data.map((refund, index) => (
                    <RecurRefundsTableRow
                        key={refund.id}
                        data={{
                            id: refund.id,
                            transactionDate: refund.transactionDate ? refund.transactionDate : '-',
                            companyName: refund.investeeOrganizationName,
                            lenderName: refund.investorOrganizationName,
                            amount: refund.amount,
                            utr: refund.transactionReferenceId,
                            status: refund.status
                        }}
                        index={index}
                        fetchRefundsData={fetchRefundsData}
                    />
                ))}
                {isLoadingMore && (
                    <tr>
                        <td colSpan={7} style={{ textAlign: 'center', padding: '1rem' }}>
                            Loading more...
                        </td>
                    </tr>
                )}
            </tbody>
        );
    };

    return (
        <div className="main-body">
            {isPageLoading && <PageLoader />}
            <AdminHead />
            <section className={styles.mainBody}>
                <div className={styles.headerSection}>
                    <div className={styles.title}>Refunds</div>
                </div>
                <div className="page-head-bottom-border"></div>
                <div>
                    <div className={styles.controls}>
                        <div className={styles.searchContainer}>
                            <span className={styles.searchIcon}>
                                <img
                                    alt=''
                                    src='../../../../../assets/search.png'
                                />
                            </span>
                            <input
                                value={filters.companyName}
                                onChange={(e) => handleTextSearch(e.target.value)}
                                type='text'
                                placeholder='Company Name'
                            />
                        </div>
                        <button onClick={handleCreateRefund}>
                            <span>
                                <img src={"https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/plus-white.svg"} alt='plus' />
                            </span>
                            <span>Create Refund</span>
                        </button>
                    </div>
                    {filterObjRep() && (
                        <div className={styles.filterTags}>
                            {filters.companyName && (
                                <span 
                                    className={styles.filterTag}
                                    onClick={(e) => clearFilters(e, false, 'companyName')}
                                >
                                    {filters.companyName}
                                    <img src={clearIcon} alt="clear" />
                                </span>
                            )}
                            {filters.status?.map((status, index) => (
                                <span 
                                    key={index}
                                    className={styles.filterTag}
                                    onClick={(e) => clearFilters(e, false, 'status', status)}
                                >
                                    {status}
                                    <img src={clearIcon} alt="clear" />
                                </span>
                            ))}
                            {filterObjRep() && (
                                <span 
                                    className={styles.clearAll}
                                    onClick={(e) => clearFilters(e, true)}
                                >
                                    Clear All Filter
                                </span>
                            )}
                        </div>
                    )}
                    <div 
                        className={styles.tableContainer} 
                        onScroll={handleScroll}
                    >
                        <RecurTable
                            headers={getHeaders()}
                            renderRows={renderRows(refundsData)}
                            changeColorOnHover={false}
                            loading={isLoading}
                            style={{}}
                            headerStyle={{}}
                            additionalRef={null}
                            onScroll={handleScroll}
                        />
                    </div>
                </div>
            </section>
            {modalState.isModalOpen && (
                <CreateRefundsModal
                    onClose={handleModalClose}
                    onSubmit={handleModalSubmit}
                />
            )}
        </div>
    );
}

export default RecurRefundsPage