import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { getEmail, getUserId } from '../../../../App/AppReducer';
import { getCompanyListMatchedWithPrefix } from '../../../../../store/wireTransferStore/wireTransferHelper';
import { getLenders } from '../../../../../store/RefundsStore/RefundsHelper';
import { CreateNewRefundRequestData } from './useRecurRefunds';
import { getRefundStatusKey } from '../../../../../util/utility';
import { Mixpanel } from '../../../../../util/mixpanel';
import { REFUND_CREATED } from '../../../../../constants/mixpanel/AdminEvents';
import { REFUND_REQUEST_BY_ADMIN, REFUND_STATUS_MAP, UTR } from '../recurRefundsConsts';

interface Option {
  value: string;
  label: string;
}

interface FormState {
  selectedCompanyId: string;
  companyName: string;
  selectedCompanyName: string;
  selectedLenderName: string;
  lenderName: string;
  lenderId: string;
  amount: number | '';
  status: string;
  transactionDate: string;
  utr: string;
}

interface CompanyResponse {
    id: string;
    displayName: string;
}

const initialFormState: FormState = {
  selectedCompanyId: '',
  companyName: '',
  selectedCompanyName: '',
  selectedLenderName: '',
  lenderName: '',
  lenderId: '',
  amount: '',
  status: REFUND_STATUS_MAP.REFUND_PENDING,
  transactionDate: '',
  utr: '',
};

export const useCreateRefunds = (onSubmit: (data: CreateNewRefundRequestData) => void) => {
  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [isDropdownOpen, setDropdownOpen] = useState({ company: false, lender: false });
  const [loading, setLoading] = useState({ companies: false, lenders: false });
  const [companies, setCompanies] = useState<Option[]>([]);
  const [lenders, setLenders] = useState<Option[]>([]);

  const userEmail = useSelector(getEmail)
  const userID = useSelector(getUserId);

  const fetchCompanies = async (query: string) => {
    if (query.length < 3) {
      setCompanies([]);
      setFormState(prev => ({
        ...prev,
        selectedCompanyId: '',
        companyName: query,
        lenderName: '',
        lenderId: '',
        selectedLenderName: '',
      }));
      setLenders([]);
      return;
    }

    setLoading(prev => ({ ...prev, companies: true }));
    try {
      await getCompanyListMatchedWithPrefix(query, {
        OnSuccess: (data) => {
          setCompanies(data.map((company: CompanyResponse) => ({
            id: company.id,
            displayName: company.displayName,
          })));
        },
        OnError: (error) => {
          setCompanies([]);
        },
      });
    } finally {
      setLoading(prev => ({ ...prev, companies: false }));
    }
  };

  const debouncedFetchCompanies = useCallback(debounce(fetchCompanies, 600), []);

  const fetchLenders = async (companyId: string) => {
    if (!companyId) return;
    
    setLoading(prev => ({ ...prev, lenders: true }));
    try {
      const lendersList = await getLenders(companyId);
      setLenders(lendersList.map((lender: any) => ({
        id: lender.id,
        displayName: lender.investorOrganizationName,
      })));
    } catch (error) {
      setLenders([]);
    } finally {
      setLoading(prev => ({ ...prev, lenders: false }));
    }
  };

  useEffect(() => {
    debouncedFetchCompanies(formState.companyName);
    return () => debouncedFetchCompanies.cancel();
  }, [formState.companyName, debouncedFetchCompanies]);

  useEffect(() => {
    fetchLenders(formState.selectedCompanyId);
  }, [formState.selectedCompanyId]);

  const updateFormState = (updates: Partial<FormState>) => {
    setFormState(prev => ({ ...prev, ...updates }));
  };

  const handleCompanySelection = (company: any) => {
    updateFormState({
      selectedCompanyId: company.id,
      companyName: company.displayName,
      selectedCompanyName: company.displayName,
      lenderName: '',
      lenderId: '',
      selectedLenderName: '',
    });
    setDropdownOpen(prev => ({ ...prev, company: false }));
  };

  const handleLenderSelection = (lender: any) => {
    updateFormState({
      lenderName: lender.displayName,
      lenderId: lender.id,
      selectedLenderName: lender.displayName,
    });
    setDropdownOpen(prev => ({ ...prev, lender: false }));
  };

  const isFormValid =
    formState.selectedCompanyId &&
    formState.lenderName &&
    (formState.selectedLenderName === formState.lenderName) &&
    formState.companyName &&
    (formState.companyName === formState.selectedCompanyName) &&
    formState.lenderId &&
    formState.amount !== '' &&
    formState.status &&
    (formState.status !== REFUND_STATUS_MAP.REFUND_APPROVED || (formState.transactionDate && formState.utr));

  const handleSubmit = () => {
      Mixpanel.track(REFUND_CREATED,{
        Company_Name: formState.companyName,
        Lender_Name: formState.lenderName,
        Status: formState.status,
        User_Email: userEmail
      })
    if (isFormValid) {
      onSubmit({
        amount: Number(formState.amount),
        status: getRefundStatusKey(formState.status)!,
        referenceId: `${userID}_${new Date()}`,
        referenceIdentifier: REFUND_REQUEST_BY_ADMIN,
        investeeOrganizationId: formState.selectedCompanyId,
        investorOrganizationId: formState.lenderId,
        statusUpdatedBy: userID,
        transactionDate: formState.status === REFUND_STATUS_MAP.REFUND_APPROVED ? formState.transactionDate : undefined,
        transactionReferenceId: formState.status === REFUND_STATUS_MAP.REFUND_APPROVED ? formState.utr : undefined,
        transactionReferenceIdentifier: formState.status === REFUND_STATUS_MAP.REFUND_APPROVED ? UTR : undefined,
      });
    }
  };

  return {
    formState,
    isDropdownOpen,
    loading,
    companies,
    lenders,
    isFormValid,
    setDropdownOpen,
    updateFormState,
    handleCompanySelection,
    handleLenderSelection,
    handleSubmit,
  };
};
