import { JAVA_API_STATUS } from "../../constants/commonConstants";
import { GENERIC_ERROR, SHOW_NOTIFICATION_STATUS } from "../../constants/enums";
import { showNotification } from "../../util/utility";
import { callParseDocument, fetchCompanyList, fetchInvestorWiseCollectionData, fetchParsedData, submitWireTransferRepaymentProof } from "./WireTrasferService";

export async function parsedDocumentForWireTransfer(payload: { docId: string; extractionConfigId: string }) {
    try {
        let response = await callParseDocument(payload);
        if (response?.status === JAVA_API_STATUS.SUCCESS_200
            && response?.data?.responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response.data?.data;
        } else {
            const errorMessage = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, errorMessage);
            return {};
        }
    } catch (error) {
        console.error('Error while parsing the uploaded documents ', error);
        return {};
    }
}

export async function getExtractedDataForWireTransfer(textractIdReference: string) {
    try {
        let response = await fetchParsedData(textractIdReference);
        if (response?.status === JAVA_API_STATUS.SUCCESS_200
            && response?.data?.responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response.data?.data;
        } else {
            const errorMessage = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, errorMessage);
            return {};
        }
    } catch (error) {
        console.error('Error while fetching the extracted data ', error);
        return {};
    }
}

export async function uploadTheWireTransferProof(data: Object) {
    try {
        let response = await submitWireTransferRepaymentProof(data);
        if (response?.status === JAVA_API_STATUS.SUCCESS_200
            && response?.data?.responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'Document uploaded successfully');
            return true;
        } else {
            const errorMessage = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, errorMessage);
            return false;
        }
    } catch (error) {
        console.error('Error while uploading the wire transfer proof ', error);
        return false;
    }
}

export async function getCompanyListMatchedWithPrefix(
    prefix: string,
    handlers: { OnSuccess: (data: any) => void; OnError: (error: any) => void }
) {
    try {
        const response = await fetchCompanyList(prefix);

        const { status, data } = response || {};
        const { responseData } = data || {};
        const { responseCode, responseMessage } = responseData || {};

        switch (status) {
            case JAVA_API_STATUS.SUCCESS_200:
                switch (responseCode) {
                    case JAVA_API_STATUS.SUCCESS:
                        handlers.OnSuccess(data?.data || []);
                        break;
                    default:
                        const errorMessage = responseMessage || GENERIC_ERROR;
                        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, errorMessage);
                        handlers.OnError(errorMessage);
                        break;
                }
                break;

            default:
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
                handlers.OnError(GENERIC_ERROR);
                break;
        }
    } catch (error) {
        console.error('Error while fetching the company list', error);
        handlers.OnError(error);
    }
}

export async function gettingAllInvestorWiseCollectionData(investeeOrgId: string) {
    try {
        let response = await fetchInvestorWiseCollectionData(investeeOrgId);
        if (response?.status === JAVA_API_STATUS.SUCCESS_200
            && response?.data?.responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response.data?.data;
        } else {
            const errorMessage = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, errorMessage);
            return [];
        }
    } catch (error) {
        console.error('Error while fetching the investor wise collection data ', error);
        return [];
    }
}