import React, {
  useState,
  // useEffect
} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import "../../../css/Admin.css";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logOutUser } from "../../App/AppActions";
import { getCheckIfUserHasRelevantPermissions } from "../../App/AppReducer";
import usePageTracking from "../../../util/PageViewEvent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    background: "grey",
    height: "6px",
  },
}));

function SimpleTabs(props) {
  const isUserHasEditAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["app:edit"]),
  );
  const isUserHasUWViewAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]),
  );
  const isUserHasCAEditAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["ca:edit"]),
  );
  const userHasClubAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["club-access:edit"]),
  );
  // for tracking page view 
    usePageTracking();

  const classes = useStyles();
  const [
    value,
    // setValue
  ] = useState(() => {
    if (props.location.pathname === "/admin/company/list") return 0;
    else if (props.location.pathname === "/admin/nachcollections/list")
      return 1;
    else if (
      props.location.pathname === "/admin/trades/list" &&
      (isUserHasEditAccess || isUserHasUWViewAccess)
    )
      return 2;
    else if (props.location.pathname === "/admin/checkout") return 3;
    else if (
      props.location.pathname === "/admin/orderRepayments/list" &&
      isUserHasEditAccess
    )
      return 4;
    else if (
      props.location.pathname === "/admin/withdrawal/list" &&
      isUserHasEditAccess
    )
      return 5;
    else if (
      props.location.pathname === "/admin/contracts/list" &&
      isUserHasEditAccess
    )
      return 6;
    else if (
      props.location.pathname === "/admin/swaps/list" &&
      isUserHasEditAccess
    )
      return 7;
    else if (
      props.location.pathname.match(/investor/g) &&
      (isUserHasEditAccess || isUserHasUWViewAccess)
    )
      return 9;
    else if (
      props.location.pathname === "/admin/company/list?tab=deals" &&
      (isUserHasEditAccess || isUserHasUWViewAccess)
    )
      return 10;
    else if (
      props.location.pathname === "/admin/autoinvest/list" &&
      isUserHasEditAccess
    )
      return 11;
    else if (props.location.pathname === "/admin/customer-requests/list")
      return isUserHasEditAccess ? 12 : isUserHasUWViewAccess ? 6 : 3;
    else if (
      props.location.pathname === "/admin/invoices/list" &&
      (isUserHasEditAccess || isUserHasUWViewAccess)
    )
      return 14;
    else if (
      props.location.pathname === "/admin/clubaccess/list" &&
      (isUserHasEditAccess || userHasClubAccess)
    )
      return 15;
    else if (props.location.pathname === "/admin/platformhelp/list") {
      return 16;
    } else if (props.location.pathname === "/admin/refunds/list") return 17;
    else return 0;
  });

  const handleChange = (event, newValue) => {
    if (newValue === 0) props.history.push("/admin/company/list");
    else if (newValue === 1) props.history.push("/admin/nachcollections/list");
    else if (newValue === 2 && (isUserHasEditAccess || isUserHasUWViewAccess))
      props.history.push("/admin/trades/list");
    // else if (newValue === 2 && !isUserHasEditAccess)
      // Need to show "Recur Checkout" page for admin.read@recur.club (zeus-side, read only user)
      // props.history.push("/admin/checkout");
    else if (newValue === 17) props.history.push("/admin/refunds/list");
    else if (newValue === 3 && (isUserHasEditAccess || isUserHasUWViewAccess))
      props.history.push("/admin/checkout");
    else if (newValue === 8 && (!isUserHasEditAccess || isUserHasUWViewAccess))
      // Need to show "Contact Us" page for admin.read@recur.cl(zeus-side, read only user)
      props.history.push("/admin/platformhelp/list");
    else if (newValue === 7 && isUserHasUWViewAccess)
      props.history.push("/admin/invoices/list");
    else if (newValue === 4 && isUserHasEditAccess)
      props.history.push("/admin/orderRepayments/list");
    else if (newValue === 5 && isUserHasEditAccess)
      props.history.push("/admin/withdrawal/list");
    else if (newValue === 6 && isUserHasEditAccess)
      props.history.push("/admin/contracts/list");
    else if (newValue === 7 && isUserHasEditAccess)
      props.history.push("/admin/swaps/list");
    else if (newValue === 9 && (isUserHasEditAccess || isUserHasUWViewAccess))
      props.history.push("/admin/investors/list");
    else if (newValue === 10 && (isUserHasEditAccess || isUserHasUWViewAccess))
      props.history.push("/admin/company/list?tab=deals");
    else if (newValue === 11 && isUserHasEditAccess)
      props.history.push("/admin/autoinvest/list");
    else if (newValue === 12 && (isUserHasEditAccess || isUserHasCAEditAccess))
      props.history.push("/admin/customer-requests/list");
    else if (newValue === 14 && (isUserHasEditAccess || isUserHasUWViewAccess))
      props.history.push("/admin/invoices/list");
    else if (newValue === 15 && (isUserHasEditAccess || userHasClubAccess))
      props.history.push("/admin/clubaccess/list");
    else if (newValue === 16 && (isUserHasEditAccess || isUserHasUWViewAccess))
      props.history.push("/admin/platformhelp/list");
  };
  
  

  const logOut = () => {
    props.dispatch(logOutUser());
  };
  return (
    <AppBar
      style={{
        borderTopRightRadius: "52px",
        position: "static",
        backgroundColor: "#1f1f1f",
      }}
      className="col-2 sidebar justify-content-between p-0"
    >
      {/* heit, blk-clr class */}
      <div className="">
        {/* className="admin-tag-common content-1" from span */}
        <img
          className="admin-recur-logo"
          alt=""
          src="/assets/recur-new-logo.svg"
        />
        <Tabs
          className="admin-sidebar"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.indicator,
          }}
        >
          {/* <Tab icon={<img alt="test avatar" src="/assets/recur.svg" />} {...a11yProps(0)} /> */}
          <Tab label="Companies" value={0} />
          <Tab label="Collections" value={1} />
          {(isUserHasEditAccess || isUserHasUWViewAccess) && (
            <Tab label="Orders Approval" value={2} />
          )}
          <Tab label="Refunds" value={17} />
          {/* <Tab label="Recur Checkout" value={3} /> */}
          {isUserHasEditAccess && <Tab label="Repayments" value={4} />}
          {isUserHasEditAccess && <Tab label="Withdrawals" value={5} />}
          {isUserHasEditAccess && <Tab label="Contracts" value={6} />}
          {isUserHasEditAccess && <Tab label="Swaps" value={7} />}
          {isUserHasEditAccess && <div className="line"></div>}
          {(isUserHasEditAccess || isUserHasUWViewAccess) && (
            <Tab label="Investors" value={9} />
          )}
          {(isUserHasEditAccess || isUserHasUWViewAccess) && (
            <Tab label="Deals" value={10} />
          )}
          {isUserHasEditAccess && <Tab label="Auto Invest" value={11} />}
          <Tab label="Customer Support" value={12} />
          <div className="line"></div>
          {(isUserHasEditAccess || isUserHasUWViewAccess) && (
            <Tab label="Invoices" value={14} />
          )}
          {(isUserHasEditAccess || userHasClubAccess) && (
            <Tab label="Club Access" value={15} />
          )}
          {<Tab label="Contact Us" value={16} />}
        </Tabs>
      </div>
      <button className="user-logo-btn">
        <span>
          <img className="user-logo mr-2" alt="" src="/assets/user-icon.png" />
          <span onClick={() => logOut()}>Logout</span>
        </span>
      </button>
    </AppBar>
  );
}
function mapStateToProps(state, props) {
  return {};
}
export default connect(mapStateToProps)(withRouter(SimpleTabs));
