import { debounce, isArray, isBoolean, isEmpty, isNil, isString } from "lodash";
import React, { useEffect, useState } from "react";
import {
  COLLECTION_METHODS_ARR,
  SORT_COL_DIRECTION,
} from "../../../../common/ENUM";
import RecurTable from "../../../../components/RecurTable/RecurTable";
import callApi from "../../../../util/apiCaller";
import {
  removeCommasFromString,
  showNotification,
} from "../../../../util/utility";
import AdminHead from "../../components/AdminHead";
import { CollectionsTableRow } from "./CollectionsTableRow";
import PageLoader from "../../../../../src/components/loader/PageLoader";
import { Tabs } from "./tabs";
import styles from "./NachCollectionsPage.module.scss";
import "react-datepicker/dist/react-datepicker.min.css";
import "../../../../css/ReactCustomLibrary.scss";

import DatePicker from "react-datepicker";
import ReactAutoComplete from "react-autocomplete";
import clearIcon from "../../../../assets/clear.svg";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import {InvestorFilters} from "./InvestorFilters/InvestorFilters";
import {getCollectionDetails} from "../../../../common/ApiHelpers";
import { AdvanceFilters } from "./AdvanceFilters/AdvanceFilters";
import { COLLECTION_VIEW_FILTER, COLLECTION_VIEW_FILTER_API_KEY } from "../../../../constants/enums";
import WireTransferButton from "../../../../components/WireTransferDrawer/components/WireTransferButton/WireTransferButton";
import WireTransferDrawer from "../../../../components/WireTransferDrawer/WireTransferDrawer";
import useQueryParams from "../../../../common/customHooks/useQueryParams";

export const CollectionsPage = ({ tab, setTab, beta, setBeta }) => {
  const {
    CollectionsHeaderRight,
    HeaderSubPart,
    Green,
    Grey,
    Blue,
    Red,
    ProgressTop,
    greenText,
    redText,
    CollectionProgressBar,
    GreyPart,
    GreenPart,
    header_input,
    filter_arr_wrapper,
    clear_all,
    RemainingOnlyFilter,
    CommonSpacingOntTop
  } = styles;

  const initialFilterObj = {
    companyName: [],
    // payment_method: [],
    payment_default_risk: [],
    owner: [],
    collectionDate: [],
    useNachFilter: [],
    investor:[],
    nonDpdCompanies: false,
    firstRepaymentOfCompany: false,
    showDeepRedCompanies: false,
    showRedCompanies: false
  };
  const curr_year = new Date().getFullYear();
  const curr_month = new Date().getMonth() + 1;
  let curr_date = 0;
  curr_month < 10
    ? (curr_date = `${curr_year}-0${curr_month}`)
    : (curr_date = `${curr_year}-${curr_month}`);

  const COMPANY_NAME = "companyName";
  const DISPLAY_NAME = "displayName";
  const PAYMENT_DEFAULT_RISK = "payment_default_risk";
  const PAYMENT_METHOD = "payment_method";
  const OWNER = "owner";

  const { params, updateQueryParams } = useQueryParams();
  const [metricsDate, setMetricsDate] = useState(curr_date);

  const [collectionData, setCollectionData] = useState([]);
  const [growthPartners, setGrowthPartners] = useState([]);
  const [riskTags, setRiskTags] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [investorFilterOptions,setInvestorFilterOptions] = useState([]);

  const [aggregationDetails, setAggregationDetails] = useState({});

  const [filterHeader, setFilterHeader] = useState("");
  const [type, setType] = useState("");
  const [sortConfig, setSortConfig] = useState(null);
  const [loader, setLoader] = useState(true);

  const [showRemainingAmtTxns, setShowRemainingAmtTxns] = useState(true);
  const [detailedRiskTags, setDetailedRiskTags] = useState();
  const [closeAllDropDowns, setCloseAllDropDowns] = useState(null);
  const [openWireTransferDrawer, setOpenWireTransferDrawer] = useState(false);
  const [wireTransferUploadSuccess, setWireTransferUploadSuccess] = useState(false);

  const [filterObj, setFilterObj] = useState({
    showRemainingAmtTxns,
    ...initialFilterObj,
  });


  const toggleRemainingFilter = (e) => {
    setShowRemainingAmtTxns(!showRemainingAmtTxns)
    setFilterObj({
      ...filterObj,
      showRemainingAmtTxns: !showRemainingAmtTxns
    })
  }


    const filterObjRep = () => {
      for(const value of Object.values(COLLECTION_VIEW_FILTER_API_KEY)) {
        if(filterObj[value] === true) return true;
      }

      return !isEmpty(Object.values(filterObj)
      .flat()
      .filter((t) => isString(t)));
    }

  const filterObjEnteries = () => {
    let result = [];
    for (const [key, value] of Object.entries(filterObj)) {
      if (Array.isArray(value)) {
        value.forEach((v) => result.push({ key, value: v }));
      } else if(isBoolean(value) && value) {
        for (const [k, v] of Object.entries(COLLECTION_VIEW_FILTER_API_KEY)) {
          if (v === key) {
            result.push({key: key, value: COLLECTION_VIEW_FILTER[k]})
            break;
          }
        }
      }
    }
    return result;
  };

  useEffect(() => {
    document.addEventListener('click', unset)
    return () => {
      document.removeEventListener('click', unset)
    }
  }, []);

  const unset = () => {
    setCloseAllDropDowns(null);
  }

  const intitalMenuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 5px",
    fontSize: "12px",
    fontWeight: "normal",
    position: "fixed",
    overflow: "auto",
    maxHeight: "50%",
    zIndex: 2,
    fontFamily: "Gilroy-SemiBold",
    cursor: "pointer",
  };

  const shouldRender = (item, value) =>
    item?.label?.toLowerCase().indexOf(value.toLowerCase()) > -1;

  const [query, setQuery] = useState({
    company: "",
    risk: "",
    method: "",
    owner: "",
    collectionDate:""
  });

  if (sortConfig !== null) {
    if (sortConfig.direction === SORT_COL_DIRECTION.asc) {
      collectionData.sort((a, b) => {
        if (sortConfig.key === "remainingCollectionAmt") {
          return (
            Number(removeCommasFromString(a[sortConfig.key])) -
            Number(removeCommasFromString(b[sortConfig.key]))
          );
        }
        const date1 = new Date(a[sortConfig.key]);
        const date2 = new Date(b[sortConfig.key]);
        return date1 - date2;
      });
    } else {
      collectionData.sort((a, b) => {
        if (sortConfig.key === "remainingCollectionAmt") {
          return (
            Number(removeCommasFromString(b[sortConfig.key])) -
            Number(removeCommasFromString(a[sortConfig.key]))
          );
        }
        const date1 = new Date(a[sortConfig.key]);
        const date2 = new Date(b[sortConfig.key]);
        return date2 - date1;
      });
    }
  }

  const getGreyStyle = () => {
    const collectionAmt = removeCommasFromString(
      aggregationDetails?.collectionAmount
    );
    const gapAmt = removeCommasFromString(aggregationDetails?.neftAmount);
    const greyAmount = Number(collectionAmt) - Number(gapAmt);
    const greyPerc = greyAmount / collectionAmt;
    const totalWidth = 260;
    const greyPx = greyPerc * totalWidth;
    return {
      width: `${greyPx}px`,
      color: "#EEEEED",
    };
  };

  const getGreenStyle = () => {
    const collectedAmt = removeCommasFromString(
      aggregationDetails?.collectedAmount
    );
    const collectionAmt = removeCommasFromString(
      aggregationDetails?.collectionAmount
    );
    const greenPerc = Number(collectedAmt) / Number(collectionAmt);
    const totalWidth = 260;
    const greenPx = greenPerc * totalWidth;
    return {
      width: `${greenPx}px`,
      color: "#03AA00",
    };
  };

  const changeQuery = (keyName, keyValue) => {
    setQuery((prevQuery) => {
      const newQuery = { ...prevQuery };
      newQuery[keyName] = keyValue;
      return newQuery;
    });
    setType(keyValue.toLowerCase());
  };

  useEffect(() => {
    if (filterHeader === COMPANY_NAME)
      setQuery((prevQuery) => {
        const newQuery = { ...prevQuery };
        newQuery.risk = "";
        newQuery.method = "";
        newQuery.owner = "";
        return newQuery;
      });
    if (filterHeader === PAYMENT_DEFAULT_RISK)
      setQuery((prevQuery) => {
        const newQuery = { ...prevQuery };
        newQuery.company = "";
        newQuery.method = "";
        newQuery.owner = "";
        return newQuery;
      });
    if (filterHeader === PAYMENT_METHOD)
      setQuery((prevQuery) => {
        const newQuery = { ...prevQuery };
        newQuery.company = "";
        newQuery.risk = "";
        newQuery.owner = "";
        return newQuery;
      });
    if (filterHeader === OWNER)
      setQuery((prevQuery) => {
        const newQuery = { ...prevQuery };
        newQuery.company = "";
        newQuery.risk = "";
        newQuery.method = "";
        return newQuery;
      });
    setType("");
  }, [filterHeader]);

  const requestSort = (key) => {
    let direction = SORT_COL_DIRECTION.asc;
    if (
      sortConfig?.key === key &&
      sortConfig?.direction === SORT_COL_DIRECTION.asc
    ) {
      direction = SORT_COL_DIRECTION.dsc;
    }
    setSortConfig({ key, direction });
  };

  const onOptionSelect = (value, filterType) => {
    if (!value || filterObj[filterType]?.includes(value)) return;
    setFilterObj({
      ...filterObj,
      [filterType]: [...filterObj[filterType], value],
    });
  };

  const clearFilters = (e, allClear = false, filterType, filterValue) => {
    e.stopPropagation();
    if (allClear) {
      setFilterObj({ ...initialFilterObj, ...{ showRemainingAmtTxns: true } });
      return;
    } else {
      
      for (const [key, value] of Object.entries(COLLECTION_VIEW_FILTER_API_KEY)) {
        if (value === filterType) {
          setFilterObj({
            ...filterObj,
            [value]: false
          })
          return;
        }
      }

      setFilterObj({
        ...filterObj,
        [filterType]: filterObj[filterType].filter((f) => f !== filterValue),
      });
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      if (!isEmpty(filterObj)) await fetchCollectionData();
    };
    fetchDetails();
  }, [metricsDate, filterObj, beta]);

  // Fetch collection data on wire transfer upload success
  useEffect(() => {
    const fetchLatestCollectionData = async () => {
      await fetchCollectionData();
    }
    if(wireTransferUploadSuccess) {
      fetchLatestCollectionData();
      setWireTransferUploadSuccess(false);
    }
  }, [wireTransferUploadSuccess])

  useEffect(() => {
    if (params.get("wireTransferDrawer") === "true") {
      updateQueryParams({
        wireTransferDrawer: null,
        refId: null,
        docId: null,
        fileName: null,
      });
    }
  }, []);

  const fetchCollectionData = async () => {
    setLoader(true);
    const data={
      collectionDate: metricsDate,
        filters: filterObj,
      pageNumber: 1, //@todo: hardcoding this value for now will change it when the api will support pagination
    }
    const res = await getCollectionDetails(data, beta);
        setLoader(false);
        if (res) {
          setCollectionData(res?.data?.collectionsDetails);
          setGrowthPartners(res?.data?.growthPartners);
          setRiskTags(res?.data?.riskTags);
          setDetailedRiskTags(res.data.detailedRepaymentRiskData)
          setDateOptions(res?.data?.dateOptions);
          setAggregationDetails(res?.data?.aggerationDetails);
          setInvestorFilterOptions(res.data.investorOptions);
        } else {
          const message = res?.error ?? "Failed to fetch Collection Data";
          showNotification("Error", message);
        }
}

  const renderRows = () => {
        if (!isEmpty(type)) {
      const filteredCollectionData = collectionData?.filter((row) => {
        if (filterHeader === COMPANY_NAME && isEmpty(row?.filterHeader)) {
          return row?.[DISPLAY_NAME]?.toLowerCase().includes(type) || row?.[COMPANY_NAME]?.toLowerCase().includes(type);;
        }
        return row?.[filterHeader]?.toLowerCase().includes(type);
      });
      console.log("filteredCollectionData ", filteredCollectionData);
      return (
        <tbody>
          {!isEmpty(collectionData) &&
            filteredCollectionData.map((item, index) => (
              <CollectionsTableRow
                key={`${item?.investeeOrgId}_${item?.collectionDate}`}
                _id={`${item?.investeeOrgId}_${item?.collectionDate}`}
                item={item}
                growthPartners={growthPartners}
                needToShowInvestorBifurcation={true}
                riskTags={riskTags}
                setCollectionData={setCollectionData}
                collectionData={collectionData}
                detailedRiskTags={detailedRiskTags?.[item?.investeeOrgId]}
                metricsDate={metricsDate}
                type={type}
                closeAllDropDowns={closeAllDropDowns}
                setCloseAllDropDowns={setCloseAllDropDowns}
                index={index}
                fetchCollectionData={fetchCollectionData}
                beta={beta}
                changeInRiskFlag={item?.changeInRiskFlag}
              />
            ))}
        </tbody>
      );
    }
    return (
      <tbody>
        {!isEmpty(collectionData) &&
          collectionData.map((item, index) => (
            <CollectionsTableRow
              key={`${item?.investeeOrgId}_${item?.collectionDate}`}
              _id={`${item?.investeeOrgId}_${item?.collectionDate}`}
              item={item}
              growthPartners={growthPartners}
              riskTags={riskTags}
              detailedRiskTags={detailedRiskTags?.[item?.investeeOrgId]}
              setCollectionData={setCollectionData}
              collectionData={collectionData}
              needToShowInvestorBifurcation={true}
              type={type}
              metricsDate={metricsDate}
              closeAllDropDowns={closeAllDropDowns}
              setCloseAllDropDowns={setCloseAllDropDowns}
              index={index}
              fetchCollectionData={fetchCollectionData}
              beta={beta}
              changeInRiskFlag={item?.changeInRiskFlag}
            />
          ))}
      </tbody>
    );
  };

  const getHeaders = () => [
    {
      jsx: (
        <span className={header_input}>
          <ReactAutoComplete
            inputProps={{
              type: "text",
              name: "collectionDate",
              placeholder: "Date",
            }}
            items={dateOptions.map((i) => ({ label: i, id: i }))}
            value={query?.collectionDate}
            getItemValue={(item) => item.label}
            shouldItemRender={shouldRender}
            onChange={(e) => {
              setQuery({ collectionDate: e.target.value });
            }}
            onSelect={(value, item) => {
              onOptionSelect(value, "collectionDate");
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "#2F8FFF" : "white",
                }}
              >
                {item.label}
              </div>
            )}
            menuStyle={{ ...intitalMenuStyle,  "flex-direction": "column","align-items": "center","display": "flex"}}
          />
        </span>
      ),
    },
    {
      jsx: (
        <span>
          <input
            type="text"
            placeholder="Company"
            value={query.company}
            onClick={() => setFilterHeader(COMPANY_NAME)}
            onChange={(e) => changeQuery("company", e.target.value)}
            style={{ border: "none" }}
          />
        </span>
      ),
    },
    {
      jsx: <span>Collection Overview</span>,
    },
    {
      jsx: <span className={header_input}>Remaining
         <button
            onClick={() => requestSort("remainingCollectionAmt")}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
      </span>,
    },
    {
      jsx: (
        <span className={header_input}>
          <ReactAutoComplete
            inputProps={{
              type: "text",
              name: "payment_default_risk",
              placeholder: "Risk Tags",
            }}
            items={Object.values(riskTags || []).map((i) => ({ label: i, id: i }))}
            value={query?.risk}
            getItemValue={(item) => item.label}
            shouldItemRender={shouldRender}
            onChange={(e) => {
              setQuery({ risk: e.target.value });
            }}
            onSelect={(value) => {
              onOptionSelect(value, "payment_default_risk");
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "#2F8FFF" : "white",
                }}
              >
                {item.label}
              </div>
            )}
            menuStyle={{ ...intitalMenuStyle }}
          />
        </span>
      ),
    },
    {
      jsx: (
        <span className={header_input}>
          <ReactAutoComplete
            inputProps={{
              type: "text",
              name: "payment_method",
              placeholder: "Use NACH",
            }}
            items={COLLECTION_METHODS_ARR.map((i) => ({ label: i, id: i }))}
            value={query?.method}
            getItemValue={(item) => item.label}
            shouldItemRender={shouldRender}
            onChange={(e) => {
              setQuery({ method: e.target.value });
            }}
            onSelect={(value) => {
              onOptionSelect(value, "useNachFilter");
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted
                    ? "#2F8FFF"
                    : "white" /* margin: "0 -5px" */,
                }}
              >
                {item.label}
              </div>
            )}
            menuStyle={{ ...intitalMenuStyle }}
          />
        </span>
      ),
    },
    {
      jsx: (
        <span className={header_input}>
          <ReactAutoComplete
            inputProps={{
              type: "text",
              name: "owner",
              placeholder: "Owner",
            }}
            items={growthPartners.map((i) => ({
              label: i?.agent_name,
              id: i?.agent_name,
            }))}
            value={query?.owner}
            getItemValue={(item) => item.label}
            shouldItemRender={shouldRender}
            onChange={(e) => {
              setQuery({ owner: e.target.value });
            }}
            onSelect={(value) => {
              onOptionSelect(value, "owner");
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "#2F8FFF" : "white"
                }}
              >
                {item.label}
              </div>
            )}
            menuStyle={{ ...intitalMenuStyle }}
          />
        </span>
      ),
    },
    {
      jsx: <span>Comments</span>,
    },
  ];
  return (
    <div className="main-body">
      <AdminHead />
      {loader ? (
        <PageLoader />
      ) : (
        <section className="w-100">
          <div
            className="d-flex justify-content-between align-items-center has-search p-3"
            style={{ height: "100px" }}
          >
            <div className="fs21 fw600">Collections</div>
            {/**should be breaked into another component */}
            <div className={CollectionsHeaderRight}>
              <div className="mr-2">
                <InvestorFilters
                  filterObj={filterObj}
                  investorFilterOptions={investorFilterOptions}
                  setFilterObj={setFilterObj}
                />
              </div>
              {/* https://reactdatepicker.com/#example-month-picker */}
              <div className="custom-DatePicker">
                <DatePicker
                  selected={new Date(metricsDate)}
                  onChange={(date) => {
                    // Create date in UTC using Date.UTC()
                    const utcDate = new Date(Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      1,
                      0,
                      0,
                      0
                    ));
                    setMetricsDate(utcDate);
                  }}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className={HeaderSubPart}>
                <div>Complete</div>
                <div>
                  <span className={Green}>{aggregationDetails?.completed}</span>
                  <span className={Grey}>
                    /{aggregationDetails?.totalTransactions}
                  </span>
                </div>
              </div>
              <div className={HeaderSubPart}>
                <div>Nach</div>
                <div className={Blue}>{aggregationDetails?.nachCount}</div>
              </div>
              <div className={HeaderSubPart}>
                <div>NEFT</div>
                <div className={Red}>{aggregationDetails?.neftCount}</div>
              </div>
              <div className="mx-3">
                <div className={ProgressTop}>
                  <span>
                    <span className={greenText}>
                      ₹ {aggregationDetails?.collectedAmount}
                    </span>
                    / ₹ {aggregationDetails?.collectionAmount}
                  </span>
                  {aggregationDetails?.neftAmount != "0" && (
                    <span className={redText}>
                      ₹ {aggregationDetails?.neftAmount} NEFT
                    </span>
                  )}
                </div>
                <div className={CollectionProgressBar}>
                  <div className={GreyPart} style={getGreyStyle()}>
                    <div className={GreenPart} style={getGreenStyle()}></div>
                  </div>
                </div>
                {/* <div className={ProgressBottom}>100% Success (5)</div> */}
              </div>
              <div className={HeaderSubPart}>
                <div>Remaining</div>
                <div>₹ {aggregationDetails?.remainingCollectionAmt}</div>
              </div>
              <div>
                <AdvanceFilters
                  filterObj={filterObj}
                  advanceFiltersOptions={COLLECTION_VIEW_FILTER}
                  setFilterObj={setFilterObj}
                />
              </div>
              <div>
                <div className={`${RemainingOnlyFilter} DocumentVaultSwitch`}>
                  <span>Remaining Only</span>
                  <ToggleButton
                    checked={showRemainingAmtTxns}
                    onChange={toggleRemainingFilter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="page-head-bottom-border"></div>
          <Tabs
            tab={tab}
            setTab={setTab}
            showRemainingAmtTxns={showRemainingAmtTxns}
            toggleRemainingFilter={toggleRemainingFilter}
          />
          <div className="d-flex justify-content-end">
            <WireTransferButton
              pageName={"Collections View page"}
              style={{height: "44px", margin: "16px 36px 0 0" }}
              setOpenWireTransferDrawer={setOpenWireTransferDrawer}
            />
          </div> 
          <div className={CommonSpacingOntTop}>
            <div className={filter_arr_wrapper}>
              {filterObjEnteries().map((o) => (
                <span onClick={(e) => clearFilters(e, false, o?.key, o?.value)}>
                  {o?.value} <img src={clearIcon} alt="clear icon" />
                </span>
              ))}
              {filterObjRep() && (
                <span
                  className={clear_all}
                  onClick={(e) => clearFilters(e, true)}
                >
                  Clear All Filter
                </span>
              )}
            </div>
            <RecurTable headers={getHeaders()} renderRows={renderRows()} />
          </div>  
          {openWireTransferDrawer && <WireTransferDrawer setWireTransferUploadSuccess={setWireTransferUploadSuccess} />}
        </section>
      )}
    </div>
  );
};