import React, { useState } from 'react';
import styles from './RecurRefundsTableRow.module.scss';
import { useSelector } from 'react-redux';

import { getEmail, getUserId } from '../../../../../App/AppReducer';
import { updateRefundData } from '../../../../../../store/RefundsStore/RefundsHelper.ts';
import { showNotification } from '../../../../../../util/utility.js';
import { SHOW_NOTIFICATION_STATUS } from '../../../../../../constants/enums.js';
import { REFUND_APPROVED, REFUND_INITIATED, REFUND_PENDING, REFUND_REJECTED, REFUND_STATUS_MAP, UTR } from '../../recurRefundsConsts.ts';
import { REFUND_STATUS_UPDATED } from '../../../../../../constants/mixpanel/AdminEvents.ts';
import { Mixpanel } from '../../../../../../util/mixpanel.js';

type RefundStatus = 'REFUND_PENDING' | 'REFUND_INITIATED' | 'REFUND_APPROVED' | 'REFUND_REJECTED';

interface RefundTableData {
    transactionDate: string;
    companyName: string;
    lenderName: string;
    amount: string | number;
    utr?: string;
    status: RefundStatus;
}

interface Props {
    data: RefundTableData & { id: string };
    index: number;
    fetchRefundsData: (resetData: boolean, isUpdated: boolean) => void;
}

export interface UpdateRefundDataParams {
    refundId: string;
    status: RefundStatus;
    updatedBy: string;
    transactionDate?: string;
    transactionReferenceId?: string;
    transactionReferenceIdentifier?: 'UTR';
}

const ASSETS = {
    SAVE: '/assets/save-icon1.svg',
    CANCEL: '/assets/clear-red.svg',
    EDIT: '/assets/editIcon.svg'
} as const;

interface EditingState {
    isEditing: boolean;
    isSaving: boolean;
}

const RecurRefundsTableRow: React.FC<Props> = ({ data, index, fetchRefundsData }) => {
    const [editingState, setEditingState] = useState<EditingState>({
        isEditing: false,
        isSaving: false
    });
    const [editedData, setEditedData] = useState({
        transactionDate: data?.transactionDate,
        utr: data?.utr || '',
        status: data?.status
    });
    
    const statusOptions = Object.values(REFUND_STATUS_MAP);
    const isEditable = data.status === REFUND_PENDING || data.status === REFUND_INITIATED;
    
    const userID = useSelector(getUserId);
    const userEmail = useSelector(getEmail)

    const handleEdit = () => {
        setEditingState(prev => ({ ...prev, isEditing: true }));
    };

    const handleSave = async () => {
        setEditingState(prev => ({ ...prev, isSaving: true }));
        try {
            Mixpanel.track(REFUND_STATUS_UPDATED,{
                Company_Name: data?.companyName,
                Lender_Name: data?.lenderName,
                User_Email: userEmail,
                Status: editedData.status,
            })
            const mappedStatus = REFUND_STATUS_MAP[editedData.status as keyof typeof REFUND_STATUS_MAP];
            
            if (mappedStatus === REFUND_STATUS_MAP.REFUND_APPROVED && (!editedData.utr || !editedData.transactionDate)) {
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'UTR and Transaction Date are mandatory for Approved status');
                return;
            }

            const isApproved = editedData.status === REFUND_APPROVED
            const success = await updateRefundData({
                refundId: data.id,
                status: editedData.status,
                updatedBy: userID,
                transactionDate: isApproved ? editedData.transactionDate : undefined,
                transactionReferenceId: isApproved ? editedData.utr : undefined,
                transactionReferenceIdentifier: editedData.utr ? UTR : undefined
            });

            if(success){
                fetchRefundsData(true, true);
                setEditingState({ isEditing: false, isSaving: false });
            }
        } catch (error) {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Failed to update refund status');
        } finally {
            setEditingState(prev => ({ ...prev, isSaving: false }));
        }
    };

    const handleCancel = () => {
        setEditedData({
            transactionDate: data?.transactionDate,
            utr: data?.utr || '',
            status: data?.status
        });
        setEditingState({ isEditing: false, isSaving: false });
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedStatus = Object.keys(REFUND_STATUS_MAP).find(
            key => REFUND_STATUS_MAP[key as keyof typeof REFUND_STATUS_MAP] === event.target.value
        ) as RefundStatus;
        
        setEditedData(prev => ({
            ...prev,
            status: selectedStatus,
            utr: selectedStatus !== REFUND_APPROVED ? '' : prev.utr,
            transactionDate: selectedStatus !== REFUND_APPROVED ? '' : prev.transactionDate
        }));
    };

    const getTdsStatusColors = (status: string) => {
        let color = '';
        let banner = '';
        switch (status) {
            case REFUND_PENDING:
                color = '#cfa983';
                banner = '#fdf6ef';
                break;
            case REFUND_INITIATED:
                color = '#D6BB0A';
                banner = '#FFFAD7';
                break;
            case REFUND_APPROVED:
                color = '#49A71B';
                banner = '#E5FCDE';
                break;
            case REFUND_REJECTED:
                color = '#DF666A';
                banner = '#FDF0EC';
                break;
        }
        return {
            color,
            banner,
        };
    };

    const isFieldsEnabled = REFUND_STATUS_MAP[editedData.status as keyof typeof REFUND_STATUS_MAP] === 'Approved';

    return (
        <tr className={styles.tableRow}>
            <td className={styles.companyName}>{data.companyName}</td>
            <td className={styles.lenderName}>{data.lenderName}</td>
            <td className={styles.amount}>{data.amount}</td>
            <td className={styles.transactionDate}>
                {editingState.isEditing ? (
                    <input
                        type="date"
                        value={editedData.transactionDate}
                        onChange={(e) => setEditedData({
                            ...editedData,
                            transactionDate: e.target.value
                        })}
                        max={new Date().toISOString().split('T')[0]}
                        disabled={!isFieldsEnabled}
                    />
                ) : (
                    data.transactionDate
                )}
            </td>
            <td className={styles.utrInputContainer}>
                {editingState.isEditing ? (
                    <input
                        type="text"
                        value={editedData.utr}
                        className={styles.utrInput}
                        onChange={(e) => setEditedData({
                            ...editedData,
                            utr: e.target.value
                        })}
                        disabled={!isFieldsEnabled}
                    />
                ) : (
                    data.utr || '-'
                )}
            </td>
            <td className={styles.statusInputContainer}>
                {editingState.isEditing ? (
                    <select
                        value={REFUND_STATUS_MAP[editedData.status as keyof typeof REFUND_STATUS_MAP] || editedData.status}
                        onChange={handleStatusChange}
                        className={styles.statusSelect}
                    >
                        <option value="">Select</option>
                        {statusOptions.filter(status => {
                            const currentStatus = REFUND_STATUS_MAP[data.status as keyof typeof REFUND_STATUS_MAP];
                            if (currentStatus === REFUND_STATUS_MAP.REFUND_PENDING) {
                                return status === REFUND_STATUS_MAP.REFUND_INITIATED || status === REFUND_STATUS_MAP.REFUND_REJECTED;
                            }
                            if (currentStatus === REFUND_STATUS_MAP.REFUND_INITIATED) {
                                return status === REFUND_STATUS_MAP.REFUND_APPROVED || status === REFUND_STATUS_MAP.REFUND_REJECTED;
                            }
                            return true;
                        }).map((status) => (
                            <option key={status} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                ) : (
                    <div className={styles.statusContainer} style={{ backgroundColor: getTdsStatusColors(data.status).banner, color: getTdsStatusColors(data.status).color }}>
                        {REFUND_STATUS_MAP[data.status as keyof typeof REFUND_STATUS_MAP] || data.status}
                    </div>
                )}
            </td>
            <td className={styles.actionButtonsContainer}>
                {editingState.isEditing ? (
                    <div className={styles.actionButtons}>
                        <button onClick={handleSave} disabled={editingState.isSaving}>
                          <img src={ASSETS.SAVE} alt="save" />
                        </button>
                        <button onClick={handleCancel} disabled={editingState.isSaving}>
                          <img src={ASSETS.CANCEL} alt="cancel" />
                        </button>
                    </div>
                ) : (
                    isEditable && <button onClick={handleEdit}>
                      <img src={ASSETS.EDIT} alt="edit" />
                    </button>
                )}
            </td>
        </tr>
    );
};

export default RecurRefundsTableRow;