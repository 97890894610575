import styles from './WireTransferDrawer.module.scss';
import { Drawer } from '@material-ui/core';
import useWireTransferDrawerLogic from './useWireTransferDrawerLogic';
import crossButton from '../../assets/cross-button.svg';
import RecordWireTransfer from './components/RecordWireTransfer/RecordWireTransfer';
import clsx from 'clsx';
import Button from '../../common/_custom/Button/Button';

function WireTransferDrawer({
  setWireTransferUploadSuccess,
}: { setWireTransferUploadSuccess: (value: boolean) => void }) {
  const {
    file,
    isLoading,
    investeeList,
    investorList,
    showDropDown,
    submitDisabled,
    paymentDetails,
    inputCompanyName,
    selectedInvestor,
    selectedCompanyOrg,
    isWireTransferDrawerOpen,
    setShowDropDown,
    handleDateChange,
    setPaymentDetails,
    submitWireTransfer,
    removeUploadedFile,
    setInputCompanyName,
    startParsingTheDocs,
    handleInvestorChange,
    handleCompanySelection,
    closeWireTransferDrawer,
    regesterAdminWTSSUploadMixpanelEvent,
  } = useWireTransferDrawerLogic({ setWireTransferUploadSuccess });

  return (
    <Drawer
      anchor='right'
      open={isWireTransferDrawerOpen}
      onClose={closeWireTransferDrawer}
      classes={{
        paper: clsx(styles.drawerPaper, {
          [styles.drawerPaperClose]: !isWireTransferDrawerOpen,
        }),
      }}
    >
      <div className={styles.wireTransferDrawerContainer}>
        <div className={styles.wireTransferDrawerHeader}>
          <div className={styles.headerComponent}>
            <span className={styles.headerText}>Record Wire Transfer</span>
            <img src={crossButton} onClick={() => closeWireTransferDrawer()} alt={''} />
          </div>
          <div className={styles.wireTransferBox}>
            <RecordWireTransfer
              file={file}
              isLoading={isLoading}
              investeeList={investeeList}
              showDropDown={showDropDown}
              investorList={investorList ?? { investorMap: {} }}
              paymentDetails={paymentDetails}
              inputCompanyName={inputCompanyName}
              selectedInvestor={selectedInvestor}
              selectedCompanyOrg={selectedCompanyOrg}
              setShowDropDown={setShowDropDown}
              handleDateChange={handleDateChange}
              setPaymentDetails={setPaymentDetails}
              removeUploadedFile={removeUploadedFile}
              setInputCompanyName={setInputCompanyName}
              startParsingTheDocs={startParsingTheDocs}
              handleInvestorChange={handleInvestorChange}
              handleCompanySelection={handleCompanySelection}
              uploadScreenShotMixPanelEvent={regesterAdminWTSSUploadMixpanelEvent}
            />
          </div>
        </div>
        {/* Footer */}
        <div className={styles.wireTransferDrawerFooter}>
          <Button
            text={'Cancel'}
            onClick={closeWireTransferDrawer}
            variant='whitePurple'
            size='small'
            style={{ width: "150px", padding: "10px 0px" }}
          />
          <Button
            text={'Submit'}
            onClick={() => submitWireTransfer()}
            variant='blue'
            size='small'
            style={{ width: "150px", padding: "10px 0px" }}
            disabled={submitDisabled}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default WireTransferDrawer;