import { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce, isEmpty } from 'lodash';
import { SHOW_NOTIFICATION_STATUS } from '../../../../../constants/enums';
import { showNotification } from '../../../../../util/utility';
import { createNewRefundRequest, getRefunds } from '../../../../../store/RefundsStore/RefundsHelper';
import { REFUND_STATUS_MAP } from '../recurRefundsConsts';
import { Mixpanel } from '../../../../../util/mixpanel';
import { useSelector } from 'react-redux';
import { getEmail } from '../../../../App/AppReducer';
import { CREATE_REFUND_CLICKED } from '../../../../../constants/mixpanel/AdminEvents';

interface Refund {
    amount: number;
    investeeOrganizationId: string;
    investorOrganizationId: string;
    investeeOrganizationName: string;
    investorOrganizationName: string;
    status: string;
    createdAt: string;
    transactionReferenceId?: string;
}

interface Filters {
    companyName: string;
    status: string[] | null;
    page: number;
}

export type CreateNewRefundRequestData = {
    amount: number;
    investeeOrganizationId: string;
    investorOrganizationId: string;
    status: string;
    referenceId: string;
    referenceIdentifier?: string;
    statusUpdatedBy: string;
    transactionDate?: string;
    transactionReferenceId?: string;
    transactionReferenceIdentifier?: string;
}

export interface GetRefundsParams {
    statuses?: string[];
    companyName?: string;
    page: number;
    size: number;
}

export const useRecurRefunds = () => {
    const [refundsData, setRefundsData] = useState<Refund[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [filters, setFilters] = useState<Filters>({
        companyName: '',
        status: null,
        page: 0,
    });
    const [modalState, setModalState] = useState({
        isModalOpen: false
    });
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const userEmail = useSelector(getEmail)
    const defaultSize = 20;

    // Fetch refunds data logic
    const fetchRefundsData = useCallback(async (resetData: boolean = false, isUpdated: boolean = false) => {
        setIsLoading(true);
        try {
            const mappedStatuses = filters.status ? 
                filters.status.map(status => 
                    Object.entries(REFUND_STATUS_MAP).find(([_, value]) => value === status)?.[0]
                ).filter((status): status is string => status !== undefined) : 
                [];
            const page = isUpdated ? 0 : filters.page;
            const size = isUpdated ? defaultSize*(filters.page + 1) : defaultSize;

            const data = await getRefunds({
                statuses: mappedStatuses,
                companyName: filters.companyName,
                page,
                size
            });
            
            setRefundsData(prev => resetData ? data : [...prev, ...data]);
            setHasMore(data.length >= size);
            if(isUpdated && data.length > size){
                setFilters(prev => ({ ...prev, page: Math.floor(data.length/defaultSize) }));
            }
            setIsLoadingMore(false);
        } catch (error) {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Error fetching refunds");
            setIsLoadingMore(false);
        } finally {
            setIsLoading(false);
        }
    }, [filters]);
 
    const debouncedFetchRefunds = useMemo(
        () => debounce(fetchRefundsData, 800),
        [fetchRefundsData]
    );

    const debouncedIncrementPage = useMemo(
        () => debounce((currentPage: number) => {
            setFilters(prev => ({ ...prev, page: currentPage + 1 }));
        }, 700),
        []
    );

    
    useEffect(() => {
        // Reset data when filters change (except page)
        const shouldResetData = filters.page === 0;
        
        if (filters.companyName) {
            debouncedFetchRefunds.cancel();
            debouncedFetchRefunds(shouldResetData);
        } else {
            debouncedFetchRefunds.cancel();
            fetchRefundsData(shouldResetData);
        }
        
        return () => {
            debouncedFetchRefunds.cancel();
        };
    }, [filters, debouncedFetchRefunds, fetchRefundsData]);
    
    useEffect(() => {
        return () => {
            debouncedIncrementPage.cancel();
        };
    }, [debouncedIncrementPage]);
    
    // Infinite scroll to load more refunds
    const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        const buffer = 200;
        
        if (
            target.scrollHeight - (target.scrollTop + target.clientHeight) <= buffer && 
            !isLoadingMore && 
            hasMore
        ) {
            setIsLoadingMore(true);
            debouncedIncrementPage(filters.page);
        }
    }, [isLoadingMore, hasMore, filters.page, debouncedIncrementPage]);

    // Text search filter
    const handleTextSearch = useCallback((value: string) => {
        setFilters(prev => ({ ...prev, page: 0, companyName: value }));
    }, []);

    // Status filter
    const handleStatusFilter = useCallback((status: string) => {
        if (status === 'All' || status === '') {
            setFilters(prev => ({
                ...prev,
                page: 0,
                status: null
            }));
        } else {
            setFilters(prev => ({
                ...prev,
                page: 0,
                status: prev.status 
                ? prev.status.includes(status)
                    ? prev.status.filter(s => s !== status)
                    : [...prev.status, status]
                : [status]
            }));
        }
    }, []);

    // Create refund modal operations
    const handleCreateRefund = () => {
        setModalState(prev => ({ ...prev, isModalOpen: true }));
        Mixpanel.track(CREATE_REFUND_CLICKED,{
            User_Email: userEmail
        })
    };

    const handleModalClose = () => {
        setModalState(prev => ({ ...prev, isModalOpen: false }));
    };

    const handleModalSubmit = async (data: CreateNewRefundRequestData) => {
        const newRefund = await createNewRefundRequest(data);
        if (newRefund) {
            setModalState(prev => ({ ...prev, isModalOpen: false }));
            fetchRefundsData(true, true);
        }
    };

    // Filter tag representation logic
    const filterObjRep = () => {
        return (filters.companyName?.length > 0) || (filters.status?.length > 0);
    };

    const clearFilters = (e: React.MouseEvent, clearAll = false, key?: string, value?: string) => {
        e.preventDefault();
        if (clearAll) {
            setFilters({
                companyName: '',
                status: null,
                page: 0,
            });
        } else if (key) {
            setFilters(prev => ({
                ...prev,
                [key]: key === 'status' ? 
                    (value ? prev.status?.filter(status => status !== value) : null) : 
                    '',
                page: 0,
            }));
        }
    };

    return {
        refundsData,
        isLoading,
        isPageLoading,
        filters,
        modalState,
        handleTextSearch,
        handleStatusFilter,
        handleCreateRefund,
        handleModalClose,
        handleModalSubmit,
        filterObjRep,
        clearFilters,
        isEmpty,
        handleScroll,
        hasMore,
        isLoadingMore,
        fetchRefundsData,
        userEmail,
    };
};