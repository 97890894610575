import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';
import styles from './Button.module.scss';

interface ButtonProps {
    text: string | JSX.Element;
    onClick: MouseEventHandler<HTMLButtonElement>;
    variant?:
        | 'white'
        | 'blue'
        | 'whitePurple'
    size?: 'tiny' | 'small' | 'normal' | 'large' | 'huge' | 'wide';
    style?: React.CSSProperties;
    disabled?: boolean;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    icon?: string | React.ReactNode;
    iconHeight?: string | number;
    iconWidth?: string | number;
    type?: 'button' | 'submit' | 'reset';
}
function Button({
    text = 'Button',
    onClick,
    variant,
    style,
    size = 'normal',
    disabled = false,
    buttonRef,
    icon,
    iconHeight = '',
    iconWidth = '',
    type = 'submit',
    ...props
}: ButtonProps) {
    return (
        <button
            type={type}
            className={clsx(styles.Button, {
                [styles.White]: variant === 'white',
                [styles.Blue]: variant === 'blue',
                [styles.WhitePurple]: variant === 'whitePurple',
                [styles.Disabled]: disabled,
                [styles.Tiny]: size === 'tiny',
                [styles.Small]: size === 'small',
                [styles.Wide]: size === 'wide',
            })}
            onClick={onClick}
            style={style}
            ref={buttonRef}
            {...props}
        >
            {!!icon &&
                (typeof icon === 'string' ? (
                    <img
                        src={icon}
                        alt="icon"
                        height={iconHeight ? iconHeight : '16px'}
                        width={iconWidth ? iconWidth : '16px'}
                        className={clsx(styles.Icon)}
                    />
                ) : (
                    <span className={clsx(styles.Icon)}>{icon}</span>
                ))}
            <div className={clsx(styles.Label)}>{text}</div>
        </button>
    );
}

export default Button;
