import { useNachToggleKeys } from "../constants/enums";

export const DATA_TYPE = { QUARTER: "quarter", YEAR: "yearly" };

export const BANK_EDIT_CLASSIFY_TYPE = { 
  "Financial Inflow": 1, 
  'Operating': 2,
  'Operating Inflow': 3,
  'Operating Outflow': 4,
  'Financial Outflow': 5,
  'Investing Inflow': 6,
  'Investing Outflow': 7,
}

export const GST_EDIT_CLASSIFY_TYPE = {
  "NA": 1,
  "Intra Company": 2,
  "Related Party": 3,
};

export const GST_CLASSIFY_TYPE_ARRAY = ["", "NA", "intraCompany", "relatedParty"];

export const BANK_STMT_CLASSIFICATION_TYPES = [
  {id: -1, name: 'Intra Company'},
  { id: 1, name: 'Financial Inflow' },
  { id: 2, name: 'Operating' },
  { id: 3, name: 'Operating Inflow' },
  { id: 4, name: 'Operating Outflow' },
  { id: 5, name: 'Financial Outflow' },
  { id: 6, name: 'Investing Inflow' },
  { id: 7, name: 'Investing Outflow' }
]

export const GST_CLASSIFICATION_TYPES = [
  { id: 1, name: 'NA', label: "NA" },
  { id: 2, name: 'intraCompany', label: "Intra Company" },
  { id: 3, name: 'relatedParty', label: "Related Party" }
]

export const CURRENT_TAB_TYPE = {
  balance_sheets: "balance-sheets",
  cashflows: "cash-flows",
  pnl: "profit-and-loss",
  invoices: "invoices",
  GST: "gst",
  bank_statements: "bank_stmt",
  contracts: "contracts",
  debt_schedule: 'debt-schedule'
};

export const lastFetchDetailsToTabTypeMapping = {
    "balance-sheets": "bs",
    "cash-flows": "cfs",
    "profit-and-loss": "pnl",
    "invoices": "invoices",
    "gst": "gst",
    "bank_stmt": "bank",
    "contracts": "contracts",
    "debt-schedule": "debt"
}
export const CURRENT_TAB_DATA_TYPE = {
  gst: "gst",
  gstr2a: "gstr2a",
  contracts: "contracts",
  pdf: "pdf",
  spread: "spread",
  pivot: "classified",
  profile: "profile",
  raw: "raw",
  excel_report: "excel_report",
  classified: "classified",
  cashflow: "cashflow"
};

export const GST_INVOICE_MAP_TYPE = {
  spread: "invoice_gst_spreads",
  pivot: "invoice_gst_amounts",
};

export const OVERVIEW_SCREENMAP = {
  TOT: "TOT",
};

export const SORT_COL_DIRECTION = {
  asc: "ascending",
  dsc: "descending",
};

export const DEAL_HEADER_FILTER = {
  createDate:"createDate",
  investor: "investorDisplayName",
  company: "companyName",
  action: "actionStatus",
  status:"lastActivityStatus"
};

export const ACTION_STATUS = {
  sanction: "SEND SANCTION",
  deal: "SEND DEAL",
  resend_kyc: "RESEND KYC & DOCS",
  send_kyc: "SEND KYC & DOCS",
  reminder: "SEND REMINDER",
  drawdown: "SEND DRAWDOWN",
  resolve_query: "RESOLVE QUERY",
  completed: "COMPLETED"
};

export const DEAL_DRAWER_ALERT_TYPE = {
  sendForSinging: "SEND FOR SIGNING",
  regenerateMfaSl:"REGENERATE MFA AND SL"
};
export const DEAL_AlERT_BUTTON_TXT = {
  SEND: "Send",
  REGENERATE:"Regenerate"
};

export const LAST_ACTIVITY_STATUS = {
  kyc_query_recieved: "KYC Query Recieved",
  uw_query_recieved: "U/W Query Received"
}

export const OVERVIEW_STATUS = {
  sent: "Sent",
  not_sent: "Not Sent",
  passed: "Passed",
  accepted: "Accepted",
  deal_view: "Deal View",
  kyc_ready: "KYC & Docs Ready",
  kyc_shared: "KYC & Docs Shared",
  expired: "Expired",
  expire_soon: "Expiring Soon",
  drawdown: "Drawdown Request",
  sanction: "Sanction Approved",
  missed : "Missed",
  completed: "Completed"
};

export const DRAWDOWN_DOWNLOAD_OPTIONS = {
  DOWNLOAD_ALL: "Download All",
  DRAWDOWN_LETTER: "Drawdown Letter",
  DRAWDOWN_CALC_SHEET: "Drawdown calc sheet",
  VENDOR_FINANCE: "Vendor Finance",
  PROFORMA_INVOICE: "Proforma invoice",
  DISBURSEMENT_SHEET: "Disbursement Sheet",
  PAYMENT_SPLIT_SHEET: "Payment Split"
}

export const DATA_TYPE_TABS = {
  summary: 'summary',
  spread: 'spread',
  classified: 'classified',
  cashflow: 'cashflow'
}


export const METHODS = {
  NACH: "NACH",
  NEFT: "NEFT",
  SCHEDULED: "SCHEDULED",
  ['NACH + NEFT']: 'NACH + NEFT'
}

export const COLLECTION_METHODS = [
  { methodType: METHODS.NACH },
  { methodType: METHODS.NEFT },
  { methodType: `${METHODS.NACH} + ${METHODS.NEFT}` },
];

// export const COLLECTION_METHODS_ARR = [METHODS.NACH, METHODS.NEFT, `${METHODS.NACH} + ${METHODS.NEFT}`, "YES", "NO"]
export const COLLECTION_METHODS_ARR = [(useNachToggleKeys.YES).toUpperCase(), (useNachToggleKeys.NO).toUpperCase()]

export const ONBOARDING_STATUS = {
  NEEDINFO: "Need Information",
  NEEDINFOOPTIONAL: "Need Information Optional",
  WAITLISTED: "Waitlisted",
  APPROVED: "Approved",
  SPARK_APPROVED: "Spark Approved",
  APPROVED_RUNAWAY: "Approved-Runway<3M",
  IS_NACH: "is_nach_enabled"
};

export const NEED_INFO_ONBOARDING_STATUS = [ONBOARDING_STATUS.NEEDINFOOPTIONAL, ONBOARDING_STATUS.NEEDINFO]

export const LIMIT_TYPES = {
  RECUR_LIMIT: "Recurlimit",
  STANDARD: "Standard",
  MAX: "Max",
  DEBT_SYNDICATION: "DebtSyndication"
}

export const TOT_STATUS = {
  CREATED: "Created",
  ACCEPTED: "Accepted",
  SENT: "Sent",
  UPDATED_NOT_SENT: "Updated Not Sent",
};

export const commentTypeEnum = {
  LOG_CALL: {
    key: "LOG_CALL",
    value: "Call Log",
  },
  REQUEST_CALL: {
    key: "REQUEST_CALL",
    value: "Call Requested",
  },
  COMMENT_ONLY: {
    key: "COMMENT_ONLY",
    value: "Comment Only",
  },
};

export const REGEX_DOMAIN_EXT = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*.[a-zA-Z]{2,}(?:.[a-zA-Z]{2,})?$/


export const UNDERWRITING_STATUS = {
  NOT_STARTED: 'Not Started',
  ONGOING: 'Ongoing',
  NEED_INFO: 'Need Info',
  APPROVED: 'Approved',
  WAITLISTED: 'Waitlisted',
  NOT_APPROVED: 'Not Approved'
}

export const COMPANY_STATUS = {
  EXPIRED: 'Expired',
}

export const TOT_FEE_URI = "tot/update-tot-fee";
export const INDICATIVE_TERMS = "deal/save-indicative-terms";
export const AUDIT_HANDLER_URI ={
  TOT_FEE_URI : "tot/update-tot-fee",
  INDICATIVE_TERMS : "deal/save-indicative-terms",
  TRANSACTION_DOCUMENTS : "investor_deals/investor_deal_update"

}

export const TENTATIVE_DEAL_TERMS = {
  recurFee : "Recur Fee",
  tentativeDealSize : "Limit",
  tentativeTenure : "Tenure",
  rateOfInterest : "ROI",
  investorProcessingFee : "Investor Processing Fee",
  tentativeFinancingStructure : "Financing Structure"
}
export const TENTATIVE_DEAL_TERMS_CHECK = {
  tentativeDealSize : "tentativeDealSize",
  tentativeTenure : "tentativeTenure",
  rateOfInterest : "rateOfInterest",
  tentativeFinancingStructure : "tentativeFinancingStructure",
}
export const MARK_SANCTION_COMPLETE = {
  COMPLETE : "Complete",
  INCOMPLETE : "Incomplete"
}

export const UPLOADING = "Uploading...."
export const IPA_DOC = "IPA"
export const UPLOAD_DOC  = "Upload Document"
export const UPLOAD_IPA = "Upload IPA"
export const kycFolderNames = {
    DEBT_ADDN_DOCS: "debt_addn_docs",
}
export const KFS_FOLDER_NAME = 'kfs';
export const QUERY_RAISED_CONSTANTS ={
  KYC_QUERY_RECEIVED : 'KYC Query Recieved',
  UW_QUERY_RECEIVED  : 'U/W Query Received'
}

export const RepaymentStatus = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
}

export const MARK_RESOLVED_SUCCESS = "Query successfully marked as resolved"

export const BROADCAST_MAIL = "Broadcast Mail"
export const KYC_PENDING_STATUS =["In Progress","Query Raised","Query Resolved","Not Started"]
export const UW_PENDING_STATUS =["Sent","Approved","Passed","Missed","Query Raised","Query Resolved"]

export const ORDER_STATUS = {
  APPROVED: 'Approved'
}

export const INVOICE_STATUS_LENDER_WISE = {
  YES: "Yes",
  NO: "No",
}

// Lenders configuration page contants
export const LENDERS_CONFIG_CONSTANTS = {
  INVOICE_STATUS :"generate_paid_invoice",
}
