export const ADD_ON_LIMIT_CONST = "ADD_ON_LIMIT";
export const INVESTEE_ORG_ACTIVE = "Active";
export const INVESTEE_ORG_LOCKED = "Locked";
export const INVESTEE_ORG_INACTIVE = "Inactive";
export const INVESTEE_ORG_UNCLAIMED = "Unclaimed"
export const CAPITAL_REQUESTED_FIELDS = {
  EXPECTED_REVENUE: "Exp. Revenue",
  CAPITAL_EXPECTATION: "Capital Expectation",
  TENTATIVE_TIMELINE: "Tentative Timeline",
  CAPITAL_REQUESTED: "Capital Requested",
}
export const TOT_FEE_PERC_AFTER_DISCOUNT_EXPIRY = 3


export const REPAYMENT_DATES = [
  {
    label: "24th",
    value: 24,
  },
  {
    label: "25th",
    value: 25,
  },
  {
    label: "26th",
    value: 26,
  },
  {
    label: "27th",
    value: 27,
  },
  {
    label: "28th",
    value: 28,
  },
];

// API Status
export const API_STATUS = {
  SUCCESS: "Success",
  ERROR: "Error",
};
// API Status
export const API_STATUS_CODE = {
  SUCCESS: 200
};

export const FILE_CONTENT_TYPE = {
  PDF: "application/pdf",
};
export const TOAST_TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  OFFLINE: "offline",
  ONLINE: "online",
  SOCKET_OFFLINE: "socket-offline",
};


export const DEFAULT_REPAYMENT_DAY = 28;
export const DEFAULT_CALENDAR_DAYS = 365;
export const MONTHS = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
export const LAKH = 100000;
export const CERBERUS_TOKEN = "cerberus_token";
export const USER_ID = "user_id";
export const ORG_ID = "org_id";
export const ADMIN = "admin";
export const EMAIL_ID = "email_id";

// Types of constant can be added here, for roles document
// 1. Field Values
// 2. Field Names
export const ROLES_CONST = {
  COUNTERSIGN_NAME: "Countersign Auth",
  COUNTERSIGN_BY_ADMIN_NAME: "Countersign Auth By Admin",
  NAME_FILED: "name",
};
export const DEAL_REJECTION_REASONS = {
  1: "Claimed by another investor",
  2: "Not ICP",
  3: "Low runway",
  4: "Poor CIBIL",
  5: "Small Scale",
  6: "Too young",
  7: "High leverage",
  8: "High Receivables",
  9: "No Growth",
  10: "Others",
  11: "Unresponsive",
  12: "Missed",
  13: "Already a lender",
  14: "Already Evaluated"

};

export const PG_CONFIG_TYPE = {
  FIXED_AMOUNT_DAILY: "FIXED_AMOUNT_DAILY",
};
export const PG_CONFIG_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const FLOW_ID = {
  KYC: 1,
  UW_QUERIES: 2,
  TRANSACTION_DOCUMENTS: 4
};
export const REGEX = {
  NUMBER_ONLY: /^[0-9]*$/
};


export const RAZORPAY_SPLIT_KEY = "razorpaySplit";
export const CASHFREE_SPLIT_KEY = "cashfreeSplit";
export const DIRECT_COLLECTION_KEY = "directCollection";
export const NACH_FORTNIGHTLY = "nachFortnightly";
export const NACH_WEEKLY = "nachWeekly";
export const COBORROWER = "Coborrower";
export const MAB = "MAB";
export const SECONDARY_RCM_METHODS = {
  Amazon: "Amazon",
  Swiggy: "Swiggy",
  Zomato: "Zomato",
  Shiprocket: "Shiprocket",
  Flipkart: "Flipkart",
  Easebuzz: "Easebuzz",
  Nykaa: "Nykaa",
  Snapdeal: "Snapdeal",
  Paytm: "Paytm"
}

export const SPLIT_NACH_RCM_METHODS = {
  nachFortnightly: "NACH (Fortnightly)",
  razorpaySplit: "Razorpay Split",
  cashfreeSplit: "Cashfree Split",
  nachWeekly: "NACH (Weekly)",
  ...SECONDARY_RCM_METHODS
}

export const COLLECTION_METHODS = {
  ...SPLIT_NACH_RCM_METHODS,
  MAB: "MAB",
  Coborrower: "Coborrower",
  directCollection: "Direct Collection"
};

export const AUTO_WITHDRAW_COVER_MEHTODS = [
SECONDARY_RCM_METHODS.Amazon,
SECONDARY_RCM_METHODS.Swiggy,
SECONDARY_RCM_METHODS.Zomato,
SECONDARY_RCM_METHODS.Shiprocket,
SECONDARY_RCM_METHODS.Flipkart,
SECONDARY_RCM_METHODS.Easebuzz,
SECONDARY_RCM_METHODS.Nykaa,
SECONDARY_RCM_METHODS.Snapdeal,
SECONDARY_RCM_METHODS.Paytm,
CASHFREE_SPLIT_KEY
];

export const RCM_METHOD_COVER = {
  [SECONDARY_RCM_METHODS.Amazon]: 7,
  [SECONDARY_RCM_METHODS.Swiggy]: 7,
  [SECONDARY_RCM_METHODS.Zomato]: 7,
  [SECONDARY_RCM_METHODS.Shiprocket]: 3,
  [SECONDARY_RCM_METHODS.Flipkart]: 7,
  [SECONDARY_RCM_METHODS.Easebuzz]: 3,
  [SECONDARY_RCM_METHODS.Nykaa]: 7,
  [SECONDARY_RCM_METHODS.Snapdeal]: 7,
  [SECONDARY_RCM_METHODS.Paytm]: 7,
  [CASHFREE_SPLIT_KEY]: 3
};

export const PAYMENT_DEFAULT_RISKS = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  AGREED_FOR_NACH: 'Agreed For NACH',
  EARLY_REPAYMENT: 'Early Repayment',
  PARTIAL_RECEIVED: 'Partial Received',
  NEFT_CONFIRMED: 'NEFT Confirmed',
  STOP_NACH: 'Stop NACH - NEFT',
  CREDIT_CARD_PAYMENT: 'Payment via CC',
  LAST_DAY_PAYMENT: 'Payment Last Day',
  GRACE_PERIOD: 'Grace Period',
  NACH_FAILED: 'NACH Failed',
  PAYMENT_NOT_REFLECTING: 'Payment Not Reflecting',
  UNRESPONSIVE: 'Unresponsive'
};

export const DOC_SERVICE_RESPONSE = {
  SUCCESS: "OK"
}

export const useNachToggleKeys = {
  NO: "No",
  YES: "Yes"
}

export const GENERIC_ERROR = "Some error occurred";


export const MET = 'Met';
export const PARTIALLY_MET = 'Partially Met';
export const NOT_MET = 'Not Met';
export const MET_UNVERIFIED = 'MET_UNVERIFIED';
export const EXPIRED = 'Expired';
export const RCM = 'RCM';

export const ENUM_RCM_STATUS = {
  [PARTIALLY_MET]: "partially",
  [NOT_MET]: "not_met",
  [MET]: "met"
}
export const INVESTOR_DEAL_STATUS = {
  INVESTOR_DEALS_DOCS_READY: "Docs Ready",
  INVESTOR_DEALS_DOCS_SHARED: "Docs Shared",
  INVESTOR_DEALS_SANCTIONED: "Sanctioned",
  INVESTOR_DEALS_EXPIRING: "Expiring",
  INVESTOR_DEALS_EXPIRED: "Expired",
};

export const DEAL_DOC_CATEGORY_ID = "3";
export const SHOW_NOTIFICATION = {
  SUCCESS: 'Success',
  ERROR: 'Error',
  WARNING: 'Warning',
}

export const ZEUS_EMAIL_KYC_AUDIT_TRAIL = "zeus@recur.club";

export const IDENTIFIER_TYPE = {
  ONBOARDING: "onboarding_type",
  ADD_ON: "add_on_type",
}

export const INVESTOR_DISPLAY_NAME = {
  "Aditya Birla Finance Limited": "ABFL",
  "InCred Financial Services Limited": "Incred",
  "Mamta Projects Private Limited": "Arthmate",
  "GHALLA & BHANSALI SECURITIES PRIVATE LIMITED": "Ghalla",
  "Ugro Capital Limited": "Ugro",
}

export const MAX_MORATORIUM = 3

export const RECUR_TABS_COLORS = {
  BLUE: "blue",
  BLACK: "black"
}

export const PREPARE_TERMS_TAB = {
  RRF_TERMS: "RRF Terms",
  DS_TERMS: "DS Term"
}
export const MODERATION = {
  DEBT_SYNDICATION_TERM: "Scale DS Terms",
  RRF_TERM: "Swift RRF Terms",
}

export const INVESTOR_TYPE = {
  NBFC: 'NBFC',
  NBFC_FOR_DEBT: 'NBFC FOR DEBT',
  INSTITUTIONAL: 'Institutional',
  FAMILY_OFFICE: 'Family Office',
  HNI: 'HNI',
  OTHERS: 'Others',
};

export const LOAN_TYPE = {
  RECURRING_REVENUE_FINANCING: 'Recurring Revenue Financing',
  DEBT_SYNDICATION: 'Debt Syndication',
};
export const documentationStatus = {
  QUERY_RESOLVED: "Query Resolved",
  QUERY_RAISED:"Query Raised"
}

export const queryTypes = {
  UW_QUERIES: 'uw_queries',
  KYC_QUERIES: 'kyc_queries'
}

export const queryTabs = {
  DOCUMENTS: 'Documents',
  DEAL_TERMS: 'Deal Terms',
  KYC_DOCS: 'KYC DOCS',
  UW_QUERIES: "U/W Queries",
  KYC_QUERIES: "KYC Queries"
}
// ShowNotification Statuses
export const SHOW_NOTIFICATION_STATUS = {
  SUCCESS: 'Success',
  ERROR: 'Error',
  WARNING: 'Warning',
};

export const OTHERS = 'Others'

export const additionalOption = [
  { label: 'Debt schedule and sanction Letters', value: 'Debt schedule and sanction Letters' },
  { label: 'Bank Statements', value: 'Bank Statements' },
  { label: 'Audited Financials', value: 'Audited Financials' },
  { label: 'MIS', value: 'MIS' },
  { label: 'Equity Infusion/Equity raise docs', value: 'Equity Infusion/Equity raise docs' },
  { label: 'Receivables Ageing', value: 'Receivables Ageing' },
  { label: 'GST Data and fillings', value: 'GST Data and fillings' },
  { label: 'Bureau Reports', value: 'Bureau Reports' },
  { label: 'Invoicing Data', value: 'Invoicing Data' },
  { label: 'Pitch Deck and Other Documents', value: 'Pitch Deck and Other Documents' },
  { label: 'Shareholding Pattern', value: 'Shareholding Pattern' },
  { label: 'Financial Projections', value: 'Financial Projections' },
  { label: 'ITR fillings', value: 'ITR fillings' },
  { label: 'Ageing Details', value: 'Ageing Details' },
  { label: OTHERS, value: OTHERS },
];

export const WAIVED = "Waived"
export const NOT_WAIVED = "Not Waived"

export const SENT_FOR_SIGNING = "sent_for_signing";
export const FAILED = "failed";
export const SIGNING = "signing";
export const SIGNED = "signed";
export const INVESTOR_OFFER_APPROVED = "Approved";

export const ADDITIONAL_DEAL_DOC_CATEGORY_ID = "13";
export const ADDITIONAL_DEAL_DOCS = "Additional Deal Docs";
export const DEAL_STAGE_STATUS = {
  COMPLETED: 'Complete',
  PENDING: 'Pending',
  NOT_STARTED: 'Not Started',
  QUERY_RAISED:"Query Raised",
  EXPIRED:"Expired",
  CASH_TRANSFER:"Cash Transfer"

}
export const EXPIRING_DEAL_STAGES = ["Deal Expiring Soon","Deal Expired","Sanction Expiring Soon","Sanction Expired","Sanction Expired"]
export const EXPIRED_OR_PASSED_DEALS_STAGES = ["Sanction Expired","Deal Passed","Deal Expired","Deal Missed"]
export const DEAL_STAGE_IMG_URL = {
  STAGE_COMPLETED_FADED_IMG_URL: "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/completed-grey-checkbox.svg",
  STAGE_COMPLETED_IMG_URL: "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/done-green-background.svg",
  STAGE_PENDING_IMG_URL: "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/pending-circular-yellow-box.svg",
  STAGE_NOT_DONE_IMG_URL: "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/faded-non-completed-circular-box.svg",
  STAGE_NOT_DONE_FADED_IMG_URL: "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/not-completed-circular-box.svg"
}

export const VIEW_FILTERS = {
  VIEW_ALL: "View All",
  VIEW_NEW: "View RRF New",
  VIEW_ADD_ON: "View RRF Add On",
  VIEW_DS: "View DS",
  VIEW_UNDRAWN_LINES: "View Undrawn Lines"
}
export const APPLICATION_TYPE = {
  ONBOARDING: "Onboarding",
  ADD_ON: "Add On"
}

export const VIEW_FILTERS_API_KEY = {
  VIEW_ALL: "viewAll",
  VIEW_NEW: "viewNew",
  VIEW_ADD_ON: "viewAddOn",
  VIEW_DS: "viewDS",
  VIEW_UNDRAWN_LINES: "viewUndrawnLines"
}

export const COLLECTION_VIEW_FILTER = {
  NON_DPD_COMPANIES: "Non-DPD Companies",
  FIRST_REPAYMENTS: "First Repayments",
  DEEP_RED_COMPANIES: "Deep Red Companies",
  RED_COMPANIES: "Red Companies"
}

export const COLLECTION_VIEW_FILTER_API_KEY = {
  NON_DPD_COMPANIES: "nonDpdCompanies",
  FIRST_REPAYMENTS: "firstRepaymentOfCompany",
  DEEP_RED_COMPANIES: "showDeepRedCompanies",
  RED_COMPANIES: "showRedCompanies"
}

export const KYC_DOCS = {
  PING_REPORT: "Ping Report"
}

export const COMPANY_AUTHORIZATION = {
  AUDIT_TRAIL: "Audit Trail"
}

export const GST_VALUE = 1.18;
export const DEFAULT_INVESTOR_ROI = 0.1581;
export const DEFAULT_INVESTOR_FEE = 1.09;

export const GST_INACTIVE = 'INACTIVE';
export const GST_ACTIVE = 'ACTIVE';

export const NEEDINFO = "Need Information";
export const WAITLISTED = "Waitlisted";
export const APPROVED_RUNAWAY = "Approved-Runway<3M";
export const APPROVED = "Approved";

export const NOT_APPROVED = "Not Approved";
export const COMPLETED = "Completed";

export const uw_signal_types = {
  REJECT : "reject",
  DIFFICULT: "difficult",
  SPARK: "spark",
  GOOD: "good",
  FANTASTIC: "fantastic",
};

export const uw_signal_arr = [uw_signal_types.GOOD, uw_signal_types.FANTASTIC, uw_signal_types.SPARK, uw_signal_types.DIFFICULT]

export const rating_types = {
  POOR: "poor",
  AVERAGE: "average",
  GOOD: "good",
  HIGH: "high",
  LOW: "low",
};

export const STATUS_LIST = ["OPEN"];

export const STATUS_TYPES = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
}
export const AUTO_REFUND_SETTING = {
  YES: "Yes",
  NO: "No",
}

export const OVERVIEW_EDIT_TYPES = {
  AUTO_REFUND: "autoRefund",
  RECUR_RATING: "recur_rating",
  REPAYMENT_RISK: "repayment_risk",
  GLOBAL_DELAY: "global_delay",
  RECUR_INSIDER_STATUS :"recur_insider_status",
  UNDERWRITER_ID:"underwriter_id"
}


export const HYPHEN = "-";
export const ENVIRONMENTS={
  PRODUCTION:"production",
  DEVELOPMENT:"development",
  STAGING:"staging"
}
