import React from 'react';
import { Mixpanel } from '../../../../util/mixpanel';
import Button from '../../../../common/_custom/Button/Button';
import plusImg from "../../../../assets/plus-white-circle.svg";
import useQueryParams from '../../../../common/customHooks/useQueryParams';
import { ADMIN_WIRE_TRANSFER_BUTTON_CLICK } from '../../../../constants/mixpanel/AdminEvents';

export const WireTransferButton = ({
    style,
    pageName,
    setOpenWireTransferDrawer,
}: {
    style?: React.CSSProperties;
    pageName: string;
    setOpenWireTransferDrawer: (value: boolean) => void;
}) => {

    const { updateQueryParams } = useQueryParams();

    const handleClick = () => {
        updateQueryParams({ wireTransferDrawer: 'true' });
        setOpenWireTransferDrawer(true);
    };

    const regesterAdminWTButtonClickMixpanelEvent = (page: string) => {
        Mixpanel.track(ADMIN_WIRE_TRANSFER_BUTTON_CLICK, {
            Page_Name: page,
        })
    }

    const defaultStyles: React.CSSProperties = {
        width: "125px",
        padding: "0.375rem 1rem",
        borderRadius: "4px",
    };

    return (
        <Button
            text="Wire Transfer"
            variant="blue"
            size="tiny"
            icon={plusImg}
            iconHeight={14}
            iconWidth={14}
            onClick={() => {
                handleClick();
                regesterAdminWTButtonClickMixpanelEvent(pageName);
            }}
            style={{ ...defaultStyles, ...style }}
        />
    );
};

export default WireTransferButton;
