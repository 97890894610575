import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { API_STATUS, DOC_SERVICE_RESPONSE, FLOW_ID, GENERIC_ERROR } from '../../../../../../../../constants/enums';
import callApi from '../../../../../../../../util/apiCaller';
import { downloadFileUsingFetch, generateRequestId, mixPanelTrack, showNotification } from '../../../../../../../../util/utility';
import { getDataSpecialist, getEmail, getUserId } from '../../../../../../../App/AppReducer';
import DocumentVaultCards from './components/DocumentVaultCards';
import DocumentVaultTable from './components/DocumentVaultTable';
import { KYC_NAME_UPDATE_DOC_TYPES, TOTAL_COUNT, VERIFIED_COUNT } from './consts/consts';
import { getInvesteesOrganization } from '../../../../../../AdminReducers';
import ConfirmationModal from '../../../../../../../../components/ConfirmationModal/ConfirmationModal';
import { Mixpanel } from '../../../../../../../../util/mixpanel';
import { performUpdateNameFetchedFromPan } from '../../../../../../../../common/ApiHelpers';

export default function DocumentVault() {
    const DOC_SCORE = "docScore";
    const MODERATOR_NAME = "moderatorName";
    const DOC_SHARED_WITH_INVESTOR = "docSharedWithInvestor";
    const LAST_UPDATED = "updatedAt";
    const [docsData, setDocsData] = useState();
    const [docsScoreData, setDocsScoreData] = useState();
    const [docScoreDataArr, setDocsScoreDataArr] = useState();
    const dataSpecialist = useSelector(getDataSpecialist);
    const investeeOrganizationId = useSelector(getInvesteesOrganization)?._id;
    const organizationName = useSelector(getInvesteesOrganization)?.name;
    const [isDownloadAllDocs, setIsDownloadAllDocs] = useState(false);
    const userId = useSelector(getUserId);
    const userEmail = useSelector(getEmail)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [panVerification, setPanVerification] = useState({
        isNameMatchFailed: null,
        nameFromPan: '',
        prevName: '',
        beneficialOwnerId: '',
        docType: '',
    });

    const nameMismatch = useMemo(() => ({
        title: 'Name Mismatch Detected',
        description: `Would you like to update the beneficial owner to ${panVerification.nameFromPan}?`,
        updateButtonText: 'Update Name',
        cancelButtonText: 'Cancel',
    }), [panVerification.nameFromPan]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (docsScoreData) updateDocScoreDataArr();
    }, [docsScoreData]);

    const updateStateFromUrl = () => {
        const params = new URLSearchParams(window.location.search);
        const panVerificationData = {
            isNameMatchFailed: params.get('isNameMatchFailed') === 'true',
            nameFromPan: params.get('nameFromPan') || '',
            prevName: params.get('prevName') || '',
            beneficialOwnerId: params.get('beneficialOwnerId') || '',
            docType: params.get('docType') || '',
        };

        const shouldClearParams = panVerificationData.isNameMatchFailed || panVerificationData.nameFromPan;
        if (shouldClearParams) {
            ['isNameMatchFailed', 'nameFromPan', 'prevName', 
             'beneficialOwnerId', 'docType'].forEach(param => {
                params.delete(param);
            });

            const newSearch = params.toString();
            const newUrl = newSearch 
                ? `${window.location.pathname}?${newSearch}`
                : window.location.pathname;
            
            window.history.replaceState(null, '', newUrl);

            if (panVerificationData.nameFromPan) {
                setPanVerification(panVerificationData);
                setShowConfirmationModal(panVerificationData.isNameMatchFailed);
            }
        }
    };

    useEffect(() => {
        updateStateFromUrl();

        const handlePopState = () => {
            updateStateFromUrl();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [updateStateFromUrl]);

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleSecondaryBtnClick = () => {
        Mixpanel.track('KYC Username Update Skipped', {
            action_by: userId,
            org_id: investeeOrganizationId,
            old_name: panVerification?.prevName,
            new_name: panVerification.nameFromPan,
            zeus_user: true,
        });
        closeConfirmationModal();
    };

    const handlePrimaryBtnClick = async () => {
        try {
            Mixpanel.track('KYC Username Updated', {
                action_by: userId,
                org_id: investeeOrganizationId,
                old_name: panVerification?.prevName,
                new_name: panVerification.nameFromPan,
                zeus_user: true,
            });

            const payload = {
                investee_organization_id: investeeOrganizationId,
                name: panVerification.nameFromPan,
                prevName: panVerification.prevName,
                docType: panVerification.docType,
                userId: userId,
            };

            if (panVerification.docType === KYC_NAME_UPDATE_DOC_TYPES.BENEFICIARY_ADD_SIGN_PAN) {
                payload.beneficialOwnerId = panVerification.beneficialOwnerId;
            }

            const res = await performUpdateNameFetchedFromPan(payload);
            closeConfirmationModal();
            if (res.status === API_STATUS.SUCCESS) {
                fetchAllDocs();
                showNotification('Success', 'Name updated successfully');
            } else {
                showNotification('Error', `Failed to update name: ${res.message || GENERIC_ERROR}`);
            }
        } catch (error) {
            showNotification('Error', `Failed to update name: ${error.message || GENERIC_ERROR}`);
        }
    };

    const updateDocScoreDataArr = () => {
        const defaultDocScoreObj = { label: "Documents Score", [VERIFIED_COUNT]: 0, [TOTAL_COUNT]: 0 };
        const defaultModeratorObj = { label: "Moderator", value: dataSpecialist?.agent_name ?? "N/A" };
        const defaultDocSharedWithInvestorObj = { label: "Documents shared with Investor", [VERIFIED_COUNT]: 0, [TOTAL_COUNT]: 0 };
        const defaultLastUpdatedObj = { label: "Last Updated", value: "N/A" };
        const arr = [];
        arr.push(docsScoreData[DOC_SCORE] ? {
            ...defaultDocScoreObj,
            ...docsScoreData[DOC_SCORE],
        } : defaultDocScoreObj);
        arr.push(docsScoreData[MODERATOR_NAME] ? {
            ...defaultModeratorObj,
            value: docsScoreData[MODERATOR_NAME] ?? dataSpecialist?.agent_name,
        } : defaultModeratorObj);
        arr.push(docsScoreData[DOC_SHARED_WITH_INVESTOR] ? {
            ...defaultDocSharedWithInvestorObj,
            ...docsScoreData[DOC_SHARED_WITH_INVESTOR],
        } : defaultDocSharedWithInvestorObj);
        arr.push(docsScoreData[LAST_UPDATED] ? {
            ...defaultLastUpdatedObj,
            value: (docsScoreData[LAST_UPDATED] ?? 'N/A'),
        } : defaultLastUpdatedObj);
        setDocsScoreDataArr(arr);
    }
    const downloadAllFiles = () => {
        setIsDownloadAllDocs(true);
        downloadFileUsingFetch(
            `file/getAllFilesForPrefixData?downloadRequest=UnsignedDocs&organizationId=${investeeOrganizationId}&requestId=${generateRequestId()}&organizationName=${encodeURIComponent(organizationName)}`,
            "get",
            {
                responseType: "blob",
            },
            `${organizationName}_${investeeOrganizationId}.zip`,
            () => {
                setIsDownloadAllDocs(false);
                mixPanelTrack({
                    id: userId,
                    trackUserObj: {
                        userEmail,
                        organizationId: investeeOrganizationId,
                        organizationName,
                    },
                    trackCategoryName: "Unsigned KYC Downloaded",
                });
            },
            null,null, false , true 
        );
    };

    const fetchAllDocs = async() => {
        const res = await callApi("file/fetchAllDocs", "post", {
            flowId: FLOW_ID.KYC
        }, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) setDocsData(res?.docs ?? {});
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification("Error", message);
        }
    }

    const fetchDocStats = async() => {
        const res = await callApi("file/getDocumentStats", "post", {
            flowId: FLOW_ID.KYC
        }, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) setDocsScoreData(res?.docStats ?? {});
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification("Error", message);
        }
    }

    const fetchData = () => {
        fetchDocStats();
        fetchAllDocs();
    }

    return (
        <div className='m-4'>
            <div className='row'>
                {docScoreDataArr && docScoreDataArr.map((item, index) => (
                    <div key={index} className='col-md-3'>
                        <DocumentVaultCards item={item} />
                    </div>
                ))}
            </div>
            <DocumentVaultTable
                docsData={docsData}
                moderatorName={docsScoreData?.[MODERATOR_NAME]}
                fetchData={fetchData}
                downloadAllFiles = {downloadAllFiles} 
                isDownloadAllDocs = {isDownloadAllDocs}
            />
            { showConfirmationModal && <ConfirmationModal
                    title={nameMismatch.title}
                    description={nameMismatch.description}
                    primaryButtonText={nameMismatch.updateButtonText}
                    secondaryButtonText={nameMismatch.cancelButtonText}
                    onPrimaryButtonClick={handlePrimaryBtnClick}
                    onSecondaryButtonClick={handleSecondaryBtnClick}
                    onClose={closeConfirmationModal}
            />}
        </div>
    )
}
