import React from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';
import searchImg from '../../../assets/search.svg';

interface InputProps {
  label: string;
  handleChange: (value: string) => void;
  value: string | number | null; 
  id: string;
  type?: string;
  placeholder?: string;
  showImg?: boolean;
  imgSrc?: string;
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  imgClassName?: string;
  isDisabled?: boolean;
  autocomplete?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  maxDateAllowed?: string;
  min?: string;
  step?: string;
}

export const Input: React.FC<InputProps> = ({
  label,
  handleChange,
  onBlur,
  onFocus,
  value, 
  id,
  type = 'text',
  placeholder = '',
  showImg = false,
  imgSrc,
  containerStyle,
  labelStyle,
  inputStyle,
  imgStyle,
  containerClassName,
  labelClassName,
  inputClassName,
  imgClassName,
  isDisabled = false,
  autocomplete = 'off',
  maxDateAllowed,
  min,
  step,
}) => {
  return (
    <div
      className={clsx(styles.outerDiv, value !== '' && styles.hasValue, containerClassName)}
      style={containerStyle}
    >
      <label
        htmlFor={id}
        className={clsx(labelClassName)}
        style={labelStyle}
      >
        {label}
      </label>
      <input
        id={id}
        name={id}
        className={clsx(value !== '' && styles.inputBox, inputClassName)}
        type={type}
        value={value ?? ''}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        style={inputStyle}
        disabled={isDisabled}
        autoComplete={autocomplete}
        onBlur={onBlur}
        onFocus={onFocus}
        max={type === 'date' ? maxDateAllowed : undefined}
        min={min}
        step={step}
      />
      {showImg && (
        <img
          src={imgSrc ? imgSrc : searchImg}
          alt=""
          className={clsx(imgClassName)}
          style={imgStyle}
        />
      )}
    </div>
  );
};
