import React from 'react';
import styles from './Select.module.scss';

interface Item {
  id?: string;
  displayName?: string;
}

interface SelectProps {
  isLoading?: boolean;
  isLoadingText?: string;
  notFoundText?: string;
  items: Item[];
  handleOnClick: (item: Item) => void;
}

export const Select: React.FC<SelectProps> = ({
  isLoading = false,
  isLoadingText = 'Loading...',
  notFoundText = 'No items found',
  items,
  handleOnClick,
}) => {
  return (
    <div className={styles.selectContainer}>
      <ul className={styles.selectList}>
        {isLoading ? (
          <li key={''} className={styles.notFound}>{isLoadingText}</li>
        ) : items.length > 0 ? (
          items.map((item) => (
            <li
              className={styles.selectItem}
              onClick={() => handleOnClick(item)}
              key={item.id}
            >
              {item.displayName}
            </li>
          ))
        ) : (
          <li key={''} className={styles.notFound}>{notFoundText}</li>
        )}
      </ul>
    </div>
  );
};