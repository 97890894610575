import React, {Component} from 'react';
import {
    withRouter,
} from 'react-router-dom';
import '../../../../../css/CompaniesDetail.css'
import async from "async";
import callApi from "../../../../../util/apiCaller";
import {showNotification} from "../../../../../util/utility";
import { v4 } from "uuid";
import { isEmpty, isEqual } from 'lodash';
import { FEE_STRUCTURE, feesStructureObj } from '../../../../../constants/investor_fees_structure';
import chargeFee from "../../../../../constants/charge_fee.json"
import { DEFAULT_CALENDAR_DAYS, DEFAULT_REPAYMENT_DAY, INVESTOR_TYPE } from '../../../../../constants/enums';
import { INVOICE_STATUS_LENDER_WISE, LENDERS_CONFIG_CONSTANTS } from '../../../../../common/ENUM';

const MARKET_VIEW_OPTIONS = [
    "All",
    "Only Listed"
]

class InvestorEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            investorOrganization: this.props.investorstatsDetails ? this.props.investorstatsDetails : {},
            investorpdftemplates: this.props.investorpdftemplates ? this.props.investorpdftemplates : {},
            counterSignAuthority: this.props.counterSignAuthority ? this.props.counterSignAuthority : ""
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.investorstatsDetails, this.props.investorstatsDetails)) {
            this.setState({
                investorOrganization: this.props.investorstatsDetails
            })
        }
    }

    handleChange = (keyName, keyValue) => {
        if(keyName === LENDERS_CONFIG_CONSTANTS.INVOICE_STATUS) {
            keyValue = keyValue === INVOICE_STATUS_LENDER_WISE.YES ? true : false;
        }
        const investorOrganization = {...this.state.investorOrganization};
        investorOrganization[keyName] = keyValue;
        this.setState({
            investorOrganization: investorOrganization
        });
    };

    onSaveClick = () => {
        this.props.updateFields(this.state.investorOrganization);
        if (this.props.counterSignAuthority !== this.state.counterSignAuthority) {
          this.props.updateCSAForInvestorOrganiation(
            this.props.investorstatsDetails?._id,
            this.state.counterSignAuthority
          );
        }
    };

    handleCSAChange = (value) => {
        this.setState({
            counterSignAuthority: value
        })
        
    }

    uploadSignatureImage = (file) => {
        async.series(
            [
                (callback) => {
                    callApi(`s3/sign`, "post", {
                        filename: "profile-signatures/" + v4() + file.name,
                        filetype: file.type,
                    })
                        .then((res) => {
                            console.log(res);
                            const xhr = new XMLHttpRequest();
                            xhr.upload.addEventListener(
                                "progress",
                                (e) => {
                                    console.log(e);
                                },
                                false
                            );
                            xhr.onreadystatechange = (e) => {
                                if (xhr.readyState === 4) {
                                    if (xhr.status === 200) {
                                        this.updateImageContent('authorized_signature', res.url);
                                    }
                                    callback(null);
                                }
                            };
                            xhr.open("PUT", res.signed_request);
                            xhr.setRequestHeader("x-amz-acl", "public-read");
                            xhr.send(file);
                        })
                        .catch((err) => callback(err));
                },
            ],
            (err, results) => {
                if (err) {
                    console.log(err);
                    return;
                }
                console.log(results);
                showNotification("Success", "Successfully updated image");
            }
        );
    };

    updateImageContent = (keyName, profile_image) => {
        let investorOrganization = { ...this.state.investorOrganization };
        investorOrganization[keyName] = profile_image;
        this.setState({ investorOrganization: investorOrganization });
    };

    removeImageContent = (keyName) => {
        let investorOrganization = { ...this.state.investorOrganization };
        investorOrganization[keyName] = "";
        this.setState({ investorOrganization: investorOrganization });
    };

    render() {
        const investorTypes = Object.values(INVESTOR_TYPE);
        return (
            <section className="col p-0">
                <div id="company-detail" className="company-detail">
                    <div className="company-detail-row1">
                        <div className="d-flex justify-content-between align-items-center company-row1">
                            <div>
                                <p className="grey-text small-fonts">
                                    <span>Investor &nbsp;&nbsp;</span>&nbsp;
                                    {this.state.investorOrganization ? this.state.investorOrganization.name : ''}
                                </p>
                                <h3 className="company-name">
                                    {this.state.investorOrganization ? this.state.investorOrganization.name : ''}
                                </h3>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                                <button onClick={() => this.props.toggleEditView(false)}
                                        className="medium-fonts company-edit-cancel">Cancel
                                </button>
                                <button onClick={this.onSaveClick}
                                        className="btn-bg-clr medium-fonts company-edit-save">Save
                                </button>
                            </div>

                        </div>
                        <div
                            className="d-flex justify-content-start company-row1 flex-wrap"
                            style={{marginTop: '15px'}}
                        >
                            <div className='mr-3 mb-3'>
                                <p className="price-irr-fees small-fonts">Status</p>
                                <select className="small-fonts edit-dropdown"
                                        defaultValue={this.state.investorOrganization ? this.state.investorOrganization.status : ''}
                                        placeholder="Active"
                                        name="" id="" style={{width: '129px'}}>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Committed Capital</p>
                                <input
                                    type="number"
                                    onChange={(e) => this.handleChange('committed_capital', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.committed_capital : ''}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Charge Fee</p>
                                <select
                                    name=""
                                    id=""
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={ this.state.investorOrganization.charge_fee ?? ""}
                                    onChange={(e) => this.handleChange('charge_fee', e.target.value)}
                                >
                                    {chargeFee.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Fees Structure</p>
                                <select
                                    name=""
                                    id=""
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={this.state.investorOrganization && this.state.investorOrganization.fee_type
                                        ? this.state.investorOrganization.fee_type
                                        :
                                        ""
                                    }
                                    onChange={(e) => this.handleChange('fee_type', e.target.value)}
                                >
                                    {Object.keys(feesStructureObj).map(item => (
                                        <option key={item} value={feesStructureObj[item]}>{item}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">{[FEE_STRUCTURE.INVESTOR_FEE_FIXED_ROI_PAYMENTS,FEE_STRUCTURE.INVESTOR_FEE_ROI_PMT].includes(this.state.investorOrganization.fee_type)  ? "Fixed ROI(%)" : "Fixed IRR (% if applicable)"}</p>
                                <input
                                    type="number"
                                    onChange={(e) => this.handleChange('fixed_IRR', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.fixed_IRR : ''}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Fees and Expenses (%)</p>
                                <input
                                    onChange={(e) => this.handleChange('recur_fees', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.recur_fees : ''}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Fee Waiver</p>
                                <select
                                    name=""
                                    id=""
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={this.state.investorOrganization && this.state.investorOrganization.recur_fees_waived ? 'true' : 'false'}
                                    onChange={(e) => this.handleChange('recur_fees_waived', e.target.value)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">PSA Structure</p>
                                <select
                                    name=""
                                    id=""
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={this.state.investorOrganization && this.state.investorOrganization.pdf_template_type
                                        ? this.state.investorOrganization.pdf_template_type
                                        :
                                        "order_psa_pdf"      
                                    }
                                    onChange={(e) => {this.handleChange('pdf_template_type', e.target.value);}}
                                >
                                    {this.state.investorpdftemplates.map(item => (
                                        <option key={item._id} value={item.template_type}>{item.template_type}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Market View</p>
                                <select
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={this.state.investorOrganization.market_view ?? MARKET_VIEW_OPTIONS[0]}
                                    onChange={(e) => {
                                        this.handleChange('market_view', e.target.value);
                                    }}
                                >
                                    {
                                        MARKET_VIEW_OPTIONS.map((item, index) => (
                                            <option key={`market-view-option-${index}`} value={item}>
                                                {item}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">FLDG(%)</p>
                                <input
                                    onChange={(e) => this.handleChange('fldg', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.fldg : ''}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Funded FLDG (%)</p>
                                <input
                                    onChange={(e) => this.handleChange('funded_fldg', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.funded_fldg : ''}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Deal Availability (Days)</p>
                                <input
                                    onChange={(e) => this.handleChange('deal_availability', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.deal_availability : ''}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Counter Signing Authority</p>
                                <select
                                    name=""
                                    id=""
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={this.state.counterSignAuthority ?? ""}
                                    onChange={(e) => {this.handleCSAChange(e.target.value);}}
                                >
                                    <option key={0} value={''}>Select an option</option>
                                   {!isEmpty(this.props.investors) ? this.props.investors.map(investor => (
                                            <option key={investor._id} value={investor._id}>{investor.contact_name}</option>
                                        )) : null}
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Repayment Day (Day of Month)</p>
                                <input
                                    type="number" min="1" max="28"
                                    onChange={(e) => this.handleChange("repayment_date", e.target.value)}
                                    value={this.state.investorOrganization?.repayment_date ?? DEFAULT_REPAYMENT_DAY}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Calendar Days</p>
                                <select
                                    name=""
                                    id=""
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={this.state.investorOrganization?.calendar_days ?? DEFAULT_CALENDAR_DAYS}
                                    onChange={(e) => {this.handleChange('calendar_days', e.target.value);}}
                                >
                                    <option key="1" value={360}>360</option>
                                    <option key="2" value={365}>365</option>
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Prepayment Charge</p>
                                <input
                                    onChange={(e) => this.handleChange('preparationCharge', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.preparationCharge : 0}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Penal Charge</p>
                                <input
                                    onChange={(e) => this.handleChange('penalCharge', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.penalCharge : 0}
                                    className="edit-dropdown price-irr-width"/>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Auto-Withdrawal</p>
                                <select
                                    name=""
                                    id=""
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={this.state.investorOrganization && this.state.investorOrganization.is_aw_on ? 'true' : 'false'}
                                    onChange={(e) => this.handleChange('is_aw_on', e.target.value)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                            <div className="mr-3 mb-3">
                                <p className="price-irr-fees small-fonts">Vendor Financing Supported</p>
                                <select
                                    className="edit-dropdown price-irr-width"
                                    defaultValue={
                                        this.state.investorOrganization &&
                                        this.state.investorOrganization.generate_paid_invoice === false ?
                                        INVOICE_STATUS_LENDER_WISE.NO : INVOICE_STATUS_LENDER_WISE.YES
                                      }
                                    onChange={(e) => this.handleChange(LENDERS_CONFIG_CONSTANTS.INVOICE_STATUS, e.target.value)}
                                >
                                <option value="" disabled>Select</option>
                                {Object.values(INVOICE_STATUS_LENDER_WISE).map((status, index) => (
                                    <option key={index} value={status}>{status}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row company-detail-row2">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="cmp-row2-head">Investor Name</p>
                                    <input
                                        onChange={(e) => this.handleChange('name', e.target.value)}
                                        value={this.state.investorOrganization ? this.state.investorOrganization.name : ''}
                                        className="investor-field-edit" type="text" id="comp-name"
                                        name="comp-name"
                                        style={{width: '100%'}}
                                        placeholder="Company"/>
                                </div>
                                <div className="col-md-6">
                                    <p className="cmp-row2-head">Investor Type</p>
                                    <select
                                        onChange={(e) => this.handleChange('investor_type', e.target.value)}
                                        value={this.state.investorOrganization ? this.state.investorOrganization.investor_type : ''}
                                        className="investor-field-edit"
                                        name="comp-name" style={{width: '100%'}}
                                    >
                                        {investorTypes.map(item => (
                                            <option key={item} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <p className="cmp-row2-head">GSTIN</p>
                                    <input
                                        onChange={(e) => this.handleChange('gstin_number', e.target.value)}
                                        value={this.state.investorOrganization ? this.state.investorOrganization.gstin_number : ''}
                                        className="investor-field-edit" type="text" id="comp-name"
                                        name="comp-name"
                                        style={{width: '100%'}}
                                        placeholder="GSTIN"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="cmp-row2-head">Pan Card</p>
                                    <input
                                        onChange={(e) => this.handleChange('pan_card', e.target.value)}
                                        value={this.state.investorOrganization ? this.state.investorOrganization.pan_card : ''}
                                        className="investor-field-edit" type="text" id="comp-name"
                                        name="comp-name"
                                        style={{width: '100%'}}
                                        placeholder="Pan Card"/>
                                </div>
                                <div className="col-md-6">
                                    <p className="cmp-row2-head">Organization Registration</p>
                                    <select
                                        onChange={(e) => this.handleChange('organization_registration', e.target.value)}
                                        value={this.state.investorOrganization ? this.state.investorOrganization.organization_registration : ''}
                                        className="investor-field-edit"
                                        name="comp-name" style={{width: '100%'}}
                                    >
                                        {this.props.orgRegistration && this.props.orgRegistration.map(item => (
                                            <option key={item.label} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <p className="cmp-row2-head">Address</p>
                                <input
                                    onChange={(e) => this.handleChange('address', e.target.value)}
                                    value={this.state.investorOrganization ? this.state.investorOrganization.address : ''}
                                    className="cmp-row2-field company-address edit-dropdown" type="text" id="comp-name"
                                    name="comp-name"/><br></br>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="cmp-row2-head">Authorized Designation</p>
                                    <input
                                        onChange={(e) => this.handleChange('authorized_designation', e.target.value)}
                                        value={this.state.investorOrganization ? this.state.investorOrganization.authorized_designation : ''}
                                        className="investor-field-edit" type="text" id="comp-name"
                                        name="comp-name"
                                        style={{width: '100%'}}
                                        placeholder="Company"/>
                                </div>
                                <div className="col-md-4">
                                    <p className='cmp-row2-head'>Authorized Signature</p>
                                    <img
                                        className='cmp-logo'
                                        style={{position: "relative"}}
                                        key={this.state.investorOrganization ? this.state.investorOrganization.authorized_signature : ''}
                                        src={
                                            this.state.investorOrganization &&
                                            this.state.investorOrganization.authorized_signature
                                                ? `${
                                                    this.state.investorOrganization.authorized_signature
                                                }?t=${new Date().getTime()}`
                                                : "/assets/company-logo.svg"
                                        }
                                        alt=''
                                    />
                                    <input
                                        multiple={false}
                                        accept='image/*'
                                        onChange={(e) => this.uploadSignatureImage(e.target.files[0])}
                                        id='img'
                                        type='file'
                                        style={{display: "none"}}
                                    />
                                    <label className='upload-btn upload-btn-onimg' htmlFor='img'>
                                        <img className='' src='/assets/upload.svg' alt=''></img>
                                        Upload New
                                    </label>
                                    <button
                                        onClick={() => this.removeImageContent("authorized_signature")}
                                        className='remove-btn'>
                                        <img className='' src='/assets/upload.svg' alt=''></img>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default withRouter(InvestorEditView);

